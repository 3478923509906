var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.renderComp)?_c('Snackbar',{attrs:{"SnackBarComponent":_vm.SnackBarComponent}}):_vm._e(),_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('AddIssuerUserDialog',{attrs:{"dialogAddIssuer":_vm.dialogAddIssuer},on:{"clicked":_vm.dialogCreateUsersEmit}}),_c('ActivateInactivateUserDialog',{attrs:{"dialogActivateInactivateUser":_vm.dialogActivateInactivateUser,"StoreObj":_vm.StoreObj},on:{"clicked":_vm.dialogActivateInactivateUserEmit}}),_c('ChangeOwnershipDialog',{attrs:{"StoreObj":_vm.StoreObj,"dialogChangeOwnership":_vm.dialogChangeOwnership},on:{"clicked":_vm.dialogChangeOwnershipEmit}}),(_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm)?_c('v-container',{attrs:{"fluid":""}},[_c('v-data-iterator',{attrs:{"items":_vm.GetAllIssuerUsersList,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12"}},[_c('div',{staticClass:"ma-2",staticStyle:{"text-align":"center"}},[_c('h2',[_vm._v("Team Members:")])]),_c('div',{staticStyle:{"text-align":"center"}},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.$store.getters.get_user_type == 'OWNER' ||
                _vm.$store.getters.get_user_type == 'ADMIN'
              ),expression:"\n                $store.getters.get_user_type == 'OWNER' ||\n                $store.getters.get_user_type == 'ADMIN'\n              "}],staticClass:"internalButtons white--text mr-2",attrs:{"color":"green","dark":"","depressed":"","small":""},on:{"click":function($event){_vm.dialogAddIssuer = true}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v("Invite Member")],1)],1)])],1),[_c('v-row',_vm._l((_vm.GetAllIssuerUsersList),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6","md":"4","lg":"4"}},[_c('v-card',{attrs:{"elevation":"1","outlined":""}},[_c('v-card-title',{staticClass:"subheading font-weight-bold"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"green","dark":"","size":"30"}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-account-circle ")])],1),_vm._v(" "+_vm._s(item.user_name)+" ")],1),_c('v-divider',{attrs:{"color":"black"}}),_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_vm._v("Email Id:")]),_c('v-list-item-content',{staticClass:"align-end"},[_vm._v(" "+_vm._s(item.user_email_id)+" ")])],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Created On:")]),_c('v-list-item-content',{staticClass:"align-end"},[_vm._v(" "+_vm._s(new Date(item.user_created_on).toLocaleString())+" ")])],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("User Type:")]),_c('v-list-item-content',{staticClass:"align-end"},[_vm._v(" "+_vm._s(item.user_type)+" ")])],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Status:")]),_c('v-list-item-content',{staticClass:"align-end",class:item.user_status == 'ACTIVE'
                        ? 'green--text'
                        : 'red--text'},[_vm._v(" "+_vm._s(item.user_status)+" ")])],1),_c('v-list-item',[_c('v-list-item-content',[_vm._v("Actions:")]),_c('v-list-item-content',[(
                        _vm.$store.getters.get_user_type == 'OWNER' ||
                        _vm.$store.getters.get_user_type == 'ADMIN'
                      )?_c('span',[(
                          item.user_status == 'ACTIVE' ||
                          item.user_status == 'INACTIVE'
                        )?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [(
                                item.user_type == 'MEMBER' ||
                                item.user_type == 'ADMIN'
                              )?_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"icon":"","color":item.user_status == 'ACTIVE' ? 'red' : 'green'},on:{"click":function($event){return _vm.checkItem(item, 'ACTION')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(
                                  item.user_status == 'ACTIVE'
                                    ? 'mdi-account-minus'
                                    : 'mdi-account-plus'
                                )}})],1):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(
                              item.user_status == 'ACTIVE'
                                ? 'Deactivate'
                                : 'Activate'
                            )}})])],1):_vm._e(),(
                          item.user_type == 'MEMBER' &&
                          item.user_status == 'ACTIVE'
                        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.PromoteOrDemote(item, 'Promote')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"},domProps:{"textContent":_vm._s('mdi-arrow-up-bold')}})],1)]}}],null,true)},[_c('span',{attrs:{"align":"center"}},[_vm._v("Promote To Admin")])]):_vm._e(),(
                          item.user_type == 'ADMIN' &&
                          item.user_status == 'ACTIVE'
                        )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.PromoteOrDemote(item, 'Demote')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"},domProps:{"textContent":_vm._s('mdi-arrow-down-bold')}})],1)]}}],null,true)},[_c('span',{attrs:{"align":"center"}},[_vm._v("Demote To Member")])]):_vm._e(),(_vm.$store.getters.get_user_type == 'OWNER')?_c('span',[(
                            item.user_type == 'ADMIN' &&
                            item.user_status == 'ACTIVE'
                          )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.checkItem(item, 'OWNERSHIP')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"},domProps:{"textContent":_vm._s('mdi-briefcase-arrow-up-down')}})],1)]}}],null,true)},[_c('span',{attrs:{"align":"center"}},[_vm._v("Transfer Ownership")])]):_vm._e()],1):_vm._e()],1):_vm._e()])],1)],1)],1)],1)}),1)]],2)],1):_vm._e(),(
      _vm.$vuetify.breakpoint.md ||
      _vm.$vuetify.breakpoint.lg ||
      _vm.$vuetify.breakpoint.xl
    )?_c('v-card',{staticClass:"ma-7",attrs:{"elevation":"0","fixed":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},[_c('div',{staticClass:"mt-1"},[_c('h2',[_vm._v("Team Members:")])]),_c('div',{staticClass:"ml-5"},[_c('div',{staticClass:"search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search__input",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}}),_c('button',{staticClass:"search__button"},[_c('svg',{staticClass:"search__icon",attrs:{"aria-hidden":"true","viewBox":"0 0 24 24"}},[_c('g',[_c('path',{attrs:{"d":"M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"}})])])])])])])]),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('div',{staticStyle:{"display":"grid"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticStyle:{"position":"relative","top":"10px"},attrs:{"color":"green","dark":"","depressed":""},on:{"click":function($event){_vm.dialogAddIssuer = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Invite Member")],1)],1)])])],1)],1):_vm._e(),(
      _vm.$vuetify.breakpoint.md ||
      _vm.$vuetify.breakpoint.lg ||
      _vm.$vuetify.breakpoint.xl
    )?_c('v-card',{staticClass:"ma-7",attrs:{"dense":"","outlined":"","elevation":"1"}},[(_vm.userTableExpand == true)?_c('v-data-table',{staticClass:"elevation-0 mt-2",attrs:{"dense":"","headers":_vm.GetIssuerListHeaders,"search":_vm.search,"items":_vm.GetAllIssuerUsersList,"no-data-text":_vm.noDataText},scopedSlots:_vm._u([{key:"item.sl_no",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(_vm.GetAllIssuerUsersList.indexOf(item) + 1)+" ")])]}},{key:"item.user_email_id",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"FontSize"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.truncateText(item.user_email_id, 20))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.user_email_id))])])]}},{key:"item.user_name",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"FontSize"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.truncateText(item.user_name, 20))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.user_name))])])]}},{key:"item.user_created_on",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(new Date(item.user_created_on) .toLocaleString("en-GB", { day: "numeric", month: "short", year: "numeric", hour: "numeric", minute: "numeric", second: "numeric", hour12: true, }) .replace(/\//g, " "))+" ")])]}},{key:"item.user_type",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"FontSize"},'div',attrs,false),on),[_vm._v(" "+_vm._s(item.user_type.replace("_", " "))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.user_type))])])]}},{key:"item.user_status",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"FontSize"},[_c('v-chip',{class:item.user_status == 'ACTIVE' ? 'green--text' : 'red--text',attrs:{"small":"","color":_vm.getColor(item.user_status)}},[_vm._v(" "+_vm._s(item.user_status)+" ")])],1)]}},{key:"item.Actions",fn:function(ref){
    var item = ref.item;
return [(
            _vm.$store.getters.get_user_type == 'OWNER' ||
            _vm.$store.getters.get_user_type == 'ADMIN'
          )?_c('span',[(
              item.user_status == 'ACTIVE' || item.user_status == 'INACTIVE'
            )?_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(
                    item.user_type == 'MEMBER' || item.user_type == 'ADMIN'
                  )?_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"icon":"","color":item.user_status == 'ACTIVE' ? 'red' : 'green'},on:{"click":function($event){return _vm.checkItem(item, 'ACTION')}}},'v-btn',attrs,false),on),[_c('v-icon',{domProps:{"textContent":_vm._s(
                      item.user_status == 'ACTIVE'
                        ? 'mdi-account-minus'
                        : 'mdi-account-plus'
                    )}})],1):_vm._e()]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(
                  item.user_status == 'ACTIVE' ? "Deactivate" : "Activate"
                )}})])],1):_vm._e(),(item.user_type == 'MEMBER' && item.user_status == 'ACTIVE')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.PromoteOrDemote(item, 'Promote')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"},domProps:{"textContent":_vm._s('mdi-arrow-up-bold')}})],1)]}}],null,true)},[_c('span',{attrs:{"align":"center"}},[_vm._v("Promote To Admin")])]):_vm._e(),(item.user_type == 'ADMIN' && item.user_status == 'ACTIVE')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.PromoteOrDemote(item, 'Demote')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"},domProps:{"textContent":_vm._s('mdi-arrow-down-bold')}})],1)]}}],null,true)},[_c('span',{attrs:{"align":"center"}},[_vm._v("Demote To Member")])]):_vm._e(),(_vm.$store.getters.get_user_type == 'OWNER')?_c('span',[(item.user_type == 'ADMIN' && item.user_status == 'ACTIVE')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.checkItem(item, 'OWNERSHIP')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"green"},domProps:{"textContent":_vm._s('mdi-briefcase-arrow-up-down')}})],1)]}}],null,true)},[_c('span',{attrs:{"align":"center"}},[_vm._v("Transfer Ownership")])]):_vm._e()],1):_vm._e()],1):_vm._e()]}}],null,true)}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }