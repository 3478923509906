<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogIssueTemplatizedCredential" persistent max-width="400">
      <v-card>
        <v-toolbar dense class="elevation-0" dark color="primary">
          <div>Issue Credential</div>
          <v-spacer></v-spacer>
          <v-btn :disabled="loading == true" icon @click="dialogIssueTemplatizedCredentialEmit((Toggle = 1))">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="">
          <v-text-field
            label="Template Name"
            :rules="[(v) => !!v || 'Required']"
            v-model="StoreObj.template_name"
            dense
            readonly
            outlined
            class="FontSize field_height field_label_size mt-5 mb-n5"></v-text-field>
          <div v-if="preview_mode == false" class="font-weight-bold mb-1">Issued By:</div>
          <v-row no-gutters v-if="preview_mode == false">
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-text-field
                label="Issuing Organization"
                :rules="[(v) => !!v || 'Required']"
                v-model="$store.getters.get_current_user_details.issuer_details.issuer_name"
                dense
                readonly
                outlined
                class="FontSize field_height field_label_size mr-1"></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-text-field
                label="Issuing User"
                :rules="[(v) => !!v || 'Required']"
                v-model="$store.getters.get_current_user_details.user_name"
                dense
                readonly
                outlined
                class="FontSize field_height field_label_size ml-1"></v-text-field>
            </v-col>
          </v-row>
          <div class="font-weight-bold mb-3" :class="preview_mode == false ? 'mt-n5' : ''">Issue To:</div>
          <v-form ref="form" class="py-0">
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="6" class="mt-n2">
                <v-text-field
                  :readonly="preview_mode == true"
                  label="Name"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="issued_to"
                  dense
                  outlined
                  class="FontSize field_height field_label_size mr-1"></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" class="mt-n2">
                <v-text-field
                  :readonly="preview_mode == true"
                  label="Email ID (Optional)"
                  :rules="emailRules"
                  v-model="issued_to_email_id"
                  dense
                  outlined
                  class="FontSize field_height field_label_size ml-1"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" xs="12" sm="12" md="4">
                <v-select
                  outlined
                  dense
                  :items="GetAllSupportedCountryList"
                  item-value="country_code"
                  item-text="country_name"
                  class="field_height field_label_size FontSize"
                  label="Country Code "
                  v-model="issued_to_country_code"
                  :readonly="preview_mode == true">
                  <template slot="selection" slot-scope="{ item }">
                    {{ item.country_code }}
                  </template>
                  <template slot="item" slot-scope="{ item }">{{ item.country_name }}({{ item.country_code }}) </template>
                </v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="8">
                <v-text-field
                  :readonly="preview_mode == true"
                  outlined
                  dense
                  class="field_height field_label_size FontSize ml-2"
                  label="Contact Number (Optional)"
                  :rules="contactRules"
                  v-model="issued_to_contact_number"></v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              :readonly="preview_mode == true"
              rows="1"
              label="Additional Comments (Optional)"
              v-model="comments"
              dense
              outlined
              class="FontSize field_height field_label_size"></v-textarea>
            <v-text-field
              v-if="preview_mode == true && issued_to_email_id != undefined && issued_to_email_id != '' && this.issued_to_email_id != null"
              filled
              outlined
              dense
              class="field_height field_label_size FontSize"
              label="Enter Email Subject (Optional)"
              v-model="email_subject"></v-text-field>
          </v-form>
          <v-card color="#e1ffef" class="px-2" outlined style="border: 1px solid #00a650">
            <span
              class="FontSizeSmall"
              v-text="
                'Note that the template itself it NOT stored on blockchain, only the active data fields and the hash code of the generated PDF document is stored'
              "></span>
          </v-card>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            small
            class="borderRadius fontStyle text-capitalize"
            color="primary"
            outlined
            @click="dialogIssueTemplatizedCredentialEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small v-if="loading == false" class="borderRadius fontStyle text-capitalize" color="primary" @click="validateMethod()"
            ><v-icon small class="mr-1" v-text="preview_mode == false ? 'mdi-eye' : 'mdi-pencil'"></v-icon
            ><span v-text="preview_mode == false ? 'Preview' : 'Edit'"></span
          ></v-btn>
          <v-btn
            :loading="loading"
            v-if="preview_mode == true"
            small
            class="borderRadius fontStyle text-capitalize ml-2"
            color="primary"
            @click="IssueTemplatizedCredentialRequestMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { IssueCredentailWithTemplate } from "@/graphql/mutations.js";
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  props: {
    dialogIssueTemplatizedCredential: Boolean,
    StoreObj: Object,
    formfields: Object,
  },
  components: {
    Snackbar,
  },
  mixins: [GetAllSupportedCountry],
  data: () => ({
    Toggle: 0,
    issued_to: "",
    issued_to_email_id: "",
    issued_to_country_code: "",
    issued_to_contact_number: "",
    email_subject: "",
    logo_url: "",
    comments: "",
    loading: false,
    SnackBarComponent: {},
    emailRules: [],
    contactRules: [],
    preview_mode: false,
  }),
  watch: {
    issued_to_contact_number(val) {
      if (val != "" && val != null) {
        this.contactRules = [
          (v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) || "Invalid",
          (v) => (v && v.length < 11) || "Invalid",
          (v) => (v && v.length > 9) || "Invalid",
        ];
      } else {
        this.contactRules = [];
      }
    },
    issued_to_email_id(val) {
      if (val != "" && val != null) {
        this.emailRules = [(v) => /.+@.+/.test(v) || "Email Must Be Valid"];
      } else {
        this.emailRules = [];
      }
    },
  },
  mounted() {
    this.GetAllSupportedCountryMethod("ACTIVE");
  },
  methods: {
    validateMethod() {
      if (this.$store.getters.get_current_user_details.issuer_details.available_credits != 0) {
        if (this.$refs.form.validate()) {
          this.preview_mode = !this.preview_mode;
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Fields marked with asterisks (*) are mandatory",
          };
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Your Can't Issue Credentials As Your Balance Is Zero",
        };
      }
    },
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.IssueTemplatizedCredentialRequestMethod();
      }
    },
    async IssueTemplatizedCredentialRequestMethod() {
      let template_fields = [];
      for (let key in this.formfields) {
        template_fields.push({
          field_label: key,
          field_value: this.formfields[key],
        });
      }
      if (this.StoreObj.template_fields.filter((item) => item == "BUSINESS_LOGO").length != 0) {
        template_fields.push({
          field_label: "BUSINESS_LOGO",
          field_value: `<img style="width:150px" src="${this.$store.getters.get_current_user_details.issuer_details.account_logo_url}"/>`,
        });
      }
      let d = new Date();
      let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let date =
        d.getDate() + "-" + monthNames[d.getMonth()] + "-" + d.getFullYear() + "  " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      try {
        var inputParams = {
          issued_to: this.issued_to,
          file_name: this.StoreObj.template_name,
          template_id: this.StoreObj.template_id,
          template_fields: JSON.stringify(template_fields),
          should_create_pdf: true,
          push_to_blockchain: true,
          user_id: this.$store.getters.get_current_user_details.user_id,
          month: `${d.getFullYear()}-${d.getMonth() + 1}`,
          date: date,
        };
        if (this.email_subject != undefined && this.email_subject != "" && this.email_subject != null) {
          inputParams.email_subject = this.email_subject;
        }
        if (this.comments != undefined && this.comments != "" && this.comments != null) {
          inputParams.comments = this.comments;
        }
        if (this.issued_to_contact_number != undefined && this.issued_to_contact_number != "" && this.issued_to_contact_number != null) {
          inputParams.phone_number =
            `${this.issued_to_country_code}${this.issued_to_contact_number}`.includes("+") == true
              ? `${this.issued_to_country_code}${this.issued_to_contact_number}`.replace("+", "")
              : `${this.issued_to_country_code}${this.issued_to_contact_number}`;
        }
        if (this.issued_to_email_id != undefined && this.issued_to_email_id != "" && this.issued_to_email_id != null) {
          inputParams.issued_to_email_id = this.issued_to_email_id;
        }
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(IssueCredentailWithTemplate, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.IssueCredentailWithTemplate);
        if (ResultObject.status == "Success") {
          this.dialogIssueTemplatizedCredentialEmit((this.Toggle = 2), ResultObject);
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogIssueTemplatizedCredentialEmit(Toggle, ResultObject) {
      this.preview_mode = false;
      this.$emit("clicked", Toggle, ResultObject);
    },
  },
};
</script>
