import { listCredentials } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllIssuedCredentials = {
  data() {
    return {
      GetIssuedCredentialsList: [],
      additionalDetails:[],
      noDataText:"",
      overlay: false,
    };
  },
  methods: {
    async GetIssuedCredentialsMethod() {
      try {
        this.overlay = true;
        this.dashboardTableExpand = false
        let result = await API.graphql(
          graphqlOperation(listCredentials, {
            input: {
                user_id: this.$store.getters.get_current_user_details.user_id,
            },
          })
        );
        this.GetIssuedCredentialsList = JSON.parse(result.data.listCredentials).data.items;
        // console.log("listCredentials", this.GetIssuedCredentialsList)
        for(let i=0;i<this.GetIssuedCredentialsList.length;i++){
          this.additionalDetails.push(this.GetIssuedCredentialsList[i].additional_details);
          this.GetIssuedCredentialsList[i].URL = `https://workhistory-mumbai.s3.ap-south-1.amazonaws.com/${this.GetIssuedCredentialsList[i].s3_key}`
        }
        this.overlay = false;
        this.dashboardTableExpand = true;
      } catch (error) {
        this.overlay = false;
        this.dashboardTableExpand = true
        this.noDataText = error.errors[0].message
        this.GetIssuedCredentialsList = [];
      }
    },
  },
};
