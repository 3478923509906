<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <PDFPreviewDialog :StoreObj="StoreObj" :PDFViewDialog="PDFViewDialog" :template_html="template_html" @clicked="PDFPreviewDialogEmit" />
    <DeletePDFTemplateDialog :StoreObj="StoreObj" :dialogDeletePDFTemplate="dialogDeletePDFTemplate" @clicked="dialogDeletePDFTemplateEmit" />
    <SavePDFWarningDialog
      :template_html="template_html"
      :TemplateStoreObj="StoreObj"
      :settings="settings"
      :templateItems="templateItems"
      :dialogSavePDFWarning="dialogSavePDFWarning"
      @clicked="dialogSavePDFWarningEmit"
    />
    <SignatureWarning :dialogSignatureWarning="dialogSignatureWarning" @clicked="dialogSignatureWarningEmit" />
    <v-card class="elevation-0" v-if="current_view == 'LIST'">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">Templates</div>
            <v-radio-group dense class="mt-5 ml-3" v-model="selectTemplateTypes" row>
              <v-radio label="My Templates" :value="true"></v-radio>
              <v-radio label="SureCreds Templates" :value="false"></v-radio>
            </v-radio-group>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" v-if="selectTemplateTypes == true">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn small @click="(current_view = 'CREATE'), (StoreObj.action = 'CREATE')" color="secondary" class="borderRadius text-capitalize"
              ><v-icon small class="mr-1">mdi-plus</v-icon>Design Template</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-expand-x-transition>
          <v-card outlined v-show="TemplateTableExpand">
            <v-data-table
              v-if="TemplateTableExpand == true"
              dense
              class="elevation-0"
              :headers="GetPortalTemplatesListHeaders"
              :items="GetPortalTemplatesList.filter((item) => item.is_editable == selectTemplateTypes)"
              :no-data-text="noDataText"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50, 100],
              }"
              :items-per-page="100"
            >
              <template v-slot:[`item.sl_no`]="{ item }">
                <div class="FontSize">{{ GetPortalTemplatesList.filter((item) => item.is_editable == selectTemplateTypes).indexOf(item) + 1 }}</div>
              </template>
              <template v-slot:[`item.template_name`]="{ item }">
                <div class="FontSize">{{ item.template_name }}</div>
              </template>
              <template v-slot:[`item.template_created_on`]="{ item }">
                <div class="FontSize">{{ new Date(item.template_created_on).toLocaleString() }}</div>
              </template>
              <template v-slot:[`item.template_type`]="{ item }">
                <div class="FontSize">{{ item.is_editable == true ? "PORTAL" : "SURECREDS" }}</div>
              </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn x-small color="blue" v-bind="attrs" v-on="on" outlined class="text-capitalize" @click="checkItem(item, 'PREVIEW')">Preview</v-btn>
                  </template>
                  <span align="center">Preview {{ item.template_name }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.is_editable == true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-2 text-capitalize" x-small color="primary" v-bind="attrs" v-on="on" outlined @click="checkItem(item, 'EDIT')">Edit</v-btn>
                  </template>
                  <span align="center">Edit {{ item.template_name }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.is_editable == true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-2 text-capitalize" color="red" v-bind="attrs" v-on="on" outlined x-small @click="checkItem(item, 'DELETE')">Delete</v-btn>
                  </template>
                  <span align="center">Delete {{ item.template_name }}</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.is_editable == false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="ml-2 text-capitalize" color="primary" v-bind="attrs" v-on="on" outlined x-small @click="checkItem(item, 'COPY')">Copy Template</v-btn>
                  </template>
                  <span align="center">Copy {{ item.template_name }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-expand-x-transition>
      </v-card-text>
    </v-card>
    <DesignTemplateCard :TemplateStoreObj="StoreObj" v-if="current_view == 'CREATE'" @clicked="designTemplateEmit" />
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import PDFPreviewDialog from "@/components/TemplateDesigner/Dialogs/PDFPreviewDialog.vue";
import DeletePDFTemplateDialog from "@/components/TemplateDesigner/Dialogs/DeletePDFTemplateDialog.vue";
import SavePDFWarningDialog from "@/components/TemplateDesigner/Dialogs/SavePDFWarningDialog.vue";
import DesignTemplateCard from "@/components/TemplateDesigner/Cards/DesignTemplateCard.vue";
import SignatureWarning from "@/components/TemplateDesigner/Dialogs/SignatureWarning.vue";
import { DesignTemplate } from "@/mixins/DesignTemplate.js";
import { GetAllPortalPDFs } from "@/mixins/GetAllPortalPDFs.js";
import { FormPDFTemplate } from "@/mixins/FormPDFTemplate.js";
export default {
  mixins: [GetAllPortalPDFs, DesignTemplate, FormPDFTemplate],
  components: {
    DeletePDFTemplateDialog,
    SavePDFWarningDialog,
    PDFPreviewDialog,
    DesignTemplateCard,
    SignatureWarning,
    Snackbar,
    Overlay,
  },
  data: () => ({
    selectTemplateTypes: true,
    overlay: false,
    PDFViewDialog: false,
    SnackBarComponent: {},
    TemplateTableExpand: false,
    dialogDeletePDFTemplate: false,
    current_view: "LIST",
    template_html: "",
    StoreObj: {},
    GetPortalTemplatesList: [],
    GetPortalTemplatesListHeaders: [
      { text: "Sl No.", value: "sl_no" },
      { text: "Name", value: "template_name" },
      { text: "Created On", value: "template_created_on" },
      { text: "Source", value: "template_type" },
      { text: "Actions", value: "Actions" },
    ],
    settings: {},
    templateContentItems: [],
    dialogSavePDFWarning: false,
    dialogSignatureWarning: false,
    templateItems: [],
  }),
  mounted() {
    this.GetAllPortalTemplatesMethod();
  },
  methods: {
    dialogSignatureWarningEmit() {
      this.dialogSignatureWarning = false;
    },
    dialogSavePDFWarningEmit(Toggle, ResultObject) {
      this.dialogSavePDFWarning = false;
      if (Toggle == 2) {
        this.GetAllPortalTemplatesMethod();
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: ResultObject.Status_Message,
        };
      }
    },
    designTemplateEmit(Toggle, ResultObject) {
      this.current_view = "LIST";
      this.GetAllPortalTemplatesMethod();
      if (Toggle == 2) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: ResultObject.Status_Message,
        };
      }
    },
    PDFPreviewDialogEmit() {
      this.PDFViewDialog = false;
    },
    dialogDeletePDFTemplateEmit(Toggle) {
      this.dialogDeletePDFTemplate = false;
      if (Toggle == 2) {
        this.GetAllPortalTemplatesMethod();
      }
    },
    async deciderMethod(item, action) {
      if (action == "EDIT") {
        this.current_view = "CREATE";
      } else if (action == "DELETE") {
        this.dialogDeletePDFTemplate = true;
      } else if (action == "COPY" || action == "PREVIEW") {
        console.log("Get HTML", this.StoreObj.template_html);
        console.log("Check StoreObj", this.StoreObj);
        //   this.settings = this.StoreObj.settings;
        //   this.StoreObj.template_html = this.StoreObj.template_html.replaceAll(
        //     "${BUSINESS_LOGO}",
        //     this.$store.getters.get_current_user_details.issuer_details.account_logo_url != undefined
        //       ? `${this.$store.getters.get_current_user_details.issuer_details.account_logo_url}`
        //       : "Please Upload Your Business Logo"
        //   );
        if (action == "PREVIEW") {
          this.PDFViewDialog = true;
          await this.formPDFMethod();
          this.StoreObj.template_html = this.template_html;
        }
        if (action == "COPY") {
          this.dialogSavePDFWarning = true;
        }
      }
    },
    async checkItem(item, action) {
      this.StoreObj = item;
      this.templateItems = this.StoreObj.settings;
      this.StoreObj.action = action;
      if (this.StoreObj.settings.filter((item) => item.type == "SIGNATURE" && item.content_present == true).length == 0) {
        this.deciderMethod(item, action);
      } else {
        if (
          this.$store.getters.get_current_user_details.signature_url != undefined &&
          this.$store.getters.get_current_user_details.signature_url != null &&
          this.$store.getters.get_current_user_details.signature_url != ""
        ) {
          await this.formPDFMethod();
          await this.deciderMethod(this.StoreObj, this.StoreObj.action);
        } else {
          if (action != "DELETE") {
            this.dialogSignatureWarning = true;
          } else {
            await this.formPDFMethod();
            await this.deciderMethod(this.StoreObj, this.StoreObj.action);
          }
        }
      }
    },
  },
};
</script>
