<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <DeleteLogoDialog
      :DeleteLogoDialog="DeleteLogoDialog"
      @clicked="DeleteLogoDialogEmit"
    ></DeleteLogoDialog>
    <!--  -->

    <!-- 
    <v-card dense elevation="0" v-show="isbigScreen" class="ma-7">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          <h3 class="mr-12 mt-2">Organization Details:</h3>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <h3 class="mt-2" v-if="$store.getters.get_current_user_details.issuer_details
          .underModification == 'APPROVED'
          ">
          Account Status: <span class="#42414d--text">Verified</span>
          <v-icon color="green" right> mdi-check-decagram </v-icon>
        </h3>
        <h3 class="mt-2" v-if="$store.getters.get_current_user_details.issuer_details
          .underModification == 'PENDING'
          ">
          Account Status:
          <span class="red--text">Modification Request Pending Approval</span>
        </h3>
        <v-list>
          <v-list-item>
            <v-btn color="green" v-if="$store.getters.get_current_user_details.issuer_details
              .underModification !== 'PENDING'
              " small dark class="mt-2 " @click="uploadLogoToS3()"><v-icon small
                left>mdi-content-save</v-icon>SAVE</v-btn>
          </v-list-item>
        </v-list>
      </v-toolbar>
    </v-card> -->

    <v-card class="elevation-0 mr-7 ml-7 mt-7 mb-4" v-show="isbigScreen">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
          <h2 style="margin-right: 100px">Organization Details</h2>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
            v-if="
              $store.getters.get_current_user_details.issuer_details
                .underModification !== 'PENDING'
            "
          >
            <h3 class="mb-3" style="margin-right: 20px">
              Account Status:Verified
              <v-icon color="green" right>mdi-check-decagram</v-icon>
            </h3>
            <v-btn
              color="green"
              dark
              @click="uploadLogoToS3()"
              v-if="
                $store.getters.get_current_user_details.issuer_details
                  .underModification !== 'PENDING'
              "
              class="mb-3"
            >
              <v-icon left>mdi-content-save</v-icon>SAVE
            </v-btn>
          </div>

          <div
            style="text-align: end"
            v-if="
              $store.getters.get_current_user_details.issuer_details
                .underModification == 'PENDING'
            "
          >
            <h3 class="mb-3">
              Account Status:
              <span class="red--text"
                >Modification Request Pending Approval</span
              >
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      dense
      elevation="0"
      class="ml-7 mr-7"
      v-if="currentView == 'BussinessInfo'"
      v-show="isbigScreen"
    >
      <v-form ref="form" lazy-validation>
        <v-row align="center">
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-text-field
              color="green"
              prepend-inner-icon="mdi-domain"
              dense
              outlined
              label="Organization Name"
              disabled
              v-model="
                $store.getters.get_current_user_details.issuer_details
                  .issuer_name
              "
              filled
              ><template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-domain</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              :disabled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              :filled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              color="green"
              prepend-inner-icon="mdi-map-marker"
              dense
              @input="handleFieldChange"
              outlined
              label="Address 1"
              placeholder="Enter your Address1"
              v-model="issuer_details.issuer_address_1"
              ><template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-map-marker</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              :disabled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              :filled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              color="green"
              @input="handleFieldChange"
              prepend-inner-icon="mdi-city"
              dense
              outlined
              label="City"
              placeholder="Enter your city"
              v-model="issuer_details.city"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-city</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              :disabled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              :filled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              color="green"
              prepend-inner-icon="mdi-domain"
              dense
              @input="handleFieldChange"
              outlined
              label="Postal Code"
              placeholder="Enter your Postal Code"
              v-model="issuer_details.postal_code"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-map-marker</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              color="green"
              prepend-inner-icon="mdi-earth"
              dense
              outlined
              label="Country"
              placeholder="Enter your Country"
              disabled
              v-model="
                $store.getters.get_current_user_details.issuer_details.country
              "
              filled
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-earth</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-text-field
              :disabled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              :filled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              @input="handleFieldChange"
              prepend-inner-icon="mdi-format-list-numbered-rtl"
              dense
              outlined
              placeholder="Enter your Business Identification Number"
              label="Business Identification Number"
              color="green"
              v-model="issuer_details.identification_number"
            >
              <template v-slot:prepend-inner>
                <v-icon color="green" left>mdi-format-list-numbered-rtl</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              :disabled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              :filled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              @input="handleFieldChange"
              prepend-inner-icon="mdi-map-marker"
              dense
              outlined
              label="Address 2"
              color="green"
              placeholder="Enter your Address2"
              v-model="issuer_details.issuer_address_2"
              ><template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-map-marker</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              :disabled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              :filled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              prepend-inner-icon="mdi-city-variant-outline"
              dense
              outlined
              @input="handleFieldChange"
              color="green"
              label="District"
              v-model="issuer_details.district"
              placeholder="Enter your District"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left
                  >mdi-city-variant-outline</v-icon
                >
              </template>
            </v-text-field>
            <v-text-field
              :disabled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              :filled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              @input="handleFieldChange"
              prepend-inner-icon="mdi-domain"
              dense
              outlined
              label="State"
              color="green"
              placeholder="Enter your State"
              v-model="issuer_details.state"
              ><template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-domain</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              prepend-inner-icon="mdi-calendar-range"
              dense
              outlined
              label="Valid Till"
              disabled
              filled
              :value="formattedSubscriptionDate"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-calendar-range</v-icon>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-select
              :disabled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              :filled="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'PENDING'
              "
              color="green"
              prepend-inner-icon="mdi-account-group"
              outlined
              dense
              label="Number Of Members*"
              :items="members"
              class="mb-2"
              @input="handleFieldChange"
              v-model="issuer_details.number_of_members"
              :rules="[(v) => !!v || 'Required']"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green">mdi-account-group</v-icon>
              </template></v-select
            >

            <!-- <h3>Organization Logo
              <v-btn style="margin-left:30px; margin-bottom:5px" dark depressed color="green" small
                v-if="imgSrc != null && imgSrc != ''" @click.stop="(imgSrc = null), (uploadedImage = false)">Delete
                <v-icon right small>mdi-delete</v-icon></v-btn>
            </h3> -->

            <div class="logo-header">
              <h3>Organization Logo</h3>

              <!-- v-if="
                  $store.getters.get_current_user_details.issuer_details
                    .underModification == 'APPROVED'
                " -->
              <div>
                <!-- :disabled="
                    $store.getters.get_current_user_details.issuer_details
                      .underModification == 'PENDING'
                  " -->
                <!-- <v-btn
                  
                  depressed
                  v-if="imgSrc != null && imgSrc != ''"
                  @click.stop="(imgSrc = null), (uploadedImage = false)"
                  class="mb-1 white--text"
                  color="green"
                  >Delete<v-icon left small class="ml-2"
                    >mdi-delete</v-icon
                  ></v-btn
                > -->
                <v-btn
                  :disabled="
                    $store.getters.get_current_user_details.issuer_details
                      .underModification == 'PENDING'
                  "
                  depressed
                  v-if="imgSrc != null && imgSrc != ''"
                  @click="DeleteLogoDialog = true"
                  class="mb-1 white--text"
                  color="green"
                  >Delete<v-icon left small class="ml-2"
                    >mdi-delete</v-icon
                  ></v-btn
                >
              </div>
            </div>

            <!-- <div class="display: flex; justify-content: space-betweeen">
              <div>
              <span>Organization Logo </span>
            </div>
            <div>
              <v-btn dark depressed color="green" small v-if="imgSrc != null && imgSrc != ''"
                @click.stop="(imgSrc = null), (uploadedImage = false)" >Delete
                <v-icon right small>mdi-delete</v-icon></v-btn>
            </div>
            </div> -->

            <v-card
              outlined
              height="191px"
              max-width="1980px"
              class="mb-6 rouded-xl"
              rounded
              @click="checkImagePresent()"
              @input="handleFieldChange"
              elevation="1"
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                "
              >
                <v-img
                  contain
                  v-if="imgSrc != null && imgSrc != ''"
                  :src="imgSrc"
                  :lazy-src="imgSrc"
                  height="150px"
                  width="150px"
                ></v-img>
                <v-card v-else rounded elevation="0">
                  <input
                    ref="excel-upload-input"
                    accept="image/*"
                    class="excel-upload-input"
                    type="file"
                    @change="handleClick"
                  />
                  <div style="text-align: center; margin-top: 10px">
                    <v-icon large color="green">mdi-cloud-upload</v-icon>
                    <h5 class="mt-2">UPLOAD LOGO</h5>
                  </div>
                </v-card>
              </div>
            </v-card>

            <!-- <v-text-field prepend-inner-icon="mdi-domain" dense outlined label="Organization Name" disabled v-model="$store.getters.get_current_user_details.issuer_details
              .issuer_name
              " filled><template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-domain</v-icon>
              </template>
            </v-text-field> -->

            <!--             
            <v-toolbar dense elevation="0">
              <v-toolbar-title>

              </v-toolbar-title>
         <v-spacer></v-spacer>
           <v-btn color="green" v-if="$store.getters.get_current_user_details.issuer_details
              .underModification == 'PENDING'
              " small dark class="mt-2 mr" @click="uploadLogoToS3()"><v-icon small
                left>mdi-content-save</v-icon>SAVE</v-btn>
          </v-toolbar>  -->
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <!-- <v-card dense elevation="0" v-show="isbigScreen">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>
          <h3 class="ml-2 mb-12">Account Owner Details:</h3>
        </v-toolbar-title>
      </v-toolbar>
    </v-card> -->

    <v-card class="elevation-0" v-show="isbigScreen">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0">
            <h2 class="ml-3">Account Owner Details</h2>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      dense
      elevation="0"
      class="ml-7 mr-7 mb-5 mt-5"
      v-if="currentView == 'BussinessInfo'"
      v-show="isbigScreen"
    >
      <v-row align="center">
        <v-col cols="12" md="4" lg="4" sm="12" xs="12">
          <v-text-field
            color="green"
            prepend-inner-icon="mdi-domain"
            dense
            outlined
            label="Email ID"
            disabled
            v-model="
              $store.getters.get_current_user_details.issuer_details
                .contact_email_id
            "
            filled
            ><template v-slot:prepend-inner>
              <v-icon small color="green" left>mdi-email</v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" md="4" lg="4" sm="12" xs="12">
          <v-text-field
            color="green"
            prepend-inner-icon="mdi-domain"
            dense
            outlined
            label="Country Code"
            v-model="issuer_details.country_code"
            filled
            disabled
            ><template v-slot:prepend-inner>
              <v-icon small color="green" left>mdi-flag</v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-col cols="12" md="4" lg="4" sm="12" xs="12">
          <v-text-field
            color="green"
            prepend-inner-icon="mdi-phone"
            dense
            outlined
            label="Contact Number"
            disabled
            v-model="
              $store.getters.get_current_user_details.issuer_details
                .contact_number
            "
            filled
            ><template v-slot:prepend-inner>
              <v-icon small color="green" left>mdi-phone</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      color="white"
      v-if="currentView == 'BussinessInfo'"
      dense
      elevation="0"
      class="ml-4 mr-4"
      style="text-align: center"
      v-show="isSmallScreen"
    >
      <v-row align="center">
        <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
          <h3 class="ma-2">Organization Info</h3>
          <h5
            v-if="
              $store.getters.get_current_user_details.issuer_details
                .underModification == 'APPROVED'
            "
          >
            Account Status: <span class="#42414d--text">Verified</span>
            <v-icon color="green" right> mdi-check-decagram </v-icon>
          </h5>

          <div
            v-if="
              $store.getters.get_current_user_details.issuer_details
                .underModification == 'PENDING'
            "
            class="ma-2"
          >
            <h4 style="color: red">Modification request pending approval</h4>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      dense
      elevation="0"
      class="ml-4 mr-4"
      v-if="currentView == 'BussinessInfo'"
      v-show="isSmallScreen"
    >
      <v-form ref="form" lazy-validation>
        <v-row align="center">
          <v-col cols="12" sm="12" xs="12">
            <v-text-field
              color="green"
              prepend-inner-icon="mdi-domain"
              dense
              outlined
              label="Organization Name"
              disabled
              v-model="
                $store.getters.get_current_user_details.issuer_details
                  .issuer_name
              "
              filled
              ><template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-domain</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              color="green"
              prepend-inner-icon="mdi-earth"
              dense
              outlined
              label="Country"
              disabled
              v-model="
                $store.getters.get_current_user_details.issuer_details.country
              "
              filled
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-earth</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              color="green"
              prepend-inner-icon="mdi-calendar-range"
              dense
              outlined
              label="Valid Till"
              disabled
              filled
              :value="formattedSubscriptionDate"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-calendar-range</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              color="green"
              @input="handleFieldChange"
              prepend-inner-icon="mdi-format-list-numbered-rtl"
              dense
              outlined
              label="Business Identification Number"
              v-model="issuer_details.identification_number"
            >
              <template v-slot:prepend-inner>
                <v-icon color="green" left>mdi-format-list-numbered-rtl</v-icon>
              </template>
            </v-text-field>

            <v-select
              color="green"
              prepend-inner-icon="mdi-account-group-outline"
              outlined
              dense
              label="No of members*"
              :items="members"
              @input="handleFieldChange"
              v-model="issuer_details.number_of_members"
              :rules="[(v) => !!v || 'Required']"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green">mdi-account-group-outline</v-icon>
              </template></v-select
            >

            <v-text-field
              color="green"
              prepend-inner-icon="mdi-map-marker"
              dense
              @input="handleFieldChange"
              outlined
              label="Address 1"
              v-model="issuer_details.issuer_address_1"
              ><template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-map-marker</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              color="green"
              @input="handleFieldChange"
              prepend-inner-icon="mdi-map-marker-plus
"
              dense
              outlined
              label="Address 2"
              v-model="issuer_details.issuer_address_2"
              ><template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-map-marker-plus</v-icon>
              </template>
            </v-text-field>
            <!-- <h3>Organization Logo
              <v-btn style="margin-left:30px; margin-bottom:5px" dark depressed color="green" small
                v-if="imgSrc != null && imgSrc != ''" @click.stop="(imgSrc = null), (uploadedImage = false)">Delete
                <v-icon right small>mdi-delete</v-icon></v-btn>
            </h3> -->
            <div class="logo-header">
              <h3>Organization Logo</h3>
              <v-btn
                dark
                depressed
                color="green"
                small
                v-if="imgSrc != null && imgSrc != ''"
                @click="DeleteLogoDialog = true"
                class="mb-1"
              >
                Delete
                <v-icon right small>mdi-delete</v-icon>
              </v-btn>
            </div>

            <v-card
              outlined
              height="140px"
              width="405px"
              class="mb-6"
              rounded
              @click="checkImagePresent()"
              @input="handleFieldChange"
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100%;
                "
              >
                <v-img
                  contain
                  v-if="imgSrc != null && imgSrc != ''"
                  :src="imgSrc"
                  :lazy-src="imgSrc"
                  height="150px"
                  width="150px"
                ></v-img>
                <v-card v-else rounded elevation="0">
                  <input
                    ref="excel-upload-input"
                    accept="image/*"
                    class="excel-upload-input"
                    type="file"
                    @change="handleClick"
                  />
                  <div style="text-align: center; margin-top: 10px">
                    <v-icon large color="green">mdi-cloud-upload</v-icon>
                    <h5 class="mt-2">UPLOAD LOGO</h5>
                  </div>
                </v-card>
              </div>
            </v-card>
            <v-text-field
              color="green"
              @input="handleFieldChange"
              prepend-inner-icon="mdi-city"
              dense
              outlined
              label="City"
              v-model="issuer_details.city"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-city</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              color="green"
              prepend-inner-icon="mdi-city-variant-outline"
              dense
              outlined
              @input="handleFieldChange"
              label="District"
              v-model="issuer_details.district"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left
                  >mdi-city-variant-outline</v-icon
                >
              </template>
            </v-text-field>

            <v-text-field
              color="green"
              prepend-inner-icon="mdi-domain"
              dense
              @input="handleFieldChange"
              outlined
              label="Postal Code"
              v-model="issuer_details.postal_code"
            >
              <template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-map-marker</v-icon>
              </template>
            </v-text-field>

            <v-text-field
              color="green"
              @input="handleFieldChange"
              prepend-inner-icon="mdi-domain"
              dense
              outlined
              label="State"
              v-model="issuer_details.state"
              ><template v-slot:prepend-inner>
                <v-icon small color="green" left>mdi-domain</v-icon>
              </template>
            </v-text-field>
            <v-toolbar
              elevation="0"
              dense
              v-if="
                $store.getters.get_current_user_details.issuer_details
                  .underModification == 'APPROVED'
              "
            >
              <v-toolbar-title> </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                color="green"
                v-if="
                  $store.getters.get_current_user_details.issuer_details
                    .underModification == 'APPROVED'
                "
                small
                dark
                @click="uploadLogoToS3()"
                ><v-icon small left>mdi-content-save</v-icon>SAVE</v-btn
              >
            </v-toolbar>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-card
      color="white"
      v-if="currentView == 'BussinessInfo'"
      dense
      elevation="0"
      style="text-align: center"
      v-show="isSmallScreen"
    >
      <v-toolbar dense elevation="0">
        <v-toolbar-title class="">
          <h5>Account Owner Details:</h5>
        </v-toolbar-title>
      </v-toolbar>
    </v-card>

    <v-card
      dense
      elevation="0"
      class="ml-4 mr-4"
      v-if="currentView == 'BussinessInfo'"
      v-show="isSmallScreen"
    >
      <v-row align="center">
        <v-col cols="12" md="4" lg="4" sm="12" xs="12">
          <v-text-field
            color="green"
            prepend-inner-icon="mdi-domain"
            dense
            outlined
            label="Email Id"
            disabled
            v-model="
              $store.getters.get_current_user_details.issuer_details
                .contact_email_id
            "
            filled
            ><template v-slot:prepend-inner>
              <v-icon small color="green" left>mdi-email</v-icon>
            </template>
          </v-text-field>

          <v-text-field
            color="green"
            prepend-inner-icon="mdi-domain"
            dense
            outlined
            label="Country Code"
            v-model="issuer_details.country_code"
            filled
            disabled
            ><template v-slot:prepend-inner>
              <v-icon small color="green" left>mdi-flag</v-icon>
            </template>
          </v-text-field>

          <v-text-field
            color="green"
            prepend-inner-icon="mdi-phone"
            dense
            outlined
            label="Contact Number"
            disabled
            v-model="
              $store.getters.get_current_user_details.issuer_details
                .contact_number
            "
            filled
            ><template v-slot:prepend-inner>
              <v-icon small color="green" left>mdi-phone</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// var AWS = require("aws-sdk");
import DeleteLogoDialog from "@/components/Dashboard/Dialogs/DeleteLogoDialog.vue";
import axios from "axios";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
import Overlay from "@/components/Extras/Overlay.vue";
// import UpdateOrganization from "@/components/Dashboard/Dialogs/UpdateOrganization.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import Snackbar from "@/components/Extras/Snackbar.vue";
// import { UpdateIssuerDetails } from "@/graphql/mutations.js";
import { UpdateIssuerFormDetails } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import { GetAllCountryCityStates } from "@/mixins/GetAllCountryCityStates.js";
// import CountryList from "@/JsonFiles/CountryList.json";
import { GetPresignedUrlForS3Upload } from "../../../mixins/GetsFileConvertS3PreSignedUrl";
export default {
  mixins: [
    GetAllSupportedCountry,
    GetCurrentUser,
    GetAllCountryCityStates,
    GetPresignedUrlForS3Upload,
  ],
  components: {
    Snackbar,
    Overlay,
    DeleteLogoDialog,

    // UpdateOrganization,
  },
  props: {
    user_typeBI: String,
  },
  data: () => ({
    DeleteLogoDialog: false,
    changesMade: false,
    initialFormValues: {},
    // Flag to track if the form has been edited
    formEdited: false,
    tableHeaders: [
      { text: "Field", align: "start", value: "field" },
      { text: "Value", value: "value" },
    ],
    tableData: [],
    members: ["0-10", "10-100", "100-200"],
    no_of_members: "",
    currentView: "BussinessInfo",
    form_url: "",
    height: 0,
    Expand: false,
    overlay: false,
    loading: false,
    loadingUpdate: false,
    modify_boolean: false,
    country: "+91",
    select_country: [],
    BussinessIdentity: "",
    addressOne: "",
    addressTwo: "",
    City: "",
    PostalCode: "",
    selectImage: "",
    District: "",
    State: "",
    selectedFile: "",
    // business_info: {
    //   issuer_name: "",
    //   identification_type: "",
    //   identification_number: "",
    //   issuer_address_1: "",
    //   issuer_address_2: "",
    //   country: "",
    //   state: "",
    //   city: "",
    //   district: "",
    //   postal_code: "",
    //   contact_email_id: "",
    //   business_info: "",
    //   country_code: "",
    //   contact_number: "",
    //   number_of_members: "",
    //   issuer_verification_fee: 0,
    //   account_logo_url: "",
    // },
    country_code: "91",
    GetAllSupportedCountryList: [],
    identificationType: ["Tax ID", "Pan Id", "GST ID"],
    SnackBarComponent: {},
    issuer_details: {},
    Count: 0,
    // url:"",
    stateList: [],
    imageFile: null,
    user_type: "",
    enable_verification_requests: false,
    dialogSaveBusinessInformationWarning: false,
    countryListNew: [],
    country_details: {},
    displayFields: {},
    uploadedImage: false,
    imgSrc: "",
    updateorgdetails: false,
    storeObj: {},
    malli: {},
  }),
  watch: {
    modify_boolean(val) {
      if (val == false) {
        this.imageFile = null;
      }
    },
    "business_info.country"(val) {
      this.identificationTypeItems = this.GetAllSupportedCountryList.filter(
        (item) => item.country_name == val
      );
      this.getAllStatesMethod(val);
      this.Count += 1;
      if (this.Count != 1) {
        this.business_info.identification_type = "";
        this.business_info.identification_number = "";
      }
    },
  },

  computed: {
    formattedSubscriptionDate() {
      // console.log(
      //   "dateeeeee",
      //   this.$store.getters.get_current_user_details.issuer_details
      //     .subscription_till
      // );
      const currentDate = new Date(
        this.$store.getters.get_current_user_details.issuer_details.subscription_till
      );
      const day = currentDate.getDate();
      const month = currentDate.toLocaleString("en-US", { month: "short" });
      const year = currentDate.getFullYear();
      return day + " " + month + " " + year;
    },

    userDetails() {
      return this.$store.getters.get_current_user_details.issuer_details;
    },

    isSmallScreen() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    isbigScreen() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },

  mounted() {
  console.log("status..!!",this.$store.getters.get_current_user_details.issuer_details
  .underModification);
    this.form_url = encodeURI(
      `https://surecreds.com/CredentialRequest/#/${this.$store.getters.get_current_user_details.issuer_details.issuer_name}`
    );
    this.$vuetify.goTo(0, 0);
    // console.log("thisissuer_details.country",this.$store.getters.get_current_user_details.issuer_details.country);
    this.user_type = this.$store.getters.get_user_type;
    this.GetCurrentUser();
    this.getAllCountriesMethod();
    this.GetAllSupportedCountryMethod("ACTIVE");
    this.height = window.innerHeight - 135;
    this.malli = this.$store.getters.get_current_user_details.issuer_details;
    this.country_details =
      this.$store.getters.get_current_user_details.country_details;
    this.issuer_details =
      this.$store.getters.get_current_user_details.issuer_details;
    this.business_info = {
      issuer_name:
        this.$store.getters.get_current_user_details.issuer_details.issuer_name,
      identification_type: this.issuer_details.identification_type,
      identification_number: this.issuer_details.identification_number,
      issuer_address_1: this.issuer_details.issuer_address_1,
      issuer_address_2: this.issuer_details.issuer_address_2,
      country: this.issuer_details.country,
      state: this.issuer_details.state,
      city: this.issuer_details.city,
      district: this.issuer_details.district,
      number_of_members: this.issuer_details.number_of_members,
      postal_code: this.issuer_details.postal_code,
      contact_email_id: this.issuer_details.contact_email_id,
      full_name: this.issuer_details.full_name,
      country_code: this.issuer_details.country_code,
      contact_number: `${this.issuer_details.contact_number}`,
      account_logo_url: this.issuer_details.account_logo_url,
    };
    this.displayFields = {
      "Issuer Details :": "",
      "Issuer Name": this.issuer_details.issuer_name,
      "Identification Type": this.issuer_details.identification_type,
      "Identification Number": this.issuer_details.identification_number,
      "Registered Address": "",
      "Address 1": this.issuer_details.issuer_address_1,
      "Address 2": this.issuer_details.issuer_address_2,
      Country: this.issuer_details.country,
      // State: this.issuer_details.state,
      // City: this.issuer_details.city,
      // "Postal Code": this.issuer_details.postal_code,
      "Contact Details": "",
      Name: this.issuer_details.full_name,
      "Email ID": this.issuer_details.contact_email_id,
      "Contact Number": `${this.issuer_details.country_code} - ${this.issuer_details.contact_number}`,
    };

    console.log(
      "underModification",
      this.$store.getters.get_current_user_details.issuer_details
        .underModification
    );
    // console.log("Hey", this.issuer_details.account_logo_url);

    this.enable_verification_requests =
      this.issuer_details.enable_verification_requests;
    if (this.enable_verification_requests == true) {
      this.business_info.issuer_verification_fee = parseFloat(
        this.issuer_details.issuer_verification_fee
      );
      this.displayFields["Verification Details"] = "";
      this.displayFields["Verification Fee"] = `${parseFloat(
        this.issuer_details.issuer_verification_fee
      )} ${this.issuer_details.currency_type} + ${
        this.country_details.verification_fee
      } ${this.country_details.currency_symbol} For Surecreds`;
    }
    this.imgSrc =
      this.$store.getters.get_current_user_details.issuer_details
        .account_logo_url != undefined &&
      this.$store.getters.get_current_user_details.issuer_details
        .account_logo_url != null &&
      this.$store.getters.get_current_user_details.issuer_details
        .account_logo_url != ""
        ? this.$store.getters.get_current_user_details.issuer_details
            .account_logo_url
        : "";
  },

  methods: {
    handleFieldChange() {
      // This method is called when any field is edited
      this.changesMade = true; // Set flag to true when a field is edited
    },

    // async formattedSubscriptionTill() {
    //   const currentDate = new Date(this.$store.getters.get_current_user_details.issuer_details.subscription_till);
    //   const day = currentDate.getDate();
    //   const month = this.getMonthName(currentDate.getMonth());
    //   const year = currentDate.getFullYear();
    //   return day + "-" + month + "-" + year;
    // },

    // getMonthName(monthIndex) {
    //   const monthNames = [
    //     "January", "February", "March", "April", "May", "June",
    //     "July", "August", "September", "October", "November", "December"
    //   ];
    //   return monthNames[monthIndex];
    // },

    checkImagePresent() {
      if (
        this.imgSrc == null ||
        this.imgSrc == "" ||
        this.imgSrc == undefined
      ) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    // handleClick(e) {
    //   this.selectedFile = e.target.files[0];
    //   var self = this;
    //   var fileReader = new FileReader();
    //   fileReader.readAsDataURL(self.selectedFile);
    //   fileReader.onload = async function (val) {
    //     self.imgSrc = val.target.result;
    //     // self.uploadLogoToS3();
    //   };
    // },

    async EditOrganization(url) {
      // console.log("Edit-method");
      let inputParams = {
        issuer_id: this.$store.getters.get_current_user_details.issuer_id,
        organisation_name:
          this.$store.getters.get_current_user_details.issuer_details
            .issuer_name,
        postal_code: this.issuer_details.postal_code,
        issuer_address_1: this.issuer_details.issuer_address_1,
        issuer_address_2: this.issuer_details.issuer_address_2,
        city: this.issuer_details.city,
        state: this.issuer_details.state,
        district: this.issuer_details.district,
        identification_number: this.issuer_details.identification_number,
        account_logo_url:
          this.selectedFile.name === undefined
            ? this.issuer_details.account_logo_url
            : url,
        number_of_members: this.issuer_details.number_of_members,
        is_verification_request: false,
      };
      let result = await API.graphql(
        graphqlOperation(UpdateIssuerFormDetails, {
          input: inputParams,
        })
      );
      var ResultObject = JSON.parse(result.data.UpdateIssuerFormDetails);
      // console.log(" out snackbar");
      if (ResultObject.message == "Under Review!") {
        // console.log(" in snackbar");
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Requested for Updatation",
        };
        this.loading = false;
        this.currentView = "BussinessInfo";
        this.GetCurrentUser();
      }
      if (this.$refs.editOrgDetails) {
        this.$refs.editOrgDetails.reset();
      }
    },

    async uploadLogoToS3() {
      if (this.changesMade) {
        this.loading = true;
        try {
          const key = `BMS/Issuer/account_logo/${new Date().getTime()}_${
            this.selectedFile.name
          }`;
          if (this.selectedFile.name != undefined) {
            await this.GetPresignedUrlForS3UploadMethod(key);
            const response = await this.https3UploadFunction();
            // console.log("response https3UploadFunction()", response);
            if (response.statusText == "OK") {
              let url = `https://${this.$store.getters.get_current_user_details.s3_credentials.bucket_name}.s3.ap-south-1.amazonaws.com/${key}`;
              await this.EditOrganization(url);
              // console.log("if");
            }
          } else {
            await this.EditOrganization(); // Wait for EditOrganization to complete
            // console.log("Else");
          }
          this.loading = false;
          this.S3loading = false;
          this.changesMade = false;
        } catch (error) {
          // console.log("Catch", error);
          this.loading = false;
          this.S3loading = false;
          this.changesMade = false;
        }
      } else {
        // console.log("in else bar");

        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Make Some Changes",
        };
      }
    },

    async https3UploadFunction() {
      try {
        const response = await axios.put(
          this.PresignedUrlForS3Upload,
          this.selectedFile,
          {
            headers: { "Content-Type": this.selectedFile.type },
          }
        );
        // console.log("Uploaded Successfully", response);
        // console.log("selectedFile", this.selectedFile.name);
        return response;
      } catch (error) {
        this.loading = false;
        // console.log("Error While Uploading", error);
      }
    },

    // async updateCurrentBusinessLogo(s3_URL) {
    //   try {
    //     var inputParams = {
    //       user_id: this.$store.getters.get_current_user_details.user_id,
    //       update_type: "ACCOUNT_LOGO_URL",
    //       update_url: s3_URL,
    //     };
    //     this.overlay = true;
    //     let result = await API.graphql(
    //       graphqlOperation(UpdateIssuerURLsAndAPIId, {
    //         input: inputParams,
    //       })
    //     );
    //     var ResultObject = JSON.parse(result.data.UpdateIssuerURLsAndAPIId);
    //     console.log("WHy??:-->",ResultObject)
    //     if (ResultObject.status == "Success") {
    //       this.SnackBarComponent = {
    //         SnackbarVmodel: true,
    //         SnackbarColor: "green",
    //         Top: true,
    //         SnackbarText: ResultObject.Status_Message,
    //       };
    //       this.GetCurrentUser();
    //     }
    //     this.overlay = false;
    //   } catch (error) {
    //     this.overlay = false;
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: error.errors[0].message,
    //     };
    //   }
    // },
    // checkImagePresent() {
    //   if (
    //     this.imgSrc == null ||
    //     this.imgSrc == "" ||
    //     this.imgSrc == undefined
    //   ) {
    //     this.handleUpload();
    //   }
    // },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.selectedFile = e.target.files[0];
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function (val) {
        self.imgSrc = val.target.result;
        // self.uploadLogoToS3();
      };
    },
    onCopy() {
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "green",
        Top: true,
        SnackbarText: "Verification Credential Copied Successfully !!",
      };
    },
    changeToDefaultData() {
      this.issuer_details =
        this.$store.getters.get_current_user_details.issuer_details;
      this.business_info = {
        issuer_name: this.issuer_details.issuer_name,
        identification_type: this.issuer_details.identification_type,
        identification_number: this.issuer_details.identification_number,
        issuer_address_1: this.issuer_details.issuer_address_1,
        issuer_address_2: this.issuer_details.issuer_address_2,
        country: this.issuer_details.country,
        state: this.issuer_details.state,
        city: this.issuer_details.city,
        postal_code: this.issuer_details.postal_code,
        contact_email_id: this.issuer_details.contact_email_id,
        contact_user_name: this.issuer_details.contact_user_name,
        country_code: this.issuer_details.country_code,
        contact_number: `${this.issuer_details.contact_number}`,
      };
      if (this.issuer_details.enable_verification_requests == true) {
        this.business_info.issuer_verification_fee = parseFloat(
          this.issuer_details.issuer_verification_fee
        );
      }
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.updateCurrentBusinessInfo(false);
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },

    // async updateCurrentBusinessInfo(updating_fee, account_logo_url) {
    //   try {
    //     if (updating_fee == false) {
    //       this.loading = true;
    //     } else {
    //       this.loadingUpdate = true;
    //     }
    //     var inputParams = {
    //       user_id: this.$store.getters.get_current_user_details.user_id,
    //       issuer_name: this.business_info.issuer_name,
    //       postal_code: this.business_info.postal_code,
    //       issuer_address_1: this.business_info.issuer_address_1,
    //       issuer_address_2: this.business_info.issuer_address_2,
    //       city: this.business_info.city,
    //       state: this.business_info.state,
    //       country_code: this.business_info.country_code,
    //       contact_number: this.business_info.contact_number,
    //       identification_type: this.business_info.identification_type,
    //       identification_number: this.business_info.identification_number,
    //       updating_fee: updating_fee,
    //     };
    //     if (account_logo_url) {
    //       inputParams.account_logo_url = account_logo_url;
    //     }
    //     if (this.issuer_details.enable_verification_requests == true) {
    //       if (updating_fee == true) {
    //         inputParams.issuer_verification_fee =
    //           this.business_info.issuer_verification_fee;
    //       } else {
    //         inputParams.issuer_verification_fee = 0;
    //       }
    //     } else {
    //       inputParams.issuer_verification_fee = 0;
    //     }
    //     let result = await API.graphql(
    //       graphqlOperation(UpdateIssuerDetails, {
    //         input: inputParams,
    //       })
    //     );
    //     var ResultObject = JSON.parse(result.data.UpdateIssuerDetails);
    //     if (ResultObject.status == "Success") {
    //       this.SnackBarComponent = {
    //         SnackbarVmodel: true,
    //         SnackbarColor: "green",
    //         Top: true,
    //         SnackbarText: "Editing Changes Submitted Successfully!!",
    //       };
    //       this.GetCurrentUser();
    //     }
    //     if (updating_fee == false) {
    //       this.loading = false;
    //     } else {
    //       this.loadingUpdate = false;
    //     }
    //     this.modify_boolean = false;
    //   } catch (error) {
    //     this.loading = false;
    //     if (account_logo_url) {
    //       this.imgSrc =
    //         this.$store.getters.get_current_user_details.issuer_details
    //           .account_logo_url != undefined &&
    //         this.$store.getters.get_current_user_details.issuer_details
    //           .account_logo_url != null &&
    //         this.$store.getters.get_current_user_details.issuer_details
    //           .account_logo_url != ""
    //           ? this.$store.getters.get_current_user_details.issuer_details
    //               .account_logo_url
    //           : "";
    //     }
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: error.errors[0].message,
    //     };
    //   }
    // },
    dialogUpdateOrgDetialsEmit(val) {
      if (val == false) {
        this.updateorgdetails = false;
      }
    },

    DeleteLogoDialogEmit(Toggle) {
      if (Toggle == 3) {
        this.overlay = true;
        (this.imgSrc = null) && (this.uploadedImage = false);
      }
      this.DeleteLogoDialog = false;
      this.overlay = false;
    },
  },
};
</script>

<style scoped>
.logo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* @media (min-width: 1920px) {
  .xl-card {
    width: 30vw; 
    height: auto; 
  }
} */
</style>
