<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" v-if="renderComp" />
    <v-dialog v-model="dialogverifydialogunverified" max-width="600" persistent>
      <v-card class="elevation-0 mt-n2">
        <v-toolbar dense class="elevation-0 appbar"
          ><div class="white--text">Verify Organization</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeverifydialogunverified"
            ><v-icon color="white">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-form ref="editOrgDetails">
            <v-text-field
            color="green"
              dense
              outlined
              class="mt-2 FontSize field_label_size field_height borderRadius"
              v-model="issuer_details.issuer_name"
              filled
              readonly
            ></v-text-field>
            <v-text-field
              dense
              outlined
              color="green"
              class="mt-n2 FontSize field_label_size field_height borderRadius"
              v-model="issuer_details.country"
              filled
              readonly
            ></v-text-field>

            <v-select
            color="green"
              label="No. of Members"
              dense
              outlined
              class="mt-n2 FontSize field_label_size field_height borderRadius"
              v-model="no_Of_Members"
              :items="members"
      

              :rules="[(v) => !!v || 'Required']"
            ></v-select>
            <v-text-field
            :maxlength="35" 
            :counter="35"
            color="green"
              label="Business Identification Number"
              dense
              outlined
              class="mt-n2 FontSize field_label_size field_height borderRadius"
              v-model="BussinessIdentity"
              :rules="[(v) => !!v || 'Required']"


            ></v-text-field>
            <v-text-field
            :maxlength="35" 
            :counter="35"
            color="green"
              label="Address One"
              dense
              outlined
              class="mt-n2 FontSize field_label_size field_height borderRadius"
              v-model="addressOne"
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
            <v-text-field
            label="Address Two"
            :maxlength="35" 
            :counter="35"
            color="green"
              dense
              outlined
              class="mt-n2 FontSize field_label_size field_height borderRadius"
              v-model="addressTwo"
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
            <v-text-field
            :maxlength="35" 
            :counter="35"
            color="green"
              label="City"
              dense
              outlined
              class="mt-n2 FontSize field_label_size field_height borderRadius"
              v-model="City"
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
            <v-text-field
            :maxlength="35" 
            :counter="35"
              label="District"
              dense
              outlined
              class="mt-n2 FontSize field_label_size field_height borderRadius"
              v-model="District"
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
            <v-text-field
            color="green"
              label="State"
              :maxlength="35" 
            :counter="35"
              dense
              outlined
              class="mt-n2 FontSize field_label_size field_height borderRadius"
              v-model="State"
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
            <v-text-field
            :maxlength="35" 
            :counter="35"
            color="green"
              label="Postal Code"
              dense
              outlined
              class="mt-n2 FontSize field_label_size field_height borderRadius"
              v-model="PostalCode"
              :rules="[(v) => !!v || 'Required']"
            ></v-text-field>
            <v-file-input
      
              prepend-inner-icon="mdi-file"
              prepend-icon=""
              label="Company Logo"
              dense
              color="green"
              outlined
              v-model="selectedFile"
              class="mt-n2"
              :rules="[(v) => !!v || 'Required']"
            ></v-file-input>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
              small
              @click="closeverifydialogunverified"
              outlined
              class="internalButtons  mt-n6 mb-2 mr-1"
             dark
              :loading="loading"
              ><v-icon small left>mdi-close</v-icon>Cancel</v-btn
            > -->
            <v-btn
              small
              dark
              class="internalButtons  mt-n6 mr-1"
              @click="validateForm"
              :loading="loading"
              color="green"
              ><v-icon small left>mdi-check</v-icon>Confirm</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { UpdateIssuerFormDetails } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { GetPresignedUrlForS3Upload } from "../../../mixins/GetsFileConvertS3PreSignedUrl.js";
export default {
  props: {
    dialogverifydialogunverified: Boolean,
  },
  mixins: [GetPresignedUrlForS3Upload],
  components: {
    Snackbar,
  },
  data: () => ({
    issuer_details: {},
    no_Of_Members: "",
    members: ["0-10", "10-100", "100-200"],
    BussinessIdentity: "",
    addressOne: "",
    addressTwo: "",
    City: "",
    PostalCode: "",
    selectImage: "",
    District: "",
    State: "",
    selectedFile: "",
    renderComp: true,
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {
    dialogverifydialogunverified(val) {
      if (val == true) {
        this.no_Of_Members =
          this.$store.getters.get_current_user_details.issuer_details.number_of_members;
        this.issuer_details =
          this.$store.getters.get_current_user_details.issuer_details;
      }
      // console.log("Watchers issuer_details", this.issuer_details);
    },
  },
  mounted() {
    // console.log("issuer_details", this.issuer_details);
  },
  methods: {
    closeverifydialogunverified() {
      this.$emit("clicked", false);
      if (this.$refs.editOrgDetails) {
        this.$refs.editOrgDetails.reset();
      }
    },
    async EditOrganization(url) {
      let inputParams = {
        issuer_id: this.$store.getters.get_current_user_details.issuer_id,
        organisation_name:
          this.$store.getters.get_current_user_details.issuer_details
            .issuer_name,
        postal_code: this.PostalCode,
        number_of_members: this.no_Of_Members,
        issuer_address_1: this.addressOne,
        issuer_address_2: this.addressTwo,
        city: this.City,
        state: this.State,
        district: this.District,
        identification_number: this.BussinessIdentity,
        account_logo_url: url,
        is_verification_request: true,
      };
      console.log(inputParams);
      let result = await API.graphql(
        graphqlOperation(UpdateIssuerFormDetails, {
          input: inputParams,
        })
      );
      var ResultObject = JSON.parse(result.data.UpdateIssuerFormDetails);
      if (ResultObject.message == "Under Review!") {
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText:
              "Your details has been Updated.Wait for 2 working days",
            timeout: 4000,
          };
          this.renderComp = true;
        });
        this.loading = false;
        this.closeverifydialogunverified();
      }
    },
    // async uploadLogoToS3() {
    //   this.loading = true;
    //   var self = this;
    //   var s3Bucket = new AWS.S3({
    //     region:
    //       self.$store.getters.get_current_user_details.s3_credentials.region,
    //     accessKeyId:
    //       self.$store.getters.get_current_user_details.s3_credentials
    //         .access_key,
    //     secretAccessKey:
    //       self.$store.getters.get_current_user_details.s3_credentials
    //         .secret_key,
    //   });
    //   var fileReader = new FileReader();
    //   fileReader.readAsDataURL(self.selectedFile);uploadLogoToS3
    //   fileReader.onload = async function () {
    //     var base64FileContent = fileReader.result.split(",");
    //     var buf = Buffer.from(base64FileContent[1], "base64");
    //     var param = {
    //       Bucket:
    //         self.$store.getters.get_current_user_details.s3_credentials
    //           .bucket_name,
    //       Key: `BMS/Issuer/account_logo/${new Date().getTime()}_${
    //         self.selectedFile.name
    //       }`,
    //       Body: buf,
    //       ContentType: self.selectedFile.type,
    //       ACL: "public-read-write",
    //     };
    //     await s3Bucket.putObject(param, function (err, data) {
    //       if (err) {
    //         console.log(err);
    //         self.S3loading = false;
    //       } else if (data) {
    //         let url = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
    //         self.S3loading = false;
    //         self.uploadedImage = true;
    //         console.log(url);
    //         self.EditOrganization(url);
    //         // self.updateCurrentBusinessLogo(url);
    //       }
    //     });
    //   };
    // },

    // async uploadLogoToS3() {
    //   this.loading = true;
    //   var self = this;
    //   self.file_type = `${self.selectedFile.name}`;

    //   const key = `BMS/Issuer/account_logo/${new Date().getTime()}_${
    //     this.file_type
    //   }`;
    //   await self.GetPresignedUrlForS3UploadMethod(key);
    //   self.url = `https://relieving-letter-portal-2024.s3.us-east-1.amazonaws.com/${key}`;
    //   response = await this.https3UploadFunction();
    //   console.log("https3UploadFunction", response);

    //   if ((response = "Success")) {
    //     self.EditOrganization(url);
    //   } else {
    //     console.log("Error in edit methods");
    //   }
    // },

    // async https3UploadFunction() {
    //   const response = await axios.put(
    //     this.PresignedUrlForS3Upload,
    //     this.file_type,
    //     {
    //       headers: {
    //         "Content-Type": this.file_type,
    //       },
    //     }
    //   );
    //   if (response.status == 200 && response.statusText == "OK") {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "green",
    //       Top: true,
    //       SnackbarText: "Uploaded Successfully!!",
    //     };
    //     console.log("upload completed:-->", response);
    //     console.log("response.status:-->", response.status);
    //     console.log("response.statusText:-->", response);
    //     return "Success";
    //   } else {
    //     this.loading = false;
    //     console.log("Error uploading file", response);
    //   }
    // },
    async uploadLogoToS3() {
      this.loading = true;
      try {
        const key = `BMS/Issuer/account_logo/${new Date().getTime()}_${
          this.selectedFile.name
        }`;
        {
          await this.GetPresignedUrlForS3UploadMethod(key);
          const response = await this.https3UploadFunction();
          console.log("response https3UploadFunction()", response);
          if (response.statusText == "OK") {
            let url = `https://${this.$store.getters.get_current_user_details.s3_credentials.bucket_name}.s3.ap-south-1.amazonaws.com/${key}`;
            this.EditOrganization(url);
            this.loading = false;
            this.S3loading = false;
          }
        }
      } catch (error) {
        this.loading = false;
        this.S3loading = false;
        console.log("Catch", error);
      }
    },
    async https3UploadFunction() {
      try {
        const response = await axios.put(
          this.PresignedUrlForS3Upload,
          this.selectedFile,
          {
            headers: { "Content-Type": this.selectedFile.type },
          }
        );
        console.log("Uploaded Successfully", response);
        console.log("selectedFile", this.selectedFile.name);
        return response;
      } catch (error) {
        this.loading = false;
        console.log("Error While Uploading", error);
      }
    },

    validateForm() {
      if (this.$refs.editOrgDetails.validate()) {
        this.uploadLogoToS3();
      } else {
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Fields marked with asterisks (*) are mandatory",
          };
          this.renderComp = true;
        });
      }
    },
  },
};
</script>

<style></style>
