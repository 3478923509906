import { ListMedia } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllMedia = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllMediaList: [],
    };
  },
  methods: {
    async GetAllMediaMethod() {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListMedia, {
            input: {
              user_id: this.$store.getters.get_current_user_details.user_id,
            },
          })
        );
        this.GetAllMediaList = JSON.parse(result.data.ListMedia).data.items;
        // console.log("ListMedia",this.GetAllMediaList);
        // if (this.$route.name == "Templates") {
        //   if (this.$store.getters.get_current_user_details.issuer_details.account_logo_url != undefined) {
        //     this.GetAllMediaList.push({
        //       file_name: "Business Logo",
        //       file_url: this.$store.getters.get_current_user_details.issuer_details.account_logo_url,
        //     });
        //   }
        // }
        this.overlay = false;
        this.mediaTableExpand = true;
      } catch (error) {
        this.overlay = false;
        this.mediaTableExpand = true;
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
