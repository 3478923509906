<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogActivateInactivateUser" persistent max-width="400px" class="appbar">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="secondary">
          <div>
            <span v-text="StoreObj.user_status == 'ACTIVE' ? 'Deactivate' : 'Activate'"></span>
            Issuer
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogActivateInactivateUserEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-icon
            size="50px"
            :color="StoreObj.user_status == 'ACTIVE' ? 'red' : 'green'"
            v-text="StoreObj.user_status == 'ACTIVE' ? 'mdi-account-cancel' : 'mdi-account-plus'"></v-icon>
          <div>
            Are you sure you want to
            <span v-text="StoreObj.user_status == 'ACTIVE' ? 'Deactivate' : 'Activate'"></span>
            {{ StoreObj.user_name }} ?
          </div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <!-- <v-btn class="cancelButton white--text fontStyle text-capitalize" outlined @click="dialogActivateInactivateUserEmit((Toggle = 1))"
            ><v-icon small color="white" class="mr-1">mdi-close</v-icon>Cancel</v-btn
          > -->
          <v-btn class="internalButtons text-capitalize mr-2 white--text"   @click="dialogActivateInactivateUserEmit((Toggle = 1))"
            ><v-icon small color="white" class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >

          <v-btn class="internalButtons text-capitalize mr-2 white--text" :loading="loading" @click="activateInactivateMethod()" color="green"
            >Confirm<v-icon small color="white" class="ml-1">mdi-check</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ActivateOrDeactivateUser } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogActivateInactivateUser: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    add_issuer: {
      user_name: "",
      user_email_id: "",
    },
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {},
  methods: {
    async activateInactivateMethod() {
      try {
        var inputParams = {
          user_id: this.StoreObj.user_id,
          updater_user_id: this.$store.getters.get_current_user_details.user_id,
          user_status: this.StoreObj.user_status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(ActivateOrDeactivateUser, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.ActivateOrDeactivateUser);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogActivateInactivateUserEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogActivateInactivateUserEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
