<template>
  <div>
    <AddHeaderFooter :GetAllMediaList="GetAllMediaList" :StoreObj="StoreObj" :dialogAddHeaderFooter="dialogAddHeaderFooter" @clicked="allCommonEmit" />
    <AddImage :GetAllMediaList="GetAllMediaList" :StoreObj="StoreObj" :dialogAddImage="dialogAddImage" @clicked="allCommonEmit" />
    <AddSignature :GetAllMediaList="GetAllMediaList" :StoreObj="StoreObj" :dialogAddSignature="dialogAddSignature" @clicked="allCommonEmit" />
    <AddMainContent :StoreObj="StoreObj" :dialogAddText="dialogAddText" @clicked="allCommonEmit" @activefieldsUpdate="updateActiveFields" />
    <TemplatePreviewDialog :TemplateStoreObj="TemplateStoreObj" :dialogTemplatePreview="dialogTemplatePreview" :template_html="template_html" @clicked="templatePreviewDialogEmit" />
    <SavePDFWarningDialog
      :template_html="template_html"
      :TemplateStoreObj="TemplateStoreObj"
      :StoreObj="StoreObj"
      :templateItems="templateItems"
      :dialogSavePDFWarning="dialogSavePDFWarning"
      @clicked="dialogSavePDFWarningEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="8">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">
              Design Template <span v-if="TemplateStoreObj.action == 'EDIT'">( {{ TemplateStoreObj.template_name }} )</span>
            </div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="4">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn small outlined color="primary" class="borderRadius mr-2" @click="previewMethod()"> <v-icon small class="mr-1">mdi-eye</v-icon>Preview </v-btn>
            <v-btn :disabled="allActiveFields.length == 0" small outlined color="primary" class="borderRadius mr-2" @click="dialogSavePDFWarning = true">
              <v-icon small class="mr-1">mdi-content-save</v-icon>Save
            </v-btn>
            <v-btn small color="primary" @click="designTemplateEmit((Toggle = 1))">
              <v-icon>mdi-chevron-double-left</v-icon>
              Back
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" sm="12" xs="12" md="9">
            <v-row no-gutters>
              <v-col v-for="(item, idx) in templateItems" :key="idx" cols="12" sm="12" :md="item.columns" v-show="item.type != 'BORDER' && item.type != 'BACKGROUND'">
                <v-card
                  :disabled="
                    item.type == 'SIGNATURE' &&
                    ($store.getters.get_current_user_details.signature_url == '' ||
                      $store.getters.get_current_user_details.signature_url == undefined ||
                      $store.getters.get_current_user_details.signature_url == null)
                  "
                  :color="item.color"
                  class="elevation-0 borderRadiusZero"
                  :align="item.align"
                  :height="item.height"
                  @click="checkItem(item)"
                >
                  <v-card-text class="pt-0">
                    <v-icon small color="primary">mdi-plus-circle</v-icon>
                    <div>
                      {{ item.label
                      }}<span v-if="item.content_present == false">
                        (<i>{{ item.type == "MAIN CONTENT" ? "Mandatory" : "Optional" }}</i> )</span
                      >
                    </div>
                    <div
                      v-if="
                        item.type == 'SIGNATURE' &&
                        ($store.getters.get_current_user_details.signature_url == '' ||
                          $store.getters.get_current_user_details.signature_url == undefined ||
                          $store.getters.get_current_user_details.signature_url == null)
                      "
                      class="font-weight-bold red--text"
                    >
                      Please Upload Signature In My Profile Tab To Enable This Section
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="3">
            <v-card height="100%" fill-height class="elevation-0 borderRadiusZero">
              <v-card-title class="pb-0">Stylings</v-card-title>
              <v-card-text class="pt-0">
                <v-tabs dense v-model="tabSettings">
                  <v-tab class="text-capitalize">Border</v-tab>
                  <v-tab class="text-capitalize">Background</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabSettings">
                  <v-tab-item>
                    <v-card>
                      <v-card-text class="py-0">
                        <v-checkbox @change="enableBorderMethod()" dense class="mx-0 px-0" v-model="enable_border" label="Add Border"></v-checkbox>
                        <v-select
                          dense
                          outlined
                          v-if="enable_border == true"
                          class="field_height field_label_size FontSize"
                          :rules="[(v) => !!v || 'Required']"
                          label="Border Size"
                          v-model="border_settings.border_size"
                          :items="borderSizeItems"
                        ></v-select>
                        <v-color-picker v-if="enable_border == true" v-model="border_settings.border_color" mode="hexa" :hide-mode-switch="true"></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card>
                      <v-card-text class="pb-0">
                        <v-color-picker v-model="background" mode="hexa" :hide-mode-switch="true"></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import SavePDFWarningDialog from "@/components/TemplateDesigner/Dialogs/SavePDFWarningDialog.vue";
import AddHeaderFooter from "@/components/TemplateDesigner/Dialogs/AddHeaderFooter.vue";
import AddImage from "@/components/TemplateDesigner/Dialogs/AddImage.vue";
import AddSignature from "@/components/TemplateDesigner/Dialogs/AddSignature.vue";
import AddMainContent from "@/components/TemplateDesigner/Dialogs/AddMainContent.vue";
import TemplatePreviewDialog from "@/components/TemplateDesigner/Dialogs/TemplatePreviewDialog.vue";
import { GetAllMedia } from "@/mixins/GetAllMedia.js";
import { FormPDFTemplate } from "@/mixins/FormPDFTemplate.js";
export default {
  components: {
    AddHeaderFooter,
    AddImage,
    AddSignature,
    AddMainContent,
    TemplatePreviewDialog,
    SavePDFWarningDialog,
  },
  props: {
    TemplateStoreObj: Object,
  },
  mixins: [GetAllMedia, FormPDFTemplate],
  data: () => ({
    tabSettings: 0,
    dialogAddImage: false,
    dialogAddSignature: false,
    dialogAddHeaderFooter: false,
    dialogAddText: false,
    dialogTemplatePreview: false,
    templateItems: [
      {
        type: "HEADER",
        position: "LEFT",
        columns: "4",
        align: "left",
        label: "Left Header",
        height: "50px",
        color: "",
        content_present: false,
        content_type: "",
        content: "",
      },
      {
        type: "HEADER",
        position: "MIDDLE",
        columns: "4",
        align: "center",
        label: "Center Header",
        height: "50px",
        color: "",
        content_present: false,
        content_type: "",
        content: "",
      },
      {
        type: "HEADER",
        position: "RIGHT",
        columns: "4",
        align: "right",
        label: "Right Header",
        height: "50px",
        color: "",
        content_present: false,
        content_type: "",
        content: "",
      },
      {
        type: "IMAGE",
        position: "TOP",
        columns: "12",
        align: "center",
        label: "Image",
        height: "50px",
        color: "",
        content_present: false,
        content_type: "IMAGE",
        content: "",
      },
      {
        type: "MAIN CONTENT",
        position: "TOP",
        columns: "12",
        align: "center",
        label: "Main Content",
        height: "200px",
        color: "",
        content_present: false,
        content_type: "TEXT",
        content: "",
      },
      {
        type: "IMAGE",
        position: "BOTTOM",
        columns: "12",
        align: "center",
        label: "Image",
        height: "50px",
        color: "",
        content_present: false,
        content_type: "IMAGE",
        content: "",
      },
      {
        type: "SIGNATURE",
        position: "",
        columns: "12",
        align: "center",
        label: "Signature",
        height: "75px",
        color: "",
        content_present: false,
        content_type: "SIGNATURE",
        content: "",
      },
      {
        type: "FOOTER",
        position: "LEFT",
        columns: "4",
        align: "left",
        label: "Left Footer",
        height: "50px",
        color: "",
        content_present: false,
        content_type: "",
        content: "",
      },
      {
        type: "FOOTER",
        position: "MIDDLE",
        columns: "4",
        align: "center",
        label: "Center Footer",
        height: "50px",
        color: "",
        content_present: false,
        content_type: "",
        content: "",
      },
      {
        type: "FOOTER",
        position: "RIGHT",
        columns: "4",
        align: "right",
        label: " Right Footer",
        height: "50px",
        color: "",
        content_present: false,
        content_type: "",
        content: "",
      },
      {
        type: "BORDER",
        content_present: false,
        content_type: "BORDER",
        content: "",
      },
      {
        type: "BACKGROUND",
        content_type: "BACKGROUND",
        content: "#FFFFFF",
      },
    ],
    StoreObj: {},
    template_html: "",
    GetAllMediaList: [],
    dialogSavePDFWarning: false,
    border_settings: {
      border_color: "#000000",
      border_size: "1px",
    },
    borderSizeItems: ["1px", "2px", "3px", "4px", "5px", "6px", "7px", "8px", "9px", "10px"],
    settingTypeITEMS: ["BORDER", "BACKGROUND"],
    background: "#FFFFFF",
    enable_border: false,
    allActiveFields: [],
  }),
  watch: {
    allActiveFields(val) {
      console.warn("Check All Active Fields", val);
    },
    background(val) {
      if (val != undefined && val != null && val != "") {
        for (let i = 0; i < this.templateItems.length; i++) {
          if (this.templateItems[i].type == "BACKGROUND") {
            this.templateItems[i].content = this.background;
          }
        }
      }
    },
    "border_settings.border_color"(val) {
      if (val != undefined && val != null && val != "") {
        for (let i = 0; i < this.templateItems.length; i++) {
          if (this.templateItems[i].type == "BORDER") {
            this.templateItems[i].content = `${this.border_settings.border_size} solid ${this.border_settings.border_color}`;
          }
        }
      }
    },
    "border_settings.border_size"(val) {
      if (val != undefined && val != null && val != "") {
        for (let i = 0; i < this.templateItems.length; i++) {
          if (this.templateItems[i].type == "BORDER") {
            this.templateItems[i].content = `${this.border_settings.border_size} solid ${this.border_settings.border_color}`;
          }
        }
      }
    },
  },
  mounted() {
    if (this.TemplateStoreObj.action == "EDIT") {
      this.templateItems = this.TemplateStoreObj.settings;
      this.template_html = this.TemplateStoreObj.template_html;
      if (this.templateItems.filter((item) => item.type == "BORDER")[0].content_present == true) {
        this.enable_border = true;
        this.border_settings.border_size = this.templateItems
          .filter((item) => item.type == "BORDER")[0]
          .content.split("solid")[0]
          .trim();
        this.border_settings.border_color = this.templateItems
          .filter((item) => item.type == "BORDER")[0]
          .content.split("solid")[1]
          .trim();
      }
      if (this.templateItems.filter((item) => item.type == "BACKGROUND")[0].content_present == true) {
        this.background = this.templateItems.filter((item) => item.type == "BACKGROUND")[0].content;
      }
      if (this.templateItems.filter((item) => item.type == "MAIN CONTENT")[0].content_present == true) {
        this.calculateActiveFieldsMethod(this.templateItems.filter((item) => item.type == "MAIN CONTENT")[0].content);
      }
    }
    this.GetAllMediaMethod();
  },
  methods: {
    calculateActiveFieldsMethod(content) {
      this.allActiveFields = [];
      for (let i = 0; i < content.split("}").length; i++) {
        if (content.split("}")[i].includes("{")) {
          if (content.split("}")[i].split("{")[1] != "") {
            this.allActiveFields.push(content.split("}")[i].split("{")[1]);
          }
        }
      }
    },
    updateActiveFields(allActiveFields) {
      console.warn("allActiveFields", this.allActiveFields);
      this.allActiveFields = allActiveFields;
    },
    async previewMethod() {
      await this.formPDFMethod();
      this.dialogTemplatePreview = true;
    },
    enableBorderMethod() {
      if (this.enable_border == true) {
        for (let i = 0; i < this.templateItems.length; i++) {
          if (this.templateItems[i].type == "BORDER") {
            this.templateItems[i].content = "1px solid #000000";
            this.templateItems[i].content_present = true;
          }
        }
      }
      if (this.enable_border == false) {
        for (let i = 0; i < this.templateItems.length; i++) {
          if (this.templateItems[i].type == "BORDER") {
            this.templateItems[i].content = "";
            this.templateItems[i].content_present = false;
          }
        }
      }
    },
    dialogSavePDFWarningEmit(Toggle, ResultObject) {
      this.dialogSavePDFWarning = false;
      if (Toggle == 2) {
        this.designTemplateEmit((Toggle = 2), ResultObject);
      }
    },
    templatePreviewDialogEmit() {
      this.dialogTemplatePreview = false;
    },
    allCommonEmit(Toggle, Object) {
      console.warn("Object",Object)
      this.dialogAddHeaderFooter = false;
      this.dialogAddImage = false;
      this.dialogAddSignature = false;
      this.dialogAddText = false;
      if (Toggle == 2) {
        if (Object.content != "") {
          this.templateItems[this.templateItems.indexOf(this.StoreObj)].color = "#e5f6ed";
          this.templateItems[this.templateItems.indexOf(this.StoreObj)].content = Object.content;
          this.templateItems[this.templateItems.indexOf(this.StoreObj)].content_type = Object.content_type;
          this.templateItems[this.templateItems.indexOf(this.StoreObj)].content_present = true;
          if (this.StoreObj.type == "SIGNATURE") {
            this.templateItems[this.templateItems.indexOf(this.StoreObj)].position = Object.position;
            this.templateItems[this.templateItems.indexOf(this.StoreObj)].signature_Settings = Object.signatureSettings;
          }
          if (this.StoreObj.type == "IMAGE") {
            this.templateItems[this.templateItems.indexOf(this.StoreObj)].position = Object.position;
            this.templateItems[this.templateItems.indexOf(this.StoreObj)].text_settings = Object.text_settings;
          }
        } else {
          this.templateItems[this.templateItems.indexOf(this.StoreObj)].color = "";
          this.templateItems[this.templateItems.indexOf(this.StoreObj)].content = "";
          this.templateItems[this.templateItems.indexOf(this.StoreObj)].content_type = "";
          this.templateItems[this.templateItems.indexOf(this.StoreObj)].content_present = false;
          if (this.StoreObj.type == "SIGNATURE") {
            this.templateItems[this.templateItems.indexOf(this.StoreObj)].position = "";
            this.templateItems[this.templateItems.indexOf(this.StoreObj)].signature_Settings = Object.signatureSettings;
          }
          if (this.StoreObj.type == "IMAGE") {
            this.templateItems[this.templateItems.indexOf(this.StoreObj)].text_settings = {};
          }
        }
      }
      this.formPDFMethod();
    },
    checkItem(item) {
      this.StoreObj = item;
      switch (item.type) {
        case "HEADER":
          this.dialogAddHeaderFooter = true;
          break;
        case "FOOTER":
          this.dialogAddHeaderFooter = true;
          break;
        case "IMAGE":
          this.dialogAddImage = true;
          break;
        case "SIGNATURE":
          this.dialogAddSignature = true;
          break;
        case "MAIN CONTENT":
          this.dialogAddText = true;
          break;
      }
    },
    designTemplateEmit(Toggle, ResultObject) {
      this.$emit("clicked", Toggle, ResultObject);
    },
  },
};
</script>
<style>
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.main_div {
  width: 700px;
  position: relative;
  height: 1060px;
}
.sub_div {
  position: absolute;
  bottom: 0px;
}
.margin-top {
  margin-top: 150px;
}
</style>
