import { RL_listReasonOfLeaving } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const RL_listReasonOfLeavingDropdown = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      reasonsOfLeavingList:[]
      
    };
  },
  methods: {
    async RL_listReasonOfLeavingMethod() {
      try {
        this.overlay = true;
        const response = await API.graphql(
          graphqlOperation(RL_listReasonOfLeaving, {
            input: {
              issuer_id: this.$store.getters.get_current_user_details.issuer_id,
            },
          })
        );
        // console.log("RL_listReasonOfLeaving", response)
        this.overlay = false;
        this.reasonsOfLeavingList = JSON.parse(
          response.data.RL_listReasonOfLeaving
        ).items[0].rl_reasons_of_leaving;
        // console.log("RL_listReasonOfLeavingMethod result", this.ListReasonsApi);
        // // console.log("response", response);
       return this.reasonsOfLeavingList;
      } catch (error) {
        // console.log("Error in RL_listReasonOfLeaving", error);
        this.overlay = false;
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
