<template>
  <div>
    <v-dialog :value="dialogDeleteNatureOfSeperation" persistent>
      <v-card flat>
        <v-toolbar dense flat dark class="gradient-bar">
          <v-toolbar-title>Delete </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
            color="white"
            @click="dialogDeleteNatureOfSeperationEmit(1)"
            light
            >mdi-close</v-icon
          >
        </v-toolbar>

        <v-card-text class="mt-5">
          <div class="text-center font-weight-bold">
            Are you sure you want to Delete
            <span class="red--text">{{ StoreObj }} </span>?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            depressed
            class="text-capitalize custom-btn"
            @click="dialogDeleteNatureOfSeperationEmit(3)"
          >
            <v-icon left>mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn
            dark
            depressed
            class="text-capitalize custom-btn"
            color="green"
            @click="dialogDeleteNatureOfSeperationEmit(2)"
            ><v-icon left>mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogDeleteNatureOfSeperation: Boolean,
    StoreObj: String,
  },
  components: {},
  data: () => ({}),
  watch: {
    dialogDeleteNatureOfSeperationEmt1(value) {
      if (value) {
        if (this.StoreObj.action == "DELETE_SEPERATIONS") {
          this.nature_of_separation = this.StoreObj.nature_of_separation;
          // console.log("delete");
        }
      }
    },
  },
  methods: {
    dialogDeleteNatureOfSeperationEmit(Toggle) {
      if (Toggle) this.$emit("clicked", Toggle);
    },
  },
};
</script>
