<template>
  <v-app>
    <Overlay :overlay="overlay" />
    <v-app-bar
      :content-class="true"
      app
      clipped-left
      color="white"
      elevation="1"
    >
      <v-app-bar-nav-icon
        v-if="RouterList.length != 0"
        @click="drawer = !drawer"
        large
        color="green"
      ></v-app-bar-nav-icon>
      <v-card height="50" width="145" class="elevation-0">
        <v-img src="@/assets/NewLogo.png"></v-img>
      </v-card>
      <!-- <h2 class="subtitle-1 font-weight-bold">RelievingLetter.com</h2> -->
      <v-divider vertical class="ml-1"></v-divider>
      <v-card
        v-show="isbigScreens"
        max-width="150px"
        class="ml-3 elevation-0 overflow-hidden"
        v-if="$store.state.account_logo_url != ''"
      >
        <v-img height="50px" contain :src="account_logo_url"></v-img>
      </v-card>
      <v-spacer></v-spacer>
      <!-- <span
        v-show="
          this.$store.getters.get_current_user_details.issuer_details
            .underModification == 'PENDING'
        "
        >Your organization is under verification</span
      > -->
      <span class="mr-4">
        <div align="center" class="PrimaryFontColorSmall">{{ user_name }}</div>
        <!-- <div class="PrimaryFontColorXSmall">
          {{ $store.getters.get_user_email }}
        </div> -->

        <span style="font-weight: normal;font-family:Arial;" color="black">
          {{ $store.getters.get_user_email }}</span
        >
      </span>

      <a href="/Home"
        ><v-icon class="" color="green"  @click="clearItem()"
          >mdi-logout</v-icon
        ></a
      >

      <!-- <v-btn depressed @click="$router.push('/')">
        <v-icon>mdi-logout</v-icon>
      </v-btn> -->
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      width="200px"
      class="navigationDrawer"
      dark
    >
      <v-list dark dense rounded>
        <v-list-item-group
          link
          dense
          rounded
          v-model="selectedItem"
          color="green"
          active-class="green"
          no-action
        >
          <v-list-item
            color="green"
            v-for="(item, idx) in RouterList"
            :key="idx"
            @click="
              $route.name != item.MenuRoute && item.has_child_routes == false
                ? $router.push(item.MenuRoute)
                : ''
            "
          >
            <v-list-item-icon class="pr-0 mr-0">
              <v-icon small color="white" v-text="item.MenuICon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content class="px-0 mx-0" :to="item.MenuRoute" link>
              <v-list-item-title
                class="white--text fontStyle"
                v-text="item.MenuName"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <router-view :user_typeBI="user_type" />
  </v-app>
</template>
<script>
var AWS = require("aws-sdk");
import Overlay from "@/components/Extras/Overlay.vue";
import RouterList from "@/JsonFiles/RouterList.json";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import { UpdateIssuerURLsAndAPIId } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  mixins: [GetCurrentUser],
  components: {
    Overlay,
  },
  data: () => ({
    clicked: false,
    drawer: true,
    model: null,
    overlay: false,
    RouterList: [],
    user_name: "",
    account_logo_url: "",
    selectedFile: null,
    selectedItem: 0,
  }),
  computed: {
    isbigScreens() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },
  watch: {
    "$store.getters.get_current_user_details"(val) {
      this.user_name = val.user_name;
      this.user_type = val.user_type;
      this.account_logo_url =
        this.$store.getters.get_current_user_details.issuer_details
          .account_logo_url != undefined &&
        this.$store.getters.get_current_user_details.issuer_details
          .account_logo_url != null &&
        this.$store.getters.get_current_user_details.issuer_details
          .account_logo_url != ""
          ? this.$store.getters.get_current_user_details.issuer_details
              .account_logo_url
          : "";
      if (val.user_type == "MEMBER") {
        this.RouterList = RouterList.filter(
          (item) =>
            item.MenuName == "Issue Letter" ||
            item.MenuName == "Verify Letter" ||
            item.MenuName == "Team Members" ||
            item.MenuName == "My Profile" ||
            item.MenuName == "Organization Info" ||
            item.Menuname == "Organization"
        );
      } else {
        if (val.issuer_details.enable_verification_requests == true) {
          this.RouterList = RouterList;
        } else {
          this.RouterList = RouterList.filter(
            (item) => item.MenuName != "Verification Requests"
          );
        }
      }
    },
  },
  async mounted() {
    await this.GetCurrentUser();
    this.account_logo_url =
      this.$store.getters.get_current_user_details.issuer_details
        .account_logo_url != undefined &&
      this.$store.getters.get_current_user_details.issuer_details
        .account_logo_url != null &&
      this.$store.getters.get_current_user_details.issuer_details
        .account_logo_url != ""
        ? this.$store.getters.get_current_user_details.issuer_details
            .account_logo_url
        : "";
  },
  methods: {
    async updateCurrentBusinessLogo(s3_URL) {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          update_type: "ACCOUNT_LOGO_URL",
          update_url: s3_URL,
        };
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(UpdateIssuerURLsAndAPIId, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateIssuerURLsAndAPIId);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.GetCurrentUser();
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    checkImagePresent() {
      this.clicked = false;
      if (
        this.account_logo_url == null ||
        this.account_logo_url == "" ||
        this.account_logo_url == undefined
      ) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.clicked = false;
      this.selectedFile = e.target.files[0];
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function () {
        self.uploadImageToS3();
      };
    },
    async uploadImageToS3() {
      this.loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region:
          self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId:
          self.$store.getters.get_current_user_details.s3_credentials
            .access_key,
        secretAccessKey:
          self.$store.getters.get_current_user_details.s3_credentials
            .secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket:
            self.$store.getters.get_current_user_details.s3_credentials
              .bucket_name,
          Key: `${
            self.$store.getters.get_current_user_details.issuer_id
          }/account_logo/${new Date().getTime()}_${self.selectedFile.name}`,
          Body: buf,
          ContentType: self.selectedFile.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
            self.loading = false;
          } else if (data) {
            var s3URL = `https://${
              param.Bucket
            }.s3.ap-south-1.amazonaws.com/${encodeURI(param.Key)}`;
            self.updateCurrentBusinessLogo(s3URL);
          }
        });
      };
    },
    clearItem() {
      this.$store.dispatch("resetState");
      this.$store.dispatch("setUserDetailsObjEmpty");
      this.$store.dispatch("setUserEmailEmpty");
      localStorage.clear();
    },
  },
};
</script>
