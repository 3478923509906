import { ListUsers } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllIssuerUsers = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetAllIssuerUsersList: [],
    };
  },
  methods: {
    async GetAllIssuerUsersMethod() {
      try {
        this.overlay = true;
        this.userTableExpand = false;
        let result = await API.graphql(
          graphqlOperation(ListUsers, {
            input: {
              user_email_id:
                this.$store.getters.get_current_user_details.user_email_id,
            },
          })
        );
        this.GetAllIssuerUsersList = JSON.parse(
          result.data.ListUsers
        ).data.items;
        // console.log("ListUsers", this.GetAllIssuerUsersList);
        this.userTableExpand = true;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.userTableExpand = true;
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
