<template>
  <div>
    <!-- <div style="background-color: beige;"> -->
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <!-- "issue-letter-Dialog ,i.e issue-letter" -->
    <IssueCredentialDialog
      :dialogIssueCredentials="dialogIssueCredentials"
      :StoreObj="StoreObj"
      :actionEnum="actionEnum"
      @clicked="IssueCredentialsEmit"
    />
    <VerifyCredentialsMore
      :dialogverifyLetterMore="verifyLetterMore"
      @clicked="closeVerifyEmit"
    />
    <EmployeeAdditionalDetails
      :dialogAdditionalDetails="showadditionaldetails"
      @clicked="closeAdditionalDetailsEmit"
      :StoreObj="StoreObj"
    />
    <VerifyDialogUnverified
      :dialogverifydialogunverified="verifydialogunverified"
      @clicked="closeverifydialogunverified"
    />
    <ViewAllPDFTemplates
      v-if="current_view == 'VIEWALLTEMPLATES'"
      @clicked="ViewAllTemplatesEmit"
    />
    <FillPDFFields
      v-if="current_view == 'FILLFIELDS'"
      :StoreObj="StoreObj"
      @clicked="FillPDFFieldsEmit"
    />

    <v-container fluid v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <v-data-iterator
        :items="GetIssuedCredentialsList"
        :items-per-page.sync="itemsPerPage"
        hide-default-footer
        :search="search"
      >
        <v-row>
          <v-col cols="12" sm="12" xs="12" md="12" lg="12" xl="12">
            <div style="text-align: center" class="ma-1">
              <h2>Issue Letter:</h2>
            </div>
            <div
              style="text-align: center"
              v-if="
                $store.getters.get_current_user_details.verification_status ==
                'Verified'
              "
            >
              <h4 class="mb-2">
                Account Type : Verified
                <v-icon color="green"> mdi-check-decagram</v-icon>
              </h4>
            </div>

            <div
              style="text-align: center"
              v-if="
                $store.getters.get_current_user_details.verification_status ==
                'Unverified'
              "
            >
              <h4 class="mb-2">
                Account Type : Unverified
                <v-icon color="red">mdi-help</v-icon>
                <v-btn
                  color="green"
                  dark
                  x-small
                  @click="verifydialogunverified = true"
                  ><v-icon x-small class="mr-1">mdi-eye</v-icon> verify</v-btn
                >
              </h4>
            </div>

            <div style="text-align: center">
              <h4 class="mb-2">Account Valid Till: {{ subscriptionTill }}</h4>
            </div>
            <div style="text-align: center">
              <v-btn
                dark
                depressed
                small
                @click="dialogIssueCredentials = true"
                color="green"
                ><v-icon left small>mdi-eye</v-icon>Issue Letter</v-btn
              >
            </div>
            <div style="text-align-center"></div>
          </v-col>
        </v-row>

        <template>
          <v-row>
            <v-col
              v-for="(item, index) in GetIssuedCredentialsList"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="4"
            >
              <v-card elevation="1" outlined class="">
                <v-card-title class="subheading font-weight-bold">
                  <v-avatar color="green" class="mr-2" size="30">
                    <v-icon dark small> mdi-account-circle </v-icon>
                  </v-avatar>
                  {{ item.issued_to }}
                </v-card-title>

                <v-divider color="black"></v-divider>
                <!-- color="#EEEEEE" -->
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Created On:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.issued_on }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Issued By:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.issued_by_user_name }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Designation:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.designation }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Worked From:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.worked_from }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Worked Till:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.worked_till }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Status:</v-list-item-content>
                    <v-list-item-content
                      class="align-end"
                      :class="
                        item.transaction_status == 'ISSUED'
                          ? 'green--text'
                          : 'blue--text'
                      "
                    >
                      {{ item.transaction_status }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Actions:</v-list-item-content>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="3" sm="1" xs="1">
                          <v-tooltip
                            bottom
                            v-if="item.transaction_status === 'ISSUED'"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div>
                                <v-btn
                                  small
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="downloadItem(item)"
                                >
                                  <v-icon color="green">mdi-download</v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>Download</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="3" sm="1" xs="1">
                          <v-tooltip
                            bottom
                            v-if="item.transaction_status === 'ISSUED'"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="openViewBlock(item)"
                              >
                                <v-icon color="green">mdi-link-lock</v-icon>
                              </v-btn>
                            </template>
                            <span class="text-center">View On Blockchain</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="3" sm="1" xs="1">
                          <v-tooltip
                            bottom
                            v-if="item.transaction_status === 'ISSUED'"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="showAdditionalDetailsMethod(item)"
                              >
                                <v-icon color="green"
                                  >mdi-account-details</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Details of Employee</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>

    <!-- 1ST TYPE -->
    <v-card
      class="ma-7"
      elevation="0"
      v-if="
        $vuetify.breakpoint.md ||
        $vuetify.breakpoint.lg ||
        $vuetify.breakpoint.xl
      "
      fixed
    >
      <v-row>
        <v-col cols="12" lg="8" md="8" xl="8" sm="12" xs="12">
          <div style="display: flex; flex-wrap: wrap">
            <div class="mt-1">
              <h2>Issue Letter:</h2>
            </div>
            <div
              class="ml-5"
              v-if="
                $store.getters.get_current_user_details.verification_status ==
                'Verified'
              "
            >
              <div class="search">
                <input
                  type="text"
                  class="search__input"
                  placeholder="Search"
                  v-model="search"
                />
                <button class="search__button">
                  <svg
                    class="search__icon"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path
                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <h4
            v-if="
              $store.getters.get_current_user_details.verification_status ==
              'Verified'
            "
            class="mt-4"
          >
            Account Type : Verified
            <v-icon color="green">mdi-check-decagram</v-icon>
          </h4>
          <h4
            v-if="
              $store.getters.get_current_user_details.verification_status ==
              'Unverified'
            "
          >
            Account Type : UnVerified
            <v-icon color="red" small>mdi-help</v-icon>
            <v-btn
              small
              dark
              class="ml-2 mb-1"
              @click="verifydialogunverified = true"
              color="green"
            >
              <v-icon left>mdi-eye</v-icon> Verify</v-btn
            >
          </h4>
        </v-col>

        <v-col
          cols="12"
          lg="4"
          md="4"
          xl="4"
          sm="12"
          xs="12"
          class="d-flex justify-end"
        >
          <div style="display: grid">
            <div class="d-flex justify-end">
              <v-btn
                style="position: relative; top: 2px"
                color="green"
                dark
                depressed
                @click="dialogIssueCredentials = true"
                ><v-icon left>mdi-plus</v-icon>Issue Letter</v-btn
              >
            </div>

            <div class="d-flex justify-end mt-5">
              <h4>Account Valid Till: {{ subscriptionTill }}</h4>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- 2ND TYPE -->
    <!--   <v-card class=" ma-7" elevation="1" outlined>
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0">
            <v-toolbar-title class="PrimaryFontColor"><h3>Issue Letter:</h3></v-toolbar-title>
          </v-toolbar>

          <v-toolbar dense class="elevation-0">
            <v-toolbar-title class="PrimaryFontColor"><h5>Acccount Type:Verified <v-icon color="green" right>mdi-check-decagram</v-icon></h5>  </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar dense class="elevation-0">
            <v-spacer></v-spacer>
            <v-text-field
            color="green"
              dense
              filled
              class="FontSize  field_height field_label_size SharpBorderRadius mt-7 mr-2"
              outlined
              label="Search"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-btn small dark color="green" class="ml-2" ><v-icon small  left>mdi-plus</v-icon>Issue Letter</v-btn>
          </v-toolbar>

          <v-toolbar dense class="elevation-0">
            <v-spacer></v-spacer>
            <v-toolbar-title class="PrimaryFontColor"><h5>Account valid Till:12-April-2024</h5></v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>
    </v-card>  -->

    <v-card
      class="ma-7"
      dense
      outlined
      elevation="1"
      v-if="
        $vuetify.breakpoint.md ||
        $vuetify.breakpoint.lg ||
        $vuetify.breakpoint.xl
      "
    >
      <v-data-table
        hide-default-footer
        :search="search"
        dense
        class="elevation-0 custom-table"
        :headers="GetIssuedCredentialsListHeaders"
        :items="GetIssuedCredentialsList"
        :footer-props="{
          'items-per-page-options': [100, 200, 300, 400, 500],
        }"
        :items-per-page="100"
        :no-data-text="noDataText"
      >
        <template v-slot:[`item.transaction_status`]="{ item }">
          <div class="FontSize">
            <v-chip
              small
              :class="
                item.transaction_status == 'ISSUED'
                  ? 'black--text'
                  : item.transaction_status == 'DELAYED'
                  ? ''
                  : 'red--text'
              "
              :color="getColor(item.transaction_status)"
            >
              {{ truncateText(item.transaction_status) }}
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.mined_at`]="{ item }">
          <div v-if="item.mined_at == undefined">-</div>
          <v-tooltip bottom v-if="item.mined_at != undefined">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="FontSize"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                v-text="
                  `${
                    new Date(item.mined_at).toDateString() +
                    ',' +
                    ' ' +
                    new Date(item.mined_at).toLocaleString().split(',')[1]
                  }`.length > 10
                    ? `${
                        new Date(item.mined_at).toDateString() +
                        ',' +
                        ' ' +
                        new Date(item.mined_at).toLocaleString().split(',')[1]
                      }`.slice(0, 10) + '..'
                    : `${
                        new Date(item.mined_at).toDateString() +
                        ',' +
                        ' ' +
                        new Date(item.mined_at).toLocaleString().split(',')[1]
                      }`
                "
              ></div>
            </template>
            <span
              v-text="
                `${
                  new Date(item.mined_at).toDateString() +
                  ',' +
                  ' ' +
                  new Date(item.mined_at).toLocaleString().split(',')[1]
                }`
              "
              class="FontSize"
            ></span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.issued_on`]="{ item }">
          <div class="FontSize">
            {{ getFormatedDate(item.issued_on) }}
          </div>
        </template>

        <template v-slot:[`item.issued_by_user_name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="FontSize" v-on="on">
                {{ truncateText(item.issued_by_user_name) }}
              </div>
            </template>
            <span>{{ item.issued_by_user_name }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.category_name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="FontSize" v-on="on">
                {{ truncateText(item.category_name) }}
              </div>
            </template>
            <span>{{ item.category_name }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.issued_to`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="FontSize" v-on="on">
                {{ truncateText(item.issued_to) }}
              </div>
            </template>
            <span>{{ item.issued_to }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.designation`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="FontSize" v-on="on">
                {{ truncateText(item.designation) }}
              </div>
            </template>
            <span>{{ item.designation }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.department`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div class="FontSize" v-on="on">
                {{ item.department ? truncateText(item.department) : "--" }}
              </div>
            </template>
            <span> {{ item.department ? item.department : "--" }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`item.worked_from`]="{ item }">
          <div class="FontSize">
            {{ getFormatedDate(item.worked_from) }}
          </div>
        </template>

        <template v-slot:[`item.worked_till`]="{ item }">
          <div class="FontSize">
            {{ getFormatedDate(item.worked_till) }}
          </div>
        </template>

        <template v-slot:[`item.type`]="{ item }">
          <div
            class="FontSize"
            v-text="
              item.type == 'DOCUMENT'
                ? 'Document'
                : item.type == 'FREE_TEXT'
                ? 'Free Text'
                : item.type == 'TEMPLATE'
                ? 'Template'
                : '-'
            "
          ></div>
        </template>
        <template v-slot:[`item.source`]="{ item }">
          <div
            class="FontSize"
            v-text="item.source == undefined ? '-' : item.source"
          ></div>
        </template>

        <!-- ONLY ACTIONS ICONS -->
        <template v-slot:item.Actions="{ item }">
          <div class="d-flex align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="green"
                  class="mr-1"
                  @click="downloadItem(item)"
                  :disabled="item.transaction_status === 'PENDING'"
                  v-on="on"
                >
                  mdi-download
                </v-icon>
              </template>
              <span>Download</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="green"
                  @click="openViewBlock(item)"
                  :disabled="item.transaction_status === 'PENDING'"
                  v-on="on"
                >
                  mdi-link-lock
                </v-icon>
              </template>
              <span>View on Blockchain</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="green"
                  class="ml-1"
                  @click="showAdditionalDetailsMethod(item)"
                  :disabled="item.transaction_status === 'PENDING'"
                  v-on="on"
                >
                  mdi-account-details
                </v-icon>
              </template>
              <span>Additional Details</span>
            </v-tooltip>
          </div>
        </template>

        <!-- V-MENU WITH CARDS AND V-CARD ACTIONS -->
        <!-- <template v-slot:item.Actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="green"
                small
                v-on="on"
                v-bind="attrs"
                @click="GetMenuOptionMethod(item)"
                :disabled="item.transaction_status === 'PENDING'"
              >
                <v-icon color="green">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-card flat v-for="(menu, index) in GetMenuOptions" :key="index">
              <v-card-actions
                @click="CheckItem(menu.menuItem, menu.MenuValue)"
                class="commonCursorPointer"
              >
                <div>
                  <v-icon small color="green">{{ menu.MenuIcon }}</v-icon>
                  <span class="pl-2 FontSize">{{ menu.MenuText }}</span>
                </div>
              </v-card-actions>
             <hr style="color:#e0e0e0">

            </v-card>
          </v-menu>
        </template> -->

        <!-- V-MENU WITH ICONS -->
        <!-- <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon small color="green" v-on="on">mdi-dots-vertical</v-icon>
            </template>

            <v-card dense outlined>
              <v-card-actions>
                <div>
                  <div class="ma-1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="green"
                          class="mr-1"
                          @click="downloadItem(item)"
                          v-on="on"
                          >mdi-download</v-icon
                        >
                        <span class="ml-2">Download</span>
                      </template>
                      <span>Download</span>
                    </v-tooltip>
                  </div>

                  <div class="ma-1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="green"
                          @click="openViewBlock(item)"
                          v-on="on"
                          >mdi-link-lock</v-icon
                        ><span class="ml-2">View On Blockchain</span>
                      </template>
                      <span>View On Blockchain</span>
                    </v-tooltip>
                  </div>

                  <div class="ma-1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="green"
                          class="ml-1"
                          @click="showAdditionalDetailsMethod(item)"
                          v-on="on"
                          >mdi-account-details</v-icon
                        >
                        <span class="ml-2">Show Additional Details</span>
                      </template>
                      <span>Show Additional Details</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-card-actions>
            </v-card>
          </v-menu>      </template>-->
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { GetAllIssuedCredentials } from "@/mixins/GetAllIssuedCredentials.js"; //List-Credentials "Query"
import IssueCredentialDialog from "@/components/Dashboard/Dialogs/IssueCredentialDialog.vue"; //Issue-Letter Btn
import VerifyDialogUnverified from "@/components/Dashboard/Dialogs/VerifyDialogUnverified.vue"; //verify that organization
import EmployeeAdditionalDetails from "@/components/Dashboard/Dialogs/EmployeeAdditionalDetails.vue"; //Employee Addition-Details
import VerifyCredentialsMore from "@/components/Dashboard/Dialogs/VerifyCredentialsMore.vue";
import FillPDFFields from "@/components/Dashboard/Cards/FillPDFFields.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js"; //GetCurrentUser
export default {
  components: {
    Overlay,
    Snackbar,
    IssueCredentialDialog,
    FillPDFFields,
    VerifyCredentialsMore,
    EmployeeAdditionalDetails,
    VerifyDialogUnverified,
  },
  mixins: [GetAllIssuedCredentials, GetCurrentUser],
  data: () => ({
    height: 0,
    //   CheckitemsArray: [
    //   { icon: ,title:"Download" },
    //   { icon: 'mdi-link-box-variant',title:"View on block chain" },
    //   { icon: 'mdi-account-details', title:"Additional details"},

    // ],

    GetMenuOptions: [],
    headers: [
      {
        text: "Dessert (100g serving)",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Calories", value: "calories" },
      { text: "Fat (g)", value: "fat" },
      { text: "Carbs (g)", value: "carbs" },
      { text: "Protein (g)", value: "protein" },
      { text: "Iron (%)", value: "iron" },
    ],
    height: 0,
    itemsPerPage: 4,
    verifydialogunverified: false,
    showadditionaldetails: false,
    verifyLetterMore: false,
    dashboardTableExpand: false,
    dialogIssueCredentials: false,
    dialogDownloadCredentials: false,
    search: null,
    dashboard: {
      procured: 0,
      consumed: 0,
      balance: 0,
      subscription_Till: "",
    },
    current_view: "DASHBOARD",
    items: [{ title: "With Document", value: "WITHDOCUMENT" }],
    overlay: false,
    SnackBarComponent: {},
    GetIssuedCredentialsList: [],

    GetIssuedCredentialsListHeaders: [
      //{ text: "Transaction Id", value: "credential_number" },
      { text: "Created On", value: "issued_on" },
      { text: "Issued By", value: "issued_by_user_name" },
      { text: "Issued To", value: "issued_to" },
      { text: "Designation", value: "designation" },
      { text: "Department", value: "department" },
      { text: "Worked From", value: "worked_from" },
      { text: "Worked Till", value: "worked_till" },
      { text: "Status", value: "transaction_status" },
      { text: "Actions", value: "Actions" },
    ],
    StoreObj: {},

    clearInterval: null,
    actionEnum: "",
    template_fields_array: [],
    Connection: null,
    showButton: "",
  }),
  watch: {
    getCurrentUserDetailsObject() {
      this.dashboard = {
        procured:
          this.$store.getters.get_current_user_details.issuer_details
            .total_credits,
        consumed:
          this.$store.getters.get_current_user_details.issuer_details
            .credits_issued,
        balance:
          this.$store.getters.get_current_user_details.issuer_details
            .available_credits,
      };
    },
  },

  async mounted() {
    this.height = window.innerHeight - 190;
    await this.$vuetify.goTo(0, 0);
    // console.log(
    //   "this.$store.getters.get_ListVeirifiedDocuments.issued_to",
    //   this.$store.getters.get_ListVeirifiedDocuments
    // );
    await this.GetCurrentUser();
    // console.log(
    //   "issuer_details",
    //   this.$store.getters.get_ListVeirifiedDocuments.issued_to
    // );
    await this.GetIssuedCredentialsMethod();
    // this.issuedArray= await GetIssuedCredentialsMethod();
    // console.log("this.$store.getters.get_ListVeirifiedDocuments.issued_to", this.$store.getters.get_ListVeirifiedDocuments)
    await this.checkverifyButton();
    // console.log(
    //   "Reasons for leaving:-->",
    //   this.$store.getters.get_ListVeirifiedDocuments.additional_details
    // );
    // console.log(
    //   "User-Status:-->",
    //   this.$store.getters.get_current_user_details.user_status
    // );
  },
  computed: {
    subscriptionTill() {
      const currentDate = new Date(
        this.$store.getters.get_current_user_details.issuer_details.subscription_till
      );
      const day = currentDate.getDate();
      const month = currentDate.toLocaleString("en-US", { month: "short" });
      const year = currentDate.getFullYear();
      return day + " " + month + " " + year;
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    isbigScreen() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg;
    },
  },
  methods: {
    getFormatedDate(date) {
      let dateArray = date.split("-");

      return `${dateArray[0]} ${dateArray[1].substring(0, 3)} ${dateArray[2]}`;
    },
    truncateText(text) {
      const maxLength = 15; // Set your desired maximum length
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    GetMenuOptionMethod(item) {
      this.GetMenuOptions = [];
      this.GetMenuOptions.push(
        {
          MenuText: "Download",
          MenuIcon: "mdi-download",
          MenuValue: "DOWNLOAD",
          menuItem: item,
        },
        {
          MenuText: "View on Blockchain",
          MenuIcon: "mdi-link-box-variant",
          MenuValue: "VIEW_BLOCKCHAIN",
          menuItem: item,
        },
        {
          MenuText: "View Additional Details",
          MenuIcon: "mdi-account-details",
          MenuValue: "VIEW_ADDITIONAL_DETAILS",
          menuItem: item,
        }
      );
    },
    async CheckItem(item, action) {
      // console.log("item", item);
      switch (action) {
        case "DOWNLOAD":
          await this.downloadItem(item);

          // console.log("From Download");
          break;
        case "VIEW_BLOCKCHAIN":
          this.openViewBlock(item);
          // console.log("From View BlockChain");
          break;
        case "VIEW_ADDITIONAL_DETAILS":
          this.showAdditionalDetailsMethod(item);

          // console.log("From View Additional Details");
          break;
      }
    },
    getColor(transaction_status) {
      if (transaction_status == "ISSUED") return "#e0e0e0";
      else return "#e0e0e0";
    },
    checkverifyButton() {
      if (
        this.$store.getters.get_current_user_details.verification_status ==
          "Verified" ||
        this.$store.getters.get_current_user_details.issuer_details
          .is_verification_request == true
      ) {
        this.showButton = "2";
      } else if (
        this.$store.getters.get_current_user_details.verification_status ==
        "Unverified"
      ) {
        this.showButton = "1";
      }
    },
    showAdditionalDetailsMethod(item) {
      this.showadditionaldetails = true;
      this.StoreObj = item.additional_details;
    },
    closeAdditionalDetailsEmit(val) {
      if (val == false) {
        this.showadditionaldetails = false;
      }
    },
    closeVerifyEmit(val) {
      if (val == false) {
        this.verifyLetterMore = false;
      }
    },

    async downloadItem(item) {
      this.overlay = true;
      const response = await fetch(item.URL);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.target = "_blank";
      anchor.download = `${item.issued_to}_RelievingLetter`;
      anchor.click();
      URL.revokeObjectURL(blobUrl);
      this.overlay = false;
    },

    // async downloadItem(item) {
    //   const response = await fetch(item.URL);
    //   // console.log("item", item);
    //   const blob = await response.blob();
    //   // console.log("blob", blob);
    //   const blobUrl = URL.createObjectURL(blob);
    //   // console.log("blobUrl", blobUrl);
    //   const anchor = document.createElement("a");
    //   // console.log("anchor", anchor);
    //   anchor.href = blobUrl;
    //   // console.log(" anchor.href ", anchor.href);

    //   anchor.target = "_blank";
    //   // console.log("anchor.target", anchor.target);
    //   // anchor.download = `RL_${new Date(item.transacted_on).getTime()}_${
    //   //   item.URL.split("_")[item.URL.split("_").length - 1]
    //   // }`;
    //   anchor.download = `RL_${
    //     item.URL.split("_")[item.URL.split("_").length - 2]
    //   }_${item.URL.split("_")[item.URL.split("_").length - 1]}`;
    //   // console.log("anchor.download", anchor.download);
    //   anchor.click();

    //   URL.revokeObjectURL(blobUrl);
    // },

    WebSocketConnection() {
      this.Connection = new WebSocket(
        `wss://oag87gu424.execute-api.ap-south-1.amazonaws.com/surecreds?issuer_id=${this.$store.getters.get_current_user_details.issuer_id}`
      );
      var self = this;
      (self.Connection.onopen = function () {
        console.warn("Check On Open");
      }),
        (self.Connection.onclose = function () {
          self.WebSocketConnection();
        }),
        (self.Connection.onmessage = function (event) {
          for (let i = 0; i < self.GetIssuedCredentialsList.length; i++) {
            if (
              JSON.parse(event.data).transaction_id ==
              self.GetIssuedCredentialsList[i].transaction_id
            ) {
              self.GetIssuedCredentialsList[i].transaction_status = "ISSUED";
              self.GetIssuedCredentialsList[i]["mined_at"] = JSON.parse(
                event.data
              ).mined_at;
            }
            // self.$forceUpdate();
          }
          var audio = new Audio(require("@/assets/CIS.mp3"));
          audio.play();
         
        });
    },
    FillPDFFieldsEmit(Toggle, ResultObject) {
      if (Toggle == 1) {
        this.current_view = "VIEWALLTEMPLATES";
      } else {
        this.current_view = "DASHBOARD";
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: ResultObject.Status_Message,
        };
        this.GetIssuedCredentialsMethod();
        this.dashboard = {
          procured: ResultObject.credits.total_credits,
          consumed: ResultObject.credits.credits_issued,
          balance: ResultObject.credits.available_credits,
        };
      }
    },

    IssueCredentialsEmit(Toggle, ResultObject) {
      this.dialogIssueCredentials = false;
      if (Toggle == 2) {
        this.GetIssuedCredentialsMethod();

        this.WebSocketConnection();
        this.dashboard = {
          procured: ResultObject.credits.total_credits,
          consumed: ResultObject.credits.credits_issued,
          balance: ResultObject.credits.available_credits,
        };
      }
    },
    closeverifydialogunverified(val) {
      if (val == false) {
        this.verifydialogunverified = false;
        this.GetCurrentUser();
        setTimeout(() => {
          this.checkverifyButton();
        }, 1500);
      }
    },
    FormViewEmit(Toggle) {
      if (Toggle == 1) {
        this.current_view = "VIEWALLTEMPLATES";
      } else if (Toggle == 2) {
        this.current_view = "DASHBOARD";
      } else if (Toggle == 3) {
        this.current_view = "DASHBOARD";
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Credential Prepared Successfully !!",
        };
        this.GetIssuedCredentialsMethod();
      }
    },
    ViewAllTemplatesEmit(Toggle, item) {
      if (Toggle == 1) {
        this.StoreObj = item;
        this.current_view = "FILLFIELDS";
      } else if (Toggle == 2) {
        this.current_view = "DASHBOARD";
      } else if (Toggle == 3) {
        this.current_view = "DASHBOARD";
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: "Credential Will Be Issued Shortly !!",
        };
      }
    },
    checkMenuItem(item) {
      if (this.available_credits != 0) {
        this.actionEnum = item.value;
        if (item.value != "TEMPLATIZED") {
          this.dialogIssueCredentials = true;
        } else {
          this.current_view = "VIEWALLTEMPLATES";
        }
      } else {
        this.dialogRecharge = true;
      }
    },
    checkInterval() {
      var self = this;
      self.clearInterval = setInterval(function () {
        self.GetIssuedCredentialsMethod();
      }, 60000);
    },
    openViewBlock(item) {
      window.open(`https://viewblock.io/arweave/tx/${item.transaction_id}`);
    },
    dialogDownloadCredentialsEmit() {
      this.dialogDownloadCredentials = false;
    },
  },
};

// async uploadLogoToS3() {
//   this.loading = true;
//   var self = this;
//   self.file_type = `${self.selectedFile.name}`;

//   const key = `BMS/Issuer/account_logo/${new Date().getTime()}_${
//     this.file_type
//   }`;
//   await self.GetPresignedUrlForS3UploadMethod(key);
//   const response = await this.https3UploadFunction();
//   if (response == "Success") {
//     self.EditOrganization(key);
//   } else {
//     console.error("Error whileexecuting this -->EditOrganization");
//   }
// },

// async https3UploadFunction() {
//   const response = await axios.put(
//     this.PresignedUrlForS3Upload,
//     this.selectedFile,
//     {
//       headers: {
//         "Content-Type": this.selectedFile.type,
//       },
//     }
//   );
//   if (response.status == 200 && response.statusText == "OK") {
//     this.SnackBarComponent = {
//       SnackbarVmodel: true,
//       SnackbarColor: "green",
//       Top: true,
//       SnackbarText: "Uploaded Successfully!!",
//   }
//     console.log("upload completed:-->", response);
//     console.log("response.status:-->", response.status);
//     console.log("response.statusText:-->", response);
//     return "Success";

//   } else {
//     this.loading = false;
//     console.log("Error uploading file", response);
//   }
// },
</script>

<style scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.search__input {
  font-family: inherit;
  font-size: inherit;
  background-color: #f4f2f2;
  border: none;
  color: #646464;
  padding: 0.7rem 1rem;
  border-radius: 30px;
  width: 18em;
  transition: all ease-in-out 0.5s;
  margin-right: -2rem;
}

.search__input:hover,
.search__input:focus {
  box-shadow: 0 0 1em #00000013;
}

.search__input:focus {
  outline: none;
  background-color: #f0eeee;
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: #ccc;
}

.search__input:focus + .search__button {
  background-color: #f0eeee;
}

.search__button {
  border: none;
  background-color: #f4f2f2;
  margin-top: 0.1em;
}

.search__button:hover {
  cursor: pointer;
}

.search__icon {
  height: 1.3em;
  width: 1.3em;
  fill: #b4b4b4;
}
</style>
