<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogChangeOwnership" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="secondary">
          <div>Transfer Ownership</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogChangeOwnershipEmit((Toggle = 1))"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="text-center">
          <v-icon class="mt-2" size="50px" color="green"
            >mdi-account-star</v-icon
          >
          <div>Make {{ StoreObj.user_name }} as Owner ?</div>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <!-- <v-btn small class="cancelButton text-capitalize white--text" outlined @click="dialogChangeOwnershipEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          > -->

          <v-btn
            small
            class="mr-2 internalButtons text-capitalize white--text"
            @click="dialogChangeOwnershipEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >

          <v-btn
            small
            class="mr-2 internalButtons text-capitalize white--text"
            color="green"
            :loading="loading"
            @click="transferOwnershipMethod()"
            >Confirm<v-icon small class="ml-1">mdi-check</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { TransferOwnership } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogChangeOwnership: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
  }),
  watch: {},
  methods: {
    async transferOwnershipMethod() {
      try {
        var inputParams = {
          user_id: this.StoreObj.user_id,
          admin_user_id: this.$store.getters.get_current_user_details.user_id,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(TransferOwnership, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.TransferOwnership);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogChangeOwnershipEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        console.warn(error);
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogChangeOwnershipEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
