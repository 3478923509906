<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" v-if="renderComp" />
    <Overlay :overlay="overlay" />
    <AddIssuerUserDialog
      :dialogAddIssuer="dialogAddIssuer"
      @clicked="dialogCreateUsersEmit"
    />
    <ActivateInactivateUserDialog
      :dialogActivateInactivateUser="dialogActivateInactivateUser"
      :StoreObj="StoreObj"
      @clicked="dialogActivateInactivateUserEmit"
    />
    <ChangeOwnershipDialog
      :StoreObj="StoreObj"
      :dialogChangeOwnership="dialogChangeOwnership"
      @clicked="dialogChangeOwnershipEmit"
    />
    <!-- Mobile -->
    <!-- <v-card class="elevation-0 mt-2" v-if="$vuetify.breakpoint.name == 'xs'">
      <v-row>
        <v-col cols="6" xs="6" sm="6" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew ">Team Members</div>
            <v-toolbar-title>
              <h3 class="ml-5 mt-4">Team</h3>
              <h3 class="ml-5 mb-2">Members</h3>
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col cols="6" xs="6" sm="6" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn
              small
              @click="dialogAddIssuer = true"
              class="white--text"
              color="primary"
              ><v-icon small class="mr-1">mdi-plus</v-icon>Invite Member</v-btn
            >
          </v-toolbar>
        </v-col>
      </v-row>
    </v-card> -->
    <!--  -->
    <v-container fluid v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <v-data-iterator
        :items="GetAllIssuerUsersList"
        :items-per-page.sync="itemsPerPage"
        hide-default-footer
      >
        <!-- <template v-slot:header>
          <v-toolbar class="" color="" flat>
            <v-toolbar-title>
              <h3>Team Members:</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn color="green" dark depressed small @click="dialogAddIssuer = true" class="internalButtons white--text mr-2" v-show="$store.getters.get_user_type == 'OWNER' ||
              $store.getters.get_user_type == 'ADMIN'
              "><v-icon left small>mdi-plus</v-icon>Invite Member</v-btn>
          </v-toolbar>
        </template> -->

        <v-row align="center">
          <v-col cols="12" xs="12" sm="12">
            <div style="text-align: center" class="ma-2">
              <h2>Team Members:</h2>
            </div>
            <div style="text-align: center">
              <v-btn
                color="green"
                dark
                depressed
                small
                @click="dialogAddIssuer = true"
                class="internalButtons white--text mr-2"
                v-show="
                  $store.getters.get_user_type == 'OWNER' ||
                  $store.getters.get_user_type == 'ADMIN'
                "
                ><v-icon left small>mdi-plus</v-icon>Invite Member</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <template>
          <v-row>
            <v-col
              v-for="(item, index) in GetAllIssuerUsersList"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              lg="4"
            >
              <v-card elevation="1" outlined class="">
                <v-card-title class="subheading font-weight-bold">
                  <v-avatar color="green" dark class="mr-2" size="30">
                    <v-icon dark small> mdi-account-circle </v-icon>
                  </v-avatar>
                  {{ item.user_name }}
                </v-card-title>

                <v-divider color="black"></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Email Id:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.user_email_id }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Created On:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ new Date(item.user_created_on).toLocaleString() }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>User Type:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ item.user_type }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Status:</v-list-item-content>
                    <v-list-item-content
                      class="align-end"
                      :class="
                        item.user_status == 'ACTIVE'
                          ? 'green--text'
                          : 'red--text'
                      "
                    >
                      {{ item.user_status }}
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>Actions:</v-list-item-content>
                    <v-list-item-content>
                      <span
                        v-if="
                          $store.getters.get_user_type == 'OWNER' ||
                          $store.getters.get_user_type == 'ADMIN'
                        "
                      >
                        <span
                          v-if="
                            item.user_status == 'ACTIVE' ||
                            item.user_status == 'INACTIVE'
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-if="
                                  item.user_type == 'MEMBER' ||
                                  item.user_type == 'ADMIN'
                                "
                                icon
                                class="text-capitalize"
                                :color="
                                  item.user_status == 'ACTIVE' ? 'red' : 'green'
                                "
                                @click="checkItem(item, 'ACTION')"
                              >
                                <v-icon
                                  v-text="
                                    item.user_status == 'ACTIVE'
                                      ? 'mdi-account-minus'
                                      : 'mdi-account-plus'
                                  "
                                ></v-icon>
                              </v-btn>
                            </template>
                            <span
                              v-text="
                                item.user_status == 'ACTIVE'
                                  ? 'Deactivate'
                                  : 'Activate'
                              "
                            ></span>
                          </v-tooltip>
                        </span>

                        <v-tooltip
                          bottom
                          v-if="
                            item.user_type == 'MEMBER' &&
                            item.user_status == 'ACTIVE'
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              class="text-capitalize"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              @click="PromoteOrDemote(item, 'Promote')"
                            >
                              <v-icon
                                v-text="'mdi-arrow-up-bold'"
                                color="green"
                              ></v-icon>
                            </v-btn>
                          </template>
                          <span align="center">Promote To Admin</span>
                        </v-tooltip>

                        <v-tooltip
                          bottom
                          v-if="
                            item.user_type == 'ADMIN' &&
                            item.user_status == 'ACTIVE'
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="primary"
                              class="text-capitalize"
                              v-bind="attrs"
                              v-on="on"
                              icon
                              @click="PromoteOrDemote(item, 'Demote')"
                            >
                              <v-icon
                                v-text="'mdi-arrow-down-bold'"
                                color="green"
                              ></v-icon>
                            </v-btn>
                          </template>
                          <span align="center">Demote To Member</span>
                        </v-tooltip>

                        <span v-if="$store.getters.get_user_type == 'OWNER'">
                          <v-tooltip
                            bottom
                            v-if="
                              item.user_type == 'ADMIN' &&
                              item.user_status == 'ACTIVE'
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                color="primary"
                                class="text-capitalize"
                                v-bind="attrs"
                                v-on="on"
                                icon
                                @click="checkItem(item, 'OWNERSHIP')"
                              >
                                <v-icon
                                  v-text="'mdi-briefcase-arrow-up-down'"
                                  color="green"
                                ></v-icon>
                              </v-btn>
                            </template>
                            <span align="center">Transfer Ownership</span>
                          </v-tooltip>
                        </span>
                      </span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>

    <v-card
      class="ma-7"
      elevation="0"
      v-if="
        $vuetify.breakpoint.md ||
        $vuetify.breakpoint.lg ||
        $vuetify.breakpoint.xl
      "
      fixed
    >
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <div style="display: flex; flex-wrap: wrap">
            <div class="mt-1">
              <h2>Team Members:</h2>
            </div>
            <div class="ml-5">
              <div class="search">
                <input
                  type="text"
                  class="search__input"
                  placeholder="Search"
                  v-model="search"
                />
                <button class="search__button">
                  <svg
                    class="search__icon"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                  >
                    <g>
                      <path
                        d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </v-col>

        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          class="d-flex justify-end"
        >
          <div style="display: grid">
            <div class="d-flex justify-end">
              <v-btn
                style="position: relative; top: 10px"
                color="green"
                dark
                depressed
                @click="dialogAddIssuer = true"
                ><v-icon left>mdi-plus</v-icon>Invite Member</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      class="ma-7"
      dense
      outlined
      elevation="1"
      v-if="
        $vuetify.breakpoint.md ||
        $vuetify.breakpoint.lg ||
        $vuetify.breakpoint.xl
      "
    >
      <v-data-table
        v-if="userTableExpand == true"
        dense
        class="elevation-0 mt-2"
        :headers="GetIssuerListHeaders"
        :search="search"
        :items="GetAllIssuerUsersList"
        :no-data-text="noDataText"
      >
        <template v-slot:[`item.sl_no`]="{ item }">
          <div class="FontSize">
            {{ GetAllIssuerUsersList.indexOf(item) + 1 }}
          </div>
        </template>

        <template v-slot:[`item.user_email_id`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="FontSize" v-on="on" v-bind="attrs">
                {{ truncateText(item.user_email_id, 20) }}
              </div>
            </template>
            <span>{{ item.user_email_id }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.user_name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="FontSize" v-on="on" v-bind="attrs">
                {{ truncateText(item.user_name, 20) }}
              </div>
            </template>
            <span>{{ item.user_name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.user_created_on`]="{ item }">
          <div class="FontSize">
            {{
              new Date(item.user_created_on)
                .toLocaleString("en-GB", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                  hour12: true,
                })
                .replace(/\//g, " ")
            }}
          </div>
        </template>
        <template v-slot:[`item.user_type`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div class="FontSize" v-on="on" v-bind="attrs">
                {{ item.user_type.replace("_", " ") }}
              </div>
            </template>
            <span>{{ item.user_type }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.user_status`]="{ item }">
          <div class="FontSize">
            <v-chip
              small
              :class="
                item.user_status == 'ACTIVE' ? 'green--text' : 'red--text'
              "
              :color="getColor(item.user_status)"
            >
              {{ item.user_status }}
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.Actions`]="{ item }">
          <span
            v-if="
              $store.getters.get_user_type == 'OWNER' ||
              $store.getters.get_user_type == 'ADMIN'
            "
          >
            <span
              v-if="
                item.user_status == 'ACTIVE' || item.user_status == 'INACTIVE'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    v-if="
                      item.user_type == 'MEMBER' || item.user_type == 'ADMIN'
                    "
                    icon
                    class="text-capitalize"
                    :color="item.user_status == 'ACTIVE' ? 'red' : 'green'"
                    @click="checkItem(item, 'ACTION')"
                  >
                    <v-icon
                      v-text="
                        item.user_status == 'ACTIVE'
                          ? 'mdi-account-minus'
                          : 'mdi-account-plus'
                      "
                    ></v-icon>
                  </v-btn>
                </template>
                <span
                  v-text="
                    item.user_status == 'ACTIVE' ? `Deactivate` : `Activate`
                  "
                ></span>
              </v-tooltip>
            </span>

            <v-tooltip
              bottom
              v-if="item.user_type == 'MEMBER' && item.user_status == 'ACTIVE'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="PromoteOrDemote(item, 'Promote')"
                >
                  <v-icon v-text="'mdi-arrow-up-bold'" color="green"></v-icon>
                </v-btn>
              </template>
              <span align="center">Promote To Admin</span>
            </v-tooltip>

            <v-tooltip
              bottom
              v-if="item.user_type == 'ADMIN' && item.user_status == 'ACTIVE'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="PromoteOrDemote(item, 'Demote')"
                >
                  <v-icon v-text="'mdi-arrow-down-bold'" color="green"></v-icon>
                </v-btn>
              </template>
              <span align="center">Demote To Member</span>
            </v-tooltip>
            <span v-if="$store.getters.get_user_type == 'OWNER'">
              <v-tooltip
                bottom
                v-if="item.user_type == 'ADMIN' && item.user_status == 'ACTIVE'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="text-capitalize"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    @click="checkItem(item, 'OWNERSHIP')"
                  >
                    <v-icon
                      v-text="'mdi-briefcase-arrow-up-down'"
                      color="green"
                    ></v-icon>
                  </v-btn>
                </template>
                <span align="center">Transfer Ownership</span>
              </v-tooltip>
            </span>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { RLGetCurrentUserDetails } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import Overlay from "@/components/Extras/Overlay.vue";
import { GetAllIssuerUsers } from "@/mixins/GetAllIssuerUsers.js";
import { UpgradeToAdmin } from "@/graphql/mutations.js";
import { DowngradeToMember } from "@/graphql/mutations.js";
import AddIssuerUserDialog from "@/components/Users/Dialogs/AddIssuerUserDialog.vue";
import ActivateInactivateUserDialog from "@/components/Users/Dialogs/ActivateInactivateUserDialog.vue";
import ChangeOwnershipDialog from "@/components/Users/Dialogs/ChangeOwnershipDialog.vue";
export default {
  components: {
    Overlay,
    AddIssuerUserDialog,
    ActivateInactivateUserDialog,
    ChangeOwnershipDialog,
    Snackbar,
  },
  mixins: [GetAllIssuerUsers],
  data: () => ({
    search: null,
    itemsPerPage: 4,
    overlay: false,
    noDataText: "",
    userTableExpand: false,
    GetIssuedCredentialsList: [],
    GetIssuerListHeaders: [
      { text: "Sl No.", value: "sl_no" },
      { text: "Name", value: "user_name" },
      { text: "Email ID", value: "user_email_id" },
      { text: "Created On", value: "user_created_on" },
      { text: "User Type", value: "user_type" },
      { text: "Status", value: "user_status" },
      { text: "Actions", value: "Actions" },
    ],
    StoreObj: {},
    renderComp: true,
    dialogAddIssuer: false,
    dialogChangeOwnership: false,
    dialogActivateInactivateUser: false,
    GetAllIssuerUsersList: [],
    SnackBarComponent: {},
  }),
  async mounted() {
    this.$vuetify.goTo(0, 0);
    await this.GetAllIssuerUsersMethod();
    await this.GetCurrentUser();

    // if (this.$store.getters.get_current_user_details.user_type != "OWNER") {
    //   this.GetIssuerListHeaders = this.GetIssuerListHeaders.filter(
    //     (item) => item.text != "Actions"
    //   );
    // }
  },
  methods: {
    truncateText(text) {
      const maxLength = 20; // Set your desired maximum length
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    getColor(user_status) {
      if (user_status == "ACTIVE") return "#e0e0e0";
      else return "#e0e0e0";
    },
    async GetCurrentUser() {
      let result = await API.graphql(
        graphqlOperation(RLGetCurrentUserDetails, {
          input: {
            user_email_id: this.$store.getters.get_user_email,
          },
        })
      );
      this.$store.commit(
        "SET_USER_TYPE",
        JSON.parse(result.data.RLGetCurrentUserDetails).data.items[0].user_type
      );
    },
    dialogChangeOwnershipEmit(Toggle) {
      this.dialogChangeOwnership = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersMethod();
        this.GetCurrentUser();
      }
    },
    checkItem(item, ACTION) {
      this.StoreObj = item;
      switch (ACTION) {
        case "ACTION":
          this.dialogActivateInactivateUser = true;
          break;
        case "OWNERSHIP":
          this.dialogChangeOwnership = true;
          break;
      }
    },
    dialogActivateInactivateUserEmit(Toggle) {
      this.dialogActivateInactivateUser = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersMethod();
      }
    },
    dialogCreateUsersEmit(Toggle) {
      this.dialogAddIssuer = false;
      if (Toggle == 2) {
        this.GetAllIssuerUsersMethod();
      }
    },
    IssueCredentialsEmit(Toggle, response) {
      this.dialogAddIssuer = false;
      if (Toggle == 2) {
        this.StoreObj = response;
        this.GetIssuedCredentialsMethod();
      }
    },
    async PromoteOrDemote(item, type) {
      // console.log(item);
      if (type == "Promote") {
        let inputParams = {
          admin_user_id: this.$store.getters.get_current_user_details.user_id,
          member_user_id: item.user_id,
        };
        let result = await API.graphql(
          graphqlOperation(UpgradeToAdmin, {
            input: inputParams,
          })
        );
        let resultObject = JSON.parse(result.data.UpgradeToAdmin);
        if (resultObject.status == 200) {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: resultObject.message,
              timeout: 4000,
            };
            this.renderComp = true;
          });
          this.GetAllIssuerUsersMethod();
        }
      } else {
        let inputParams = {
          admin_user_id: this.$store.getters.get_current_user_details.user_id,
          downgrade_user_id: item.user_id,
        };
        let result = await API.graphql(
          graphqlOperation(DowngradeToMember, {
            input: inputParams,
          })
        );
        let resultObject = JSON.parse(result.data.DowngradeToMember);
        if (resultObject.status == 200) {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: resultObject.message,
              timeout: 4000,
            };
            this.renderComp = true;
          });
          this.GetAllIssuerUsersMethod();
        }
      }
    },
  },
};
</script>

<style scoped>
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.search__input {
  font-family: inherit;
  font-size: inherit;
  background-color: #f4f2f2;
  border: none;
  color: #646464;
  padding: 0.7rem 1rem;
  border-radius: 30px;
  width: 15em;
  transition: all ease-in-out 0.5s;
  margin-right: -2rem;
}

.search__input:hover,
.search__input:focus {
  box-shadow: 0 0 1em #00000013;
}

.search__input:focus {
  outline: none;
  background-color: #f0eeee;
}

.search__input::-webkit-input-placeholder {
  font-weight: 100;
  color: #ccc;
}

.search__input:focus + .search__button {
  background-color: #f0eeee;
}

.search__button {
  border: none;
  background-color: #f4f2f2;
  margin-top: 0.1em;
}

.search__button:hover {
  cursor: pointer;
}

.search__icon {
  height: 1.3em;
  width: 1.3em;
  fill: #b4b4b4;
}
</style>
