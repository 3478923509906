<template>
  <div>
    <Overlay :overlay="overlay" />
    <Snackbar :SnackBarComponent="SnackBarComponent" />

    <v-container fluid v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      <div class="ma-2">
        <h2>My Profile</h2>
      </div>

      <template>
        <v-card elevation="2" :width="500" outlined>
          <v-card-title class="subheading font-weight-bold">
            <v-avatar color="green" class="mr-2" size="30">
              <v-icon dark small>mdi-account-circle</v-icon>
            </v-avatar>

            <template v-if="!editingName">
              {{ user_details.user_name }}
              <v-spacer></v-spacer>
              <v-icon color="green" @click="editingName = true"
                >mdi-pencil</v-icon
              >
            </template>

            <template v-else>
              <v-text-field
                :maxlength="35"
                color="green"
                v-model="user_details.user_name"
                outlined
                dense
                ref="userNameTextField"
                @input="handleFieldChange"
                class="mt-6"
              >
                <template v-slot:append>
                  <v-icon color="red" @click="cancelEditing">mdi-close</v-icon>
                </template>
              </v-text-field>

              <v-icon
                color="green"
                class="ml-3"
                @click="updateUserDetailsMethod"
              >
                mdi-content-save
              </v-icon>
            </template>
          </v-card-title>

          <v-divider color="black"></v-divider>

          <v-list dense>
            <v-list-item>
              <v-list-item-content>Email Id:</v-list-item-content>
              <v-list-item-content class="align-end">
                {{ user_details.user_email_id }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>Contact Number:</v-list-item-content>
              <v-list-item-content class="align-end">
                {{ user_details.contact_number }}
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>User Type:</v-list-item-content>
              <v-list-item-content class="align-end">
                {{ user_details.user_type }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </template>
    </v-container>

    <v-card
      class="ma-7"
      elevation="0"
      v-if="
        $vuetify.breakpoint.md ||
        $vuetify.breakpoint.lg ||
        $vuetify.breakpoint.xl
      "
      fixed
    >
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <div style="display: flex; flex-wrap: wrap">
            <div class="mt-1">
              <h2>My Profile:</h2>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <template>
      <v-card
        class="ma-7"
        elevation="2"
        :width="500"
        outlined
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-card-title class="subheading font-weight-bold">
          <v-avatar color="green" class="mr-2" size="30">
            <v-icon dark small>mdi-account-circle</v-icon>
          </v-avatar>

          <template v-if="!editingName">
            {{ user_details.user_name }}
            <v-spacer></v-spacer>
            <v-icon color="green" @click="editingName = true"
              >mdi-pencil</v-icon
            >
          </template>

          <template v-else>
            <v-row align="center" no-gutters>
              <v-col cols="10">
                <v-text-field
                  :maxlength="35"
                  v-model="user_details.user_name"
                  outlined
                  dense
                  color="green"
                  ref="userNameTextField"
                  @input="handleFieldChange"
                  class="mt-6"
                >
                  <template v-slot:append>
                    <v-icon color="red" @click="cancelEditing"
                      >mdi-close</v-icon
                    >
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-icon
                  color="green"
                  class="ml-4"
                  @click="updateUserDetailsMethod"
                >
                  mdi-content-save
                </v-icon>
              </v-col>
            </v-row>
          </template>
        </v-card-title>

        <v-divider color="black"></v-divider>

        <v-list dense>
          <v-list-item>
            <v-list-item-content>Email Id:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{ user_details.user_email_id }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>Contact Number:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{ user_details.contact_number }}
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>User Type:</v-list-item-content>
            <v-list-item-content class="align-end">
              {{ user_details.user_type }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </template>
  </div>
</template>

<script>
var AWS = require("aws-sdk");
import Overlay from "@/components/Extras/Overlay.vue";
import Snackbar from "@/components/Extras/Snackbar.vue";
import ChangePasswordDialog from "@/components/MyProfile/Dialogs/ChangePasswordDialog.vue";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import {
  UpdateUserDetails,
  UpdateIssuerURLsAndAPIId,
  DeleteSignatureURL,
} from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
export default {
  components: {
    Snackbar,
    Overlay,
    ChangePasswordDialog,
  },
  mixins: [GetCurrentUser],
  data: () => ({
    originalUserName: "", // Store the original value of user_name

    editingName: false,
    changesMade: false,
    overlay: false,
    edit_toggle: false,
    Expand: false,
    user_details: {
      user_name: "",
      user_email_id: "",
      contact_number: "",
      user_type: "",
    },
    loading: false,
    signatureList: [],
    SnackBarComponent: {},
    selectedFile: null,
    imgSrc: null,
    S3loading: false,
    uploadedImage: false,
    loadingConfirmPassword: false,
    dialogChangePassword: false,
  }),
  watch: {
    // edit_toggle(val) {
    //   if (val == false) {
    //     this.validateMethod();
    //   }
    // },
  },
  async mounted() {
    await this.GetCurrentUser();
    this.user_details = {
      user_name: this.$store.getters.get_current_user_details.user_name,
      user_email_id: this.$store.getters.get_current_user_details.user_email_id,
      contact_number: `${this.$store.getters.get_current_user_details.issuer_details.country_code}-${this.$store.getters.get_current_user_details.issuer_details.contact_number}`,
      user_type: this.$store.getters.get_current_user_details.user_type,
    };
    await this.$vuetify.goTo(0, 0);
    this.imgSrc =
      this.$store.getters.get_current_user_details.signature_url != undefined
        ? this.$store.getters.get_current_user_details.signature_url
        : "";
    this.public_key =
      this.$store.getters.get_current_user_details.issuer_details.public_key;
    this.originalUserName = await this.user_details.user_name;
    // console.log("this.originalUserName", this.originalUserName);
  },
  methods: {
    cancelEditing() {
      this.editingName = false;

      document.removeEventListener("click", this.handleOutsideClick);
    },
    handleFieldChange() {
      this.user_details.user_name = this.user_details.user_name.trim();
      if (
        this.user_details.user_name !==
        this.$store.getters.get_current_user_details.user_name
      ) {
        this.changesMade = true;
        // console.log("  this.changesMade", this.changesMade);
      }
    },
    async updateUserDetailsMethod() {
  if (this.changesMade) {
    const oldUserName = this.user_details.user_name.trim();
    if (oldUserName === "") {
      
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        Top: true,
        SnackbarText: "Empty Fields Occurred Cannot Update User",
      };

      this.user_details.user_name = this.$store.getters.get_current_user_details.user_name;
      this.changesMade = false;
   
      return;
    }
    try {
      this.overlay = true;
      var inputParams = {
        user_id: this.$store.getters.get_current_user_details.user_id,
        user_name: oldUserName, // Use the trimmed old username
        user_contact_number: this.$store.getters.get_current_user_details.issuer_details.contact_number,
      };
      this.loading = true;

      let result = await API.graphql(
        graphqlOperation(UpdateUserDetails, {
          input: inputParams,
        })
      );
      var ResultObject = JSON.parse(result.data.UpdateUserDetails);
      if (ResultObject.status == "Success") {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "green",
          Top: true,
          SnackbarText: ResultObject.Status_Message,
        };

        await this.GetCurrentUser();
      }
      this.changesMade = false;
      this.cancelEditing();
      this.editingName = false;

      document.removeEventListener("click", this.handleOutsideClick);
    } catch (error) {
      // console.error(error);
      this.SnackBarComponent = {
        SnackbarVmodel: true,
        SnackbarColor: "red",
        Top: true,
        SnackbarText: error.errors[0].message,
      };
    } finally {
      // Set loading and overlay to false regardless of try/catch outcome
      this.loading = false;
      this.overlay = false;
      
    }
  } else {
    this.SnackBarComponent = {
      SnackbarVmodel: true,
      SnackbarColor: "red",
      Top: true,
      SnackbarText: "No Updates Found",
    };
  }
},

    // async handleSave() {
    //   if (this.user_details.user_name !== this.originalUserName) {
    //     console.log("this.user_details.user_name ", this.user_details.user_name);
    //     console.log("thisoriginalUserName ", this.originalUserName)

    //     await this.updateUserDetailsMethod();
    //     // Call the method if changes are made
    //   } else {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: "Please Make Some Changes",
    //     };
    //   }
    // },
    dialogChangePasswordEmit() {
      this.dialogChangePassword = false;
    },
    async s3DeleteMethod() {
      let Key =
        this.$store.getters.get_current_user_details.signature_url.replace(
          "https://workhistory-mumbai.s3.ap-south-1.amazonaws.com/",
          ""
        );
      var self = this;
      var s3Bucket = new AWS.S3({
        region:
          self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId:
          self.$store.getters.get_current_user_details.s3_credentials
            .access_key,
        secretAccessKey:
          self.$store.getters.get_current_user_details.s3_credentials
            .secret_key,
      });
      var param = {
        Bucket:
          self.$store.getters.get_current_user_details.s3_credentials
            .bucket_name,
        Key: Key,
      };
      await s3Bucket.deleteObject(param, function (err) {
        if (err) {
          self.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            SnackbarText: "Error Occured!! Please try again",
            Top: true,
          };
        } else {
          self.deleteSignature();
        }
      });
    },
    async deleteSignature() {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(DeleteSignatureURL, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.DeleteSignatureURL);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.GetCurrentUser();
        }
        this.loading = false;
        this.modify_boolean = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    async updateCurrentBusinessLogo(s3_URL) {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          update_type: "SIGNATURE_URL",
          update_url: s3_URL,
        };
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(UpdateIssuerURLsAndAPIId, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateIssuerURLsAndAPIId);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.GetCurrentUser();
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    async uploadImageToS3() {
      this.S3loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region:
          self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId:
          self.$store.getters.get_current_user_details.s3_credentials
            .access_key,
        secretAccessKey:
          self.$store.getters.get_current_user_details.s3_credentials
            .secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket:
            self.$store.getters.get_current_user_details.s3_credentials
              .bucket_name,
          Key: `${
            self.$store.getters.get_current_user_details.issuer_id
          }/SIGNATURE/${new Date().getTime()}_mySignature.png`,
          Body: buf,
          ContentType: self.selectedFile.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            // console.log(err);
            self.S3loading = false;
          } else if (data) {
            let url = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
            self.S3loading = false;
            self.uploadedImage = true;
            self.updateCurrentBusinessLogo(url);
          }
        });
      };
    },
    checkImagePresent() {
      if (
        this.imgSrc == null ||
        this.imgSrc == "" ||
        this.imgSrc == undefined
      ) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      this.selectedFile = e.target.files[0];
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFile);
      fileReader.onload = async function (val) {
        self.imgSrc = val.target.result;
        self.uploadImageToS3();
      };
    },
    // validateMethod() {
    //   if (this.$refs.form.validate()) {
    //     this.updateUserDetailsMethod();
    //   } else {
    //     this.SnackBarComponent = {
    //       SnackbarVmodel: true,
    //       SnackbarColor: "red",
    //       Top: true,
    //       SnackbarText: "Please Enter User Name",
    //     };
    //   }
    // },
  },
};
</script>

<style scoped>
.borderCl .v-input--selection-controls {
  border-color: green !important;
}
</style>
