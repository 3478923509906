<template>
  <div>
    <v-dialog v-model="PDFViewDialog" persistent max-width="1000px">
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>
            PDF Preview <span class="ml-2"> ({{ StoreObj.template_name }})</span>
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="PDFPreviewDialogEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-4">
          <v-card :height="height" class="overflow-y-auto">
            <div class="px-4 py-2" v-html="final_html"></div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    PDFViewDialog: Boolean,
    template_html: String,
    newHTML: String,
    StoreObj: Object,
  },
  data: () => ({
    Toggle: 0,
    final_html: "",
    height: 0,
  }),
  watch: {
    PDFViewDialog(val) {
      if (val == true) {
        if (this.newHTML != "" && this.newHTML != undefined && this.newHTML != null) {
          this.final_html = this.newHTML;
        } else {
          this.final_html = this.template_html
            .replaceAll(
              "${BUSINESS LOGO}",
              this.$store.getters.get_current_user_details.issuer_details.account_logo_url != undefined
                ? `<img style="width:150px" src="${this.$store.getters.get_current_user_details.issuer_details.account_logo_url}"/>`
                : "Please Upload Your Business Logo"
            )
            .replaceAll("ISSUER NAME", this.$store.getters.get_current_user_details.issuer_details.issuer_name);
        }
      }
    },
  },
  mounted() {
    this.height = window.innerHeight - 200;
  },
  methods: {
    PDFPreviewDialogEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
