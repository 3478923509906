<template>
  <div>
    <DeleteReasonsDialog
      :dialogDeleteReasons="dialogDeleteReasons"
      :StoreObj="StoreObj"
      @clicked="dialogDeleteReasonsEmit"
    />
    <DeleteNatureOfSeparationDialog
      :dialogDeleteNatureOfSeperation="dialogDeleteNatureOfSeperation"
      :StoreObj="StoreObj"
      @clicked="dialogDeleteNatureOfSeperationEmit"
    />
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <Overlay :overlay="overlay" />

    <!-- <v-card class="mt-4 mb-4 ml-10" dense elevation="0" v-show="isSmallScreen">
      <h3>
        <span style="font-size: larger">Settings</span>
        <span class="ml-1" style="font-size: smaller">/Organization Settings</span>
      </h3>
    </v-card> -->

    <v-card
      class="ma-7"
      elevation="0"
      v-if="
        $vuetify.breakpoint.md ||
        $vuetify.breakpoint.lg ||
        $vuetify.breakpoint.xl
      "
      fixed
    >
      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <div style="display: flex; flex-wrap: wrap">
            <div class="mt-1">
              <h2 class="ml-6">Organization Settings:</h2>
            </div>
          </div>
        </v-col>
        <!-- <h2>
                <span style="font-size: larger">Settings</span>
                <span class="ml-1 " style="font-size: smaller"
                  ><v-icon color="black">mdi-slash-forward</v-icon>Organization
                  Settings:</span
                >
              </h2> -->
      </v-row>
    </v-card>

    <v-card dense elevation="2" outlined class="ma-7" v-show="isbigScreen">
      <h4 class="ml-7 mt-2">Reasons For Leaving</h4>
      <v-row>
        <v-col cols="8" md="4" lg="4" xs="8" sm="8">
          
          <v-text-field
            v-model="RL_reason_of_leaving"
            class="mt-4 ml-7"
            label="Reason Of Leaving "
            dense
            outlined
            color="green"
            :maxlength="200"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4" md="4" lg="4" sm="4" xs="4">
          <v-btn
            color="green"
            class="mt-4 mb-4"
            dark
            depressed
            @click="AddReasons"
            ><v-icon>mdi-plus</v-icon> ADD</v-btn
          >
        </v-col>
      </v-row>

      <v-chip
        v-for="(reason, index) in reasonsOfLeavingList"
        :key="index"
        class="ml-7 mb-7"
        close
        outlined
        dense
        @click:close="CheckItem(reason, 'DELETE_REASONS')"
      >
        {{ reason }}

        <template v-slot:reason.actions="{ reason }"> </template>
      </v-chip>
    </v-card>

    <v-card dense elevation="2" outlined class="ma-7" v-show="isbigScreen">
      <h4 class="ml-7 mt-2">Nature Of Separation</h4>
      <v-row>
        <v-col cols="8" md="4" lg="4" xs="8" sm="8">
          <v-text-field
            v-model="nature_of_separation"
            class="mt-4 ml-7"
            label="Nature Of Separation"
            dense
            outlined
            color="green"
            :maxlength="200"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4" md="4" lg="4" sm="4" xs="4">
          <v-btn
            color="green"
            class="mt-4 mb-4"
            dark
            depressed
            @click="AddNatureofSeparation"
            ><v-icon>mdi-plus</v-icon> ADD</v-btn
          >
        </v-col>
      </v-row>

      <v-chip
        v-for="(Separation, index) in reasonsOfLeavingList2"
        :key="index"
        class="ml-7 mb-7"
        close
        outlined
        dense
        @click:close="CheckItem(Separation, 'DELETE_SEPERATIONS')"
      >
        {{ Separation }}
      </v-chip>
    </v-card>

    <div style="text-align: center" class="ma-2" v-show="isSmallScreen">
      <h2>Organization Settings</h2>
    </div>

    <v-card dense elevation="2" outlined class="ma-4" v-show="isSmallScreen">
      <h4 class="ml-3 mt-2">Reasons For Leaving</h4>
      <v-row>
        <v-col cols="8" md="4" lg="4" xs="8" sm="8">
          <v-text-field
            color="green"
            v-model="RL_reason_of_leaving"
            class="mt-4 ml-3"
            label="Reasons"
            dense
            outlined
            :maxlength="200"
          >
          </v-text-field>
        </v-col>
        <v-col cols="4" md="4" lg="4" sm="4" xs="4">
          <v-btn
            color="green"
            class="mt-4 mb-4"
            dark
            depressed
            @click="AddReasons"
            ><v-icon>mdi-plus</v-icon> ADD</v-btn
          >
        </v-col>
      </v-row>

      <v-chip
        v-for="(reason, index) in reasonsOfLeavingList"
        :key="index"
        class="ma-3"
        close
        outlined
        dense
        @click:close="CheckItem(reason, 'DELETE_REASONS')"
      >
        {{ reason }}

        <!-- <template v-slot:reason.actions="{ reason }"> </template> -->
      </v-chip>
    </v-card>

    <v-card dense elevation="2" class="ma-4" outlined v-show="isSmallScreen">
      <h4 class="ml-3 mt-2">Nature Of Separation</h4>
      <v-row>
        <v-col cols="8" md="4" lg="4" xs="8" sm="8">
          <v-text-field
            class="mt-4 ml-3"
            dense
            outlined
            v-model="nature_of_separation"
            label="Nature Of Seperation"
            :maxlength="200"
            color="green"
          >
          </v-text-field
        ></v-col>
        <v-col cols="4" md="4" lg="4" sm="4" xs="4">
          <v-btn
            color="green"
            class="mt-4 mb-4"
            dark
            depressed
            @click="AddNatureofSeparation"
            ><v-icon>mdi-plus</v-icon> ADD</v-btn
          >
        </v-col>
      </v-row>

      <v-chip
        v-for="(Separation, index) in reasonsOfLeavingList2"
        :key="index"
        :value="Separation"
        outlined
        dense
        close
        class="ma-2"
        @click:close="CheckItem(Separation, 'DELETE_SEPERATIONS')"
      >
        {{ Separation }}
        <!-- <template v-slot:Separation.actions="{ Separation }"></template> -->
      </v-chip>
    </v-card>
    <!-- <v-toolbar dense elevation="0">
        <v-toolbar-title> </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="green" class="ma-4" dark depressed @click="saveData2"
          ><v-icon left>mdi-content-save</v-icon> Save</v-btn
        >
      </v-toolbar> -->
  </div>
</template>

<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import DeleteNatureOfSeparationDialog from "@/components/Dashboard/Dialogs/DeleteNatureOfSeparation.vue";
import DeleteReasonsDialog from "@/components/Dashboard/Dialogs/DeleteReasonsDialog.vue";
import Overlay from "@/components/Extras/Overlay.vue";
import { RL_listReasonOfLeavingDropdown } from "@/mixins/GetRL_listReasonOfLeaving.js";
import { ListIssuerNatureOfSeparationDropdown } from "@/mixins/GetAllListIssuerNatureOfSeparation.js";
import {
  RL_addReasonOfLeaving,
  UpdateIssuerNatureOfSeparation,
} from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";

export default {
  data() {
    return {
      StoreObj: "",
      showPlusIcon: true,
      plusClicked: false,
      dialogDeleteReasons: false,
      dialogDeleteNatureOfSeperation: false,
      overlay: false,
      SnackBarComponent: {},
      ListReasonsApi: [],
      ListIssuerNatureOfSeparationApi: [],
      nature_of_separation: "",
      RL_reason_of_leaving: "",

      reasonsOfLeavingList: [], // Define reasonsOfLeavingList array
      reasonsOfLeavingList2: [],
    };
  },
  components: {
    Snackbar,
    Overlay,
    DeleteReasonsDialog,
    DeleteNatureOfSeparationDialog,
  },
  mixins: [
    RL_listReasonOfLeavingDropdown,
    ListIssuerNatureOfSeparationDropdown,
  ],
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    isbigScreen() {
      return (
        this.$vuetify.breakpoint.md ||
        this.$vuetify.breakpoint.lg ||
        this.$vuetify.breakpoint.xl
      );
    },
  },

  async mounted() {
    await this.RL_listReasonOfLeavingMethod();
    await this.ListIssuerNatureOfSeparationMethod();
    await this.$vuetify.goTo(0, 0);
  },
  methods: {
    CheckItem(reason, action) {
      this.StoreObj = reason;
      switch (action) {
        case "DELETE_REASONS": {
          this.dialogDeleteReasons = true;
          // console.log("here i am");
          break;
        }
        case "DELETE_SEPERATIONS": {
          this.dialogDeleteNatureOfSeperation = true;
          // console.log("here i am in separation");
          break;
        }
      }
    },
    async dialogDeleteReasonsEmit(Toggle) {
      if (Toggle == 2) {
        const index = this.reasonsOfLeavingList.indexOf(this.StoreObj);
        if (index !== -1) {
          this.reasonsOfLeavingList.splice(index, 1);
          this.RL_reason_of_leaving = "";
          try {
            this.overlay = true;
            const result = await API.graphql(
              graphqlOperation(RL_addReasonOfLeaving, {
                input: {
                  issuer_id:
                    this.$store.getters.get_current_user_details.issuer_id,
                  RL_reason_of_leaving: this.reasonsOfLeavingList,
                },
              })
            );

            // console.log("RL_addReasonOfLeaving result", result);
            const ResultObject = JSON.parse(
              result.data.RL_addReasonOfLeaving
            ).status;
            // console.log("ResultObject", ResultObject);

            if (ResultObject === "Success") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                Top: true,
                SnackbarText: "Reason for Leaving Updated Successfully",
              };

              this.RL_reason_of_leaving = "";
            }
          } catch (error) {
            // console.log("RL_addReasonOfLeaving error", error);
            // console.log("in catch");
            this.hasChanges1 = false;
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: error.errors[0].message,
            };

            this.RL_reason_of_leaving = "";
          } finally {
            this.overlay = false;
            this.RL_reason_of_leaving = "";
            // console.log("in finalky");
          }
        }
      }
      this.reasonsOfLeavingList = "";
      this.dialogDeleteReasons = false;
      await this.RL_listReasonOfLeavingMethod();
    },

    async dialogDeleteNatureOfSeperationEmit(Toggle) {
      if (Toggle == 2) {
        const index = this.reasonsOfLeavingList2.indexOf(this.StoreObj);
        if (index !== -1) {
          this.reasonsOfLeavingList2.splice(index, 1);
          this.nature_of_separation = "";
          try {
            this.overlay = true;
            const result = await API.graphql(
              graphqlOperation(UpdateIssuerNatureOfSeparation, {
                input: {
                  issuer_id:
                    this.$store.getters.get_current_user_details.issuer_id,
                  nature_of_separation: this.reasonsOfLeavingList2,
                },
              })
            );

            // console.log("UpdateIssuerNatureOfSeparation result", result);
            const ResultObject = JSON.parse(
              result.data.UpdateIssuerNatureOfSeparation
            ).status;
            // console.log(
            //   "UpdateIssuerNatureOfSeparation  ResultObject",
            //   ResultObject
            // );

            if (ResultObject === "Success") {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                Top: true,
                SnackbarText: "NatureOfSeparation Updated Successfully",
              };

              this.nature_of_separation = "";
            }
          } catch (error) {
            // console.log("RL_addReasonOfLeaving error", error);
            // console.log("in catch");

            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: error.errors[0].message,
            };

            this.nature_of_separation = "";
            this.dialogDeleteNatureOfSeperationEmit = false;
          } finally {
            this.overlay = false;
            this.nature_of_separation = "";
            // console.log("in finalky");
          }
        }
      }
      this.nature_of_separation = "";
      this.dialogDeleteNatureOfSeperation = false;
      await this.ListIssuerNatureOfSeparationMethod();
    },

    async AddReasons() {
      if (
        this.RL_reason_of_leaving !== "" &&
        this.RL_reason_of_leaving.trim() !== ""
      ) {
        this.reasonsOfLeavingList.push(this.RL_reason_of_leaving);
        this.RL_reason_of_leaving = "";
        try {
          this.overlay = true;
          const result = await API.graphql(
            graphqlOperation(RL_addReasonOfLeaving, {
              input: {
                issuer_id:
                  this.$store.getters.get_current_user_details.issuer_id,
                RL_reason_of_leaving: this.reasonsOfLeavingList,
              },
            })
          );
          // console.log("RL_addReasonOfLeaving result", result);
          const ResultObject = JSON.parse(
            result.data.RL_addReasonOfLeaving
          ).status;

          if (ResultObject === "Success") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: "Reasons for Leaving Updated Successfully",
            };
            await this.RL_listReasonOfLeavingMethod();
          }
        } catch (error) {
          // console.log("RL_addReasonOfLeaving error", error);
          this.hasChanges1 = false;
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
        } finally {
          this.overlay = false;
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter  Reasons",
        };
      }
    },

    async AddNatureofSeparation() {
      if (
        this.nature_of_separation !== "" &&
        this.nature_of_separation.trim() !== ""
      ) {
        this.reasonsOfLeavingList2.push(this.nature_of_separation);
        this.nature_of_separation = "";
        try {
          this.overlay = true;
          const result = await API.graphql(
            graphqlOperation(UpdateIssuerNatureOfSeparation, {
              input: {
                issuer_id:
                  this.$store.getters.get_current_user_details.issuer_id,
                nature_of_separation: this.reasonsOfLeavingList2,
              },
            })
          );
          // console.log("UpdateIssuerNatureOfSeparation Result", result);

          const ResultObject = JSON.parse(
            result.data.UpdateIssuerNatureOfSeparation
          ).status;
          this.hasChanges2 = false;
          if (ResultObject == "Success") {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: "NatureOfSeparation Updated Successfully",
            };
            this.overlay = false;
            await this.ListIssuerNatureOfSeparationMethod();
            this.hasChanges2 = false;
          }
        } catch (error) {
          // console.log("UpdateIssuerNatureOfSeparation error", error);
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
          this.overlay = false;
          this.hasChanges2 = false;
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Enter Nature Of Seperation",
        };
      }
    },
  },
};
</script>

<style scoped>
/* Your scoped styles here */
</style>

<!-- 
// async mounted() {
  //   await this.RL_listReasonOfLeavingMethod();
  //   await this.ListIssuerNatureOfSeparationMethod();
  // }, -->
<!-- <v-row>
  <v-col cols="6">
    <v-select
      :items="ListReasonsApi"
      v-model="Reasons.RL_reason_of_leaving"
      label="Reason For Leaving"
      outlined
      dense
      class="FontSize field_height field_label_size"
    ></v-select>
    <v-select
      :items="ListIssuerNatureOfSeparationApi"
      v-model="Nature_of_Separation.nature_of_separation"
      label="Reason For Leaving"
      outlined
      dense
      class="ma-5"
    ></v-select>
  </v-col>
</v-row> -->

<!-- <v-chip-group
            color="white"
            v-model="Reasons.RL_reason_of_leaving"
            class="ma-5"
            multiple
          >
            <v-chip
              v-for="(reason, index) in Reasons"
              :key="index"
              :value="reason"
              outlined
              dense
            >
              {{ reason }}
            </v-chip>
          </v-chip-group> -->

<!-- <v-chip-group
          color="white"
          v-model="Nature_of_Separation.nature_of_separation"
          class="ma-5"
          multiple
        >
          <v-chip
            v-for="(Separation, index) in Nature_of_Separation"
            :key="index"
            :value="Separation"
            outlined
            dense
          >
            {{ Separation }}
          </v-chip>
        </v-chip-group> -->

<!-- // valdiateAddiconSepration() {
          //   this.plusClicked = true;
          //   if (this.nature_of_separation.trim() === "") {
          //     this.SnackBarComponent = {
          //       SnackbarVmodel: true,
          //       SnackbarColor: "red",
          //       Top: true,
          //       SnackbarText: "Please Add Values",
          //     };
          //   } else {
          //     this.addNatureofSeperation();
          //   }
          // },
      
          // addReasonOfLeaving() {
          //   if (this.RL_reason_of_leaving !== "") {
          //     this.reasonsOfLeavingList.push(this.RL_reason_of_leaving);
          //     this.RL_reason_of_leaving = ""; // Reset input field
          //   }
          // },
      
          // addNatureofSeperation() {
          //   if (this.nature_of_separation !== "") {
          //     this.reasonsOfLeavingList2.push(this.nature_of_separation);
          //     this.nature_of_separation = "";
      
          //   }
          // },
      
          // deleteNatureofSeperation(Separation) {
          //   const index = this.reasonsOfLeavingList2.indexOf(Separation);
          //   if (index !== -1) {
          //     this.reasonsOfLeavingList2.splice(index, 1);
          //     this.hasChanges2 = true;
          //   }
          // }, -->
