<template>
  <v-dialog v-model="dialogTemplatePreview" persistent max-width="1100px">
    <v-card>
      <v-toolbar dark color="primary" dense class="elevation-0">
        Template Preview
        <span class="ml-2" v-if="TemplateStoreObj.action == 'EDIT'">({{ TemplateStoreObj.template_name }})</span>
        <v-spacer></v-spacer>
        <v-btn dark icon @click="templatePreviewDialogEmit()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="text-center">
        <v-card :height="height" class="overflow-y-auto mt-4">
          <div v-html="template_html"></div>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialogTemplatePreview: Boolean,
    TemplateStoreObj: Object,
    template_html: String,
  },
  data: () => ({
    height: 0,
  }),
  mounted() {
    this.height = window.innerHeight - 200;
  },
  methods: {
    templatePreviewDialogEmit() {
      this.$emit("clicked");
    },
  },
};
</script>
<style>
.ql-align-center {
  text-align: center;
}
.ql-align-left {
  text-align: left;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
.main_div {
  width: 700px;
  position: relative;
  height: 1060px;
}
.sub_div {
  position: absolute;
  bottom: 0px;
}
.margin-top {
  margin-top: 150px;
}
</style>
