<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogDeletePDFTemplate" persistent max-width="400px">
      <v-card class="overflow-y-auto">
        <v-toolbar dark color="primary" dense class="elevation-0">
          <div>PDF Preview</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogDeletePDFTemplateEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="text-center">
          <v-icon class="mt-4" size="50px" color="red">mdi-delete</v-icon>
          <div>Are you sure you want to delete {{ StoreObj.template_name }} ?</div>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" outlined @click="dialogDeletePDFTemplateEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small class="borderRadius fontStyle text-capitalize" :loading="loading" color="secondary" @click="deletePDFTemplatesMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import { API, graphqlOperation } from "aws-amplify";
import { DeletePDFTemplate } from "@/graphql/mutations.js";
export default {
  props: {
    dialogDeletePDFTemplate: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
  }),
  methods: {
    async deletePDFTemplatesMethod() {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          template_id: this.StoreObj.template_id,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(DeletePDFTemplate, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.DeletePDFTemplate);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogDeletePDFTemplateEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
      }
    },
    dialogDeletePDFTemplateEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
