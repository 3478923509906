<template>
  <div>
    <v-dialog    max-width="500px" :value="DeleteLogoDialog" dense persistent>
      <v-card flat>
        <v-toolbar dense flat dark class="gradient-bar">
          <v-toolbar-title>Delete </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon
            color="white"
            @click="DeleteLogoDialogEmit(1)"
            light
            >mdi-close</v-icon
          >
        </v-toolbar>

        <v-card-text class="mt-5">
          <div class="text-center font-weight-bold">
            Are you sure you want to Delete this Logo
           
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            dark
            depressed
            class="text-capitalize custom-btn mb-3 mr-1"
            @click="DeleteLogoDialogEmit(2)"
          >
            <v-icon left>mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn
            dark
            depressed
            class="text-capitalize custom-btn mb-3 mr-2"
            color="green"
            @click="DeleteLogoDialogEmit(3)"
            ><v-icon left>mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
     




    </v-dialog>
  </div>
</template>
<script>
export default {
     props: {
          DeleteLogoDialog: Boolean,
  },
  data() {
    return {

    };
  },
  methods:{
 

     DeleteLogoDialogEmit(Toggle) {
      if (Toggle) this.$emit("clicked", Toggle);
    },
  }
};
</script>
