import { ListCountries } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllSupportedCountry = {
  data() {
    return {
      overlay: false,
      GetAllSupportedCountryList: [],
    };
  },
  methods: {
    async GetAllSupportedCountryMethod(country_status) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(ListCountries, {
            input: {
              country_status: country_status,
            },
          })
        );
        this.GetAllSupportedCountryList = JSON.parse(result.data.ListCountries).data.items;
        // console.warn("GetAllSupportedCountryList", this.GetAllSupportedCountryList);
        this.identificationTypeItems = this.GetAllSupportedCountryList.filter(
          (item) => item.country_name == this.business_info.country
        )[0].identification_types;
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
      }
    },
  },
};
