<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" v-if="renderComp" />
    <v-dialog v-model="dialogAddIssuer" persistent max-width="400px">
      <v-card>
        <v-toolbar dark dense class="elevation-0 appbar">
          <div class="white--text">Invite Member</div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogCreateUsersEmit((Toggle = 1))"
            ><v-icon color="">mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="text-center mt-5 pb-0">
          <v-form ref="form">
            <v-text-field
              color="green"
              label="Name *"
              :rules="[(v) => !!v || 'Required']"
              v-model="add_issuer.user_name"
              dense
              outlined
              :maxlength="35"
              class="FontSize field_height field_label_size"
            ></v-text-field>

            <v-text-field
              color="green"
              label="Email ID *"
              :rules="[
                (v) => !!v || 'Required',
                (v) => /.+@.+/.test(v) || 'Invalid',
              ]"
              v-model="add_issuer.user_email_id"
              dense
              outlined
              class="FontSize field_height field_label_size"
            ></v-text-field>
            <!-- <v-select
            color="green"
              label="User Type *"
              :items="userTypeItems"
              :rules="[(v) => !!v || 'Required']"
              v-model="add_issuer.user_type"
              dense
              outlined
              class="FontSize field_height field_label_size"></v-select> -->
            <!-- <v-text-field
              outlined
              dense
              class="field_height field_label_size FontSize"
              label="Enter Temporary Password *"
              :rules="[
                (v) => !!v || 'Required',
                (v) => (v && v.length > 5) || 'Invalid',
                (v) => (v && v.length < 21) || 'Invalid',
              ]"
              v-model="add_issuer.temporary_password"
            ></v-text-field> -->
          </v-form>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="cancelButton white--text text-capitalize"
            style="font-size: 13px"
            outlined
            @click="dialogCreateUsersEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn
            color="green"
            class="internalButtons white--text mr-2 text-capitalize"
            style="font-size: 13px"
            @click="validateMethod()"
            :loading="loading"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { RLCreateUser } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogAddIssuer: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    add_issuer: {
      user_name: "",
      user_email_id: "",
      user_type: "",
      temporary_password: "",
    },
    SnackBarComponent: {},
    loading: false,
    userTypeItems: ["MEMBER"],
    renderComp: true,
  }),
  methods: {
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.addUserMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisks (*) are mandatory",
        };
      }
    },
    async addUserMethod() {
      try {
        var inputParams = {
          user_name: this.add_issuer.user_name,
          user_email_id: this.add_issuer.user_email_id,
          user_type: "MEMBER",
          creator_email_id:
            this.$store.getters.get_current_user_details.user_email_id,
          // temporary_password: this.add_issuer.temporary_password,
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(RLCreateUser, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.RLCreateUser);
        if (ResultObject.status == "Success") {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: ResultObject.Status_Message,
            };
            this.renderComp = true;
          });
          this.dialogCreateUsersEmit((this.Toggle = 2));
          this.loading = false;
        } else if (ResultObject.status == "Error") {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "User Already Exists!!!",
            };
            this.renderComp = true;
          });
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error,
          };
          this.renderComp = true;
        });
      }
    },
    dialogCreateUsersEmit(Toggle) {
      this.$refs.form.reset();
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
