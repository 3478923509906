<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <IssueTemplatizedCredentialsDialog
      :StoreObj="StoreObj"
      :formfields="formfields"
      :dialogIssueTemplatizedCredential="dialogIssueTemplatizedCredential"
      @clicked="dialogIssueTemplatizedCredentialEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <div class="largeFontSizeNew">{{ StoreObj.template_name }}</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-btn v-if="buttonToggle == true" small class="borderRadius mr-2" color="primary" @click="dialogIssueTemplatizedCredential = true"
              ><v-icon small class="mr-1">mdi-bookmark-check</v-icon>Issue</v-btn
            >
            <v-btn small @click="FillPDFFieldsEmit((Toggle = 1))" color="primary" class="borderRadius"><v-icon small class="mr-1">mdi-chevron-double-left</v-icon>Back</v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="pt-0">
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="3">
            <v-form ref="form">
              <v-row no-gutters>
                <v-col cols="12" xs="12" sm="12" md="12" v-for="(field, idx) in StoreObj.template_fields.filter((item) => item != 'BUSINESS_LOGO')" :key="idx">
                  <v-text-field
                    v-if="field != 'ACTIVE IMAGE'"
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Required']"
                    class="FontSize field_height field_label_size"
                    :label="field"
                    v-model="formfields[field]"
                  ></v-text-field>
                  <v-file-input v-else dense outlined class="FontSize field_label_size" @change="callS3UploadMethod" :label="field" v-model="formfields[field]"></v-file-input>
                </v-col>
              </v-row>
            </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="buttonToggle == false" small class="borderRadius" color="primary" @click="validateMethod()">Proceed</v-btn>
            </v-card-actions>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="9">
            <v-card>
              <div class="px-4 py-2" v-html="this.template_html"></div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
var AWS = require("aws-sdk");
import IssueTemplatizedCredentialsDialog from "@/components/Dashboard/Dialogs/IssueTemplatizedCredentialsDialog";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    StoreObj: Object,
  },
  components: {
    Snackbar,
    IssueTemplatizedCredentialsDialog,
  },
  data: () => ({
    Toggle: 0,
    formfields: {},
    template_html: "",
    SnackBarComponent: {},
    count: 0,
    buttonToggle: false,
    dialogIssueTemplatizedCredential: false,
  }),
  mounted() {
    this.template_html = this.StoreObj.template_html = this.StoreObj.template_html.replaceAll(
      "${BUSINESS_LOGO}",
      this.$store.getters.get_current_user_details.issuer_details.account_logo_url != undefined
        ? `<img style="width:150px" src="${this.$store.getters.get_current_user_details.issuer_details.account_logo_url}"/>`
        : "Please Upload Your Business Logo"
    );
    console.log("Check Template", this.StoreObj);
  },
  methods: {
    callS3UploadMethod(field) {
      console.log("Getting Field", field);
      console.log("Check Fields", this.formfields);
      var self = this;
      var s3Bucket = new AWS.S3({
        region: self.$store.getters.get_current_user_details.s3_credentials.region,
        accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
        secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(field);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
          Key: `${self.$store.getters.get_current_user_details.issuer_id}/ACTIVE_IMAGE/${new Date().getTime()}_mySignature.png`,
          Body: buf,
          ContentType: field.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
          } else if (data) {
            let url = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
            console.log("Check The URL", url);
            self.formfields["ACTIVE IMAGE"] = `<img style="width:150px" src="${url}"/>`;
            console.log("Check The Form Fields", self.formfields);
          }
        });
      };
    },
    dialogIssueTemplatizedCredentialEmit(Toggle, ResultObject) {
      this.dialogIssueTemplatizedCredential = false;
      if (Toggle == 2) {
        this.FillPDFFieldsEmit(Toggle, ResultObject);
      }
    },
    enterFieldsInPreview() {
      let newHTML = this.template_html.replaceAll("${" + `${this.StoreObj.template_fields[this.count]}` + "}", this.formfields[this.StoreObj.template_fields[this.count]]);
      if (this.count <= this.StoreObj.template_fields.length - 1) {
        this.template_html = newHTML;
        this.count += 1;
        this.$forceUpdate();
        this.enterFieldsInPreview();
      } else {
        this.count = 0;
      }
      this.buttonToggle = true;
    },
    validateMethod() {
      if (this.$refs.form.validate()) {
        this.enterFieldsInPreview();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please fill up all the fields",
        };
      }
    },
    createFields() {
      this.formfields = {};
      for (let i = 0; i < this.StoreObj.template_fields.length; i++) {
        this.formfields[this.StoreObj.template_fields[i]] = "";
      }
      this.$forceUpdate();
    },
    FillPDFFieldsEmit(Toggle, ResultObject) {
      this.buttonToggle = false;
      this.$emit("clicked", Toggle, ResultObject);
    },
  },
};
</script>
