<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" v-if="renderComp" />

    <v-dialog v-model="EmailPhoneGiven" persistent dense width="600px">
      <v-card class="emailphonecard pt-5" height="150px">
        <v-card-text class="font-weight-bold">
          You have not given Email Address or Phone number
        </v-card-text>
        <v-card-actions class="mt-6">
          <v-spacer></v-spacer>
          <!-- <v-icon small class="mr-1">mdi-step-backward</v-icon> -->
          <v-btn
            @click="back()"
            dark
            depressed
            :loading="loading"
            class="text-capitalize custom-btn"
          >
            Back</v-btn
          >
          <v-btn
            @click="EmailPhoneGiven = false"
            dark
            depressed
            color="green"
            :loading="loading"
            class="text-capitalize custom-btn"
            >Proceed <v-icon small class="ml-1">mdi-fast-forward</v-icon></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogIssueCredentials"
      persistent
      :max-width="
        pdfview == true || actionEnum == 'WITHOUTDOCUMENT' ? '1100px' : '600px'
      "
    >
      <v-card
        :height="pdfview == true ? windowHeight : ''"
        class="overflow-y-auto"
      >
        <v-toolbar dense class="elevation-0 appbar">
          <div class="white--text">
            {{
              currentview === "issuedocument"
                ? "Issue Relieving Letter"
                : "Optional Additional Details (Not Published to Blockchain)"
            }}
          </div>
          <v-spacer></v-spacer>
          <v-btn icon @click="IssueCredentialsEmit(1)"
            ><v-icon color="white">mdi-close</v-icon></v-btn
          >
        </v-toolbar>

        <v-card-text v-if="currentview == 'issuedocument'">
          <v-row no-gutters>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              :md="pdfview == true || actionEnum == 'WITHOUTDOCUMENT' ? 5 : 12"
              class="mt-4"
            >
              <!-- <div class="font-weight-bold mb-3" :class="preview_mode == false ? 'mt-n3' : ''">
                Issued To:
              </div> -->
              <v-form ref="formIssueCredential" class="py-0">
                <v-row>
                  <v-col>
                    <!--  -->
                    <v-text-field
                      :counter="12"
                      color="green"
                      label="Employee ID*"
                      dense
                      outlined
                      v-model="emp_id"
                      :maxlength="12"
                      class="field_height field_label_size FontSize mt-4 ml-1"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) =>
                          (v && v.length <= 12) ||
                          'Employee ID  must be less than 12 characters',
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <!--  -->
                    <v-text-field
                      color="green"
                      :readonly="preview_mode == true"
                      label="Employee Name*"
                      :counter="35"
                      :maxlength="35"
                      v-model="issued_to"
                      dense
                      outlined
                      class="FontSize field_height field_label_size mt-4"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) =>
                          (v && v.length <= 35) ||
                          'Employee Name  must be less than 35 characters',
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- <v-row class="mt-n7">
                  <v-col cols="6" md="2">
                    <v-text-field
                      dense
                      outlined
                      label="Code"
                      v-model="issued_to_country_code"
                      class="FontSize field_height field_label_size mr-1"
                      :rules="[(v) => !!v || 'Required']"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="4" class="pl-0">
                    <v-text-field
                      label="Mobile Number*"
                      :maxlength="10"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => /^\d{10}$/.test(v) || 'Must be 10 digits',
                        (v) =>
                          /^([6789]\d{9})?$/.test(v) || 'Invalid phone number',
                      ]"
                      dense
                      outlined
                      v-model="issued_to_contact_number"
                      class="field_height field_label_size FontSize"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-text-field
                      :readonly="preview_mode == true"
                      label="Employee Email ID*"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => /.+@.+\..+/.test(v) || 'Email must be valid',
                      ]"
                      v-model="issued_to_email_id"
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                    ></v-text-field>
                  </v-col>
                </v-row> -->
                <v-row class="mt-n4">
                  <!-- Worked From -->
                  <v-col cols="6" md="6" class="pt-0">
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!--  -->
                        <v-text-field
                          color="green"
                          v-model="date1"
                          label="Worked From*"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          class="field_height field_label_size FontSize ml-1"
                          :rules="[(v) => !!v || 'Required']"
                        ></v-text-field>
                      </template>
                      <!-- -->
                      <v-date-picker
                        v-model="date1"
                        @input="menu1 = false"
                        no-title
                        scrollable
                        :max="new Date().toISOString().substr(0, 10)"
                        color="green"
                        :rules="[(v) => !!v || 'Required']"
                      ></v-date-picker>
                    </v-menu>
                    <!-- <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :return-value.sync="date1"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date1"
                          label="Worked From*"
                          prepend-icon=""
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          class="field_height field_label_size FontSize"
                          :rules="[(v) => !!v || 'Required']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date1"
                        :max="new Date().toISOString().substr(0, 10)"
                        no-title
                        scrollable
                        :rules="[(v) => !!v || 'Required']"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu1 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu1.save(date1)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu> -->
                  </v-col>
                  <!-- Worked Till -->
                  <v-col cols="6" md="6" class="pt-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!--  -->
                        <v-text-field
                          color="green"
                          v-model="date2"
                          label="Worked Till*"
                          :disabled="date1 == ''"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          class="field_height field_label_size FontSize"
                          :rules="[(v) => !!v || 'Required']"
                        ></v-text-field>
                      </template>
                      <!--  -->
                      <v-date-picker
                        v-model="date2"
                        @input="menu2 = false"
                        no-title
                        :min="date1"
                        scrollable
                        :max="new Date().toISOString().substr(0, 10)"
                        color="green"
                        :rules="[(v) => !!v || 'Required']"
                      ></v-date-picker>
                    </v-menu>
                    <!-- <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="date2"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :disabled="date1 == ''"
                          v-model="date2"
                          label="Worked Till*"
                          prepend-icon=""
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          class="field_height field_label_size FontSize"
                          :rules="[(v) => !!v || 'Required']"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date2"
                        :min="date1"
                        no-title
                        scrollable
                        :max="new Date().toISOString().substr(0, 10)"
                        :rules="[(v) => !!v || 'Required']"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2.save(date2)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu> -->
                  </v-col>
                </v-row>
                <!--  -->
                <v-text-field
                  color="green"
                  label="Designation*"
                  dense
                  outlined
                  v-model="emp_desg"
                  :counter="35"
                  :maxlength="35"
                  class="field_height field_label_size FontSize ml-1"
                  :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      (v && v.length <= 35) ||
                      'Designation must be less than 35 characters',
                  ]"
                ></v-text-field>
                <!-- :rules="[
                    (v) => !!v || 'Required',
                    (v) =>
                      (v && v.length <= 35) ||
                      'Department must be less than 35 characters',
                  ]" -->
                <v-text-field
                  color="green"
                  label="Department*"
                  dense
                  outlined
                  v-model="emp_dept"
                  :counter="35"
                  :maxlength="35"
                  class="field_height field_label_size FontSize ml-1"
                 
                ></v-text-field>
                <!-- <v-textarea
                  :readonly="preview_mode == true"
                  rows="1"
                  label="Additional Comments (Optional)"
                  v-model="comments"
                  dense
                  outlined
                  class="FontSize field_height field_label_size"
                ></v-textarea> -->
                <v-text-field
                  outlined
                  color="green"
                  dense
                  class="field_height field_label_size FontSize"
                  label="Enter Email Subject"
                  v-model="email_subject"
                  v-show="files != null"
                  :rules="[(v) => !!v || 'Required']"
                ></v-text-field>
                <v-file-input
                  color="green"
                  accept="application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  label="Upload Relieving letter"
                  dense
                  outlined
                  class="FontSize ml-1"
                  v-model="files"
                  prepend-icon=""
                  prepend-inner-icon="mdi-paperclip"
                  persistent-hint
                  :rules="[(v) => !!v || 'Required']"
                ></v-file-input>

                <v-card-subtitle class="mt-n10 caption ml-n3"
                  >Upload pdf, doc or docx. Maximum size 5MB.</v-card-subtitle
                >
                <v-row class="mt-n3">
                  <v-col cols="6" md="2">
                    <v-text-field
                      dense
                      color="green"
                      outlined
                      label="Code"
                      v-model="issued_to_country_code"
                      class="FontSize field_height field_label_size ml-1"
                      :rules="[(v) => !!v || 'Required']"
                      :readonly="true"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" md="4" class="pl-0">
                    <!-- <v-text-field color="green" label="Mobile Number" :maxlength="10" dense outlined
                      v-model="issued_to_contact_number" class="field_height field_label_size FontSize" ></v-text-field> -->

                    <v-text-field
                      color="green"
                      label="Mobile Number"
                      :maxlength="10"
                      dense
                      outlined
                      v-model="issued_to_contact_number"
                      class="field_height field_label_size FontSize"
                    ></v-text-field>
                    <!-- :rules="[validateMobileNumber]" -->
                  </v-col>
                  <v-col cols="12" md="6" lg="6" xl="6" sm="12" xm="12">
                    <v-text-field
                      color="green"
                      :readonly="preview_mode == true"
                      label="Employee Email ID"
                      :maxlength="50"
                      v-model="issued_to_email_id"
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                    ></v-text-field>
                  </v-col>
                  <v-card-subtitle
                    class="caption font-italic mt-n9 font-weight-bold"
                    >(the issued relieving letter will be sent by WhatsApp and
                    email to the above mobile number and email
                    address)</v-card-subtitle
                  >
                </v-row>
              </v-form>
            </v-col>
            <!--  -->
            <v-col cols="12" xs="12" sm="12" md="7">
              <div
                class="BorderPrimary"
                style="margin-top: 30px; margin-left: 30px; margin-right: 10px"
                v-if="pdfview == true"
              >
                <iframe
                  :height="heightPDF"
                  :src="pdfsrc"
                  width="100%"
                  frameborder="0"
                ></iframe>
              </div>
              <div v-if="pdfview == true">
                <v-card-actions class="pt-7 pr-4 pb-0">
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    depressed
                    class="text-capitalize custom-btn"
                    @click="IssueCredentialsEmit(1)"
                  >
                    <v-icon small class="mr-1">mdi-close</v-icon>Cancel
                  </v-btn>

                  <v-btn
                    dark
                    depressed
                    color="green"
                    :loading="loading"
                    class="text-capitalize custom-btn"
                    @click="validateIssueForm()"
                  >
                    Confirm
                    <v-icon right small>mdi-fast-forward</v-icon>
                  </v-btn>
                </v-card-actions>
              </div>
            </v-col>
            <v-col
              cols="12"
              xs="12"
              sm="12"
              md="7"
              class="mt-5 px-3"
              v-if="actionEnum == 'WITHOUTDOCUMENT'"
            >
              <div class="font-weight-bold mb-2">Please Add Content</div>
              <vue-editor
                :disabled="preview_mode == true"
                class="editorHeightFT"
                ref="imageEditor"
                :editorToolbar="customToolbar"
                v-model="payload"
                placeholder="Add Content"
              ></vue-editor>
              <v-card-actions class="pt-16 pb-0 pr-0">
                <v-spacer></v-spacer>

                <v-btn
                  dark
                  depressed
                  class="text-capitalize custom-btn"
                  @click="IssueCredentialsEmit(1)"
                  ><v-icon small left>mdi-close</v-icon>Cancel</v-btn
                >
                <v-btn
                  dark
                  depressed
                  class="text-capitalize custom-btn"
                  color="green"
                  @click="issueCredentialsWithOutDocMethod()"
                  ><v-icon small right>mdi-check</v-icon>Confirm</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="currentview == 'additionalDetails'">
          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1" color="green">
                General Information
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2" color="green">
                Evaluation Related
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 3" step="3" color="green"
                >Specific Remarks</v-stepper-step
              >
            </v-stepper-header>

            <v-stepper-items>
              <!-- <v-card-text class="mb-n6 ml-3 grey--text"
                >OPTIONAL(Additional Details not pushed to Blockchain
                )</v-card-text
              > -->
              <v-stepper-content step="1">
                <v-card
                  class="elevation-0"
                  :height="
                    $vuetify.breakpoint.md ||
                    $vuetify.breakpoint.xl ||
                    $vuetify.breakpoint.lg
                      ? '600px'
                      : '600px'
                  "
                >
                  <v-form ref="additionalData1">
                    <!-- <v-switch
                      v-model="IsAutoPopulateAdress"
                      flat
                      label="Auto-populate Address"
                    ></v-switch> -->

                    <!-- v-show="
                        $store.getters.get_current_user_details.issuer_details
                          .issuer_address_1 != undefined
                      " -->
                    <v-text-field
                      label="Company Address"
                      color="green"
                      v-model="additionalDetails.address"
                      dense
                      outlined
                      disabled
                      filled
                      class="FontSize field_height field_label_size mt-2"
                    ></v-text-field>

                    <v-text-field
                      color="green"
                      label="Last Drawn CTC"
                      v-model="additionalDetails.salary"
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                    ></v-text-field>
                    <v-text-field
                      color="green"
                      label="Additional Remuneration (Allowances/Bonus/Incentives)"
                      v-model="additionalDetails.remuneration"
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                    ></v-text-field>
                    <v-text-field
                      color="green"
                      label="Supervisor's Name"
                      v-model="additionalDetails.supervisordetails"
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                    ></v-text-field>
                    <v-select
                      :items="reasonsOfLeavingList"
                      v-model="additionalDetails.reasonofleaving"
                      label="Reason For Leaving"
                      outlined
                      dense
                      class="FontSize field_height field_label_size"
                      color="green"
                    ></v-select>
                    <v-select
                      :items="natureOfEmployment"
                      v-model="additionalDetails.natureofemployment"
                      label="Nature Of Employment"
                      outlined
                      dense
                      class="FontSize field_height field_label_size"
                    ></v-select>
                    <v-select
                      :items="lastLocationOfWork"
                      v-model="additionalDetails.lastlocationofwork"
                      label="Last Location Of Work"
                      outlined
                      dense
                      class="FontSize field_height field_label_size"
                      color="green"
                    ></v-select>
                    <v-text-field
                      color="green"
                      v-if="
                        additionalDetails.lastlocationofwork ==
                        'WFH(Work From Home)'
                      "
                      v-model="additionalDetails.workfromhome"
                      label="Enter your location of WFH"
                      outlined
                      dense
                      class="FontSize field_height field_label_size"
                    ></v-text-field>
                    <div>
                      <h4
                        class="font-weight-regular caption"
                        style="margin-top: 0; margin-bottom: 0"
                      >
                        Exit Formalities Completed
                      </h4>
                      <v-row>
                        <v-col cols="12" md="6" lg="6" xl="6" xs="12" sm="12">
                          <v-radio-group
                            v-model="additionalDetails.dues"
                            row
                            class="ml-2"
                          >
                            <v-radio
                              color="green"
                              label="Yes"
                              value="Yes"
                            ></v-radio>
                            <v-radio
                              label="No"
                              value="No"
                              color="green"
                            ></v-radio>
                          </v-radio-group>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                          lg="6"
                          xl="6"
                          xs="12"
                          sm="12"
                          dark
                        >
                          <div style="text-align: end">
                            <v-btn
                              dark
                              depressed
                              :loading="loading"
                              class="text-capitalize custom-btn mr-2"
                              @click="currentview = 'issuedocument'"
                            >
                              Back
                            </v-btn>

                            <v-btn
                              dark
                              color="green"
                              depressed
                              :loading="loading"
                              class="text-capitalize custom-btn"
                              @click="e1 = 2"
                            >
                              Continue
                              <v-icon small class="ml-1"
                                >mdi-fast-forward</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <!-- <v-card-actions v-show="isSmallScreen">
                    <v-spacer></v-spacer>

                    <v-btn
                      dark
                      depressed
                      :loading="loading"
                      class="text-capitalize custom-btn mb-10"
                      @click="currentview = 'issuedocument'"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      dark
                      color="green"
                      depressed
                      :loading="loading"
                      class="text-capitalize custom-btn mb-10"
                      @click="e1 = 2"
                    >
                      Continue
                      <v-icon small class="ml-1">mdi-fast-forward</v-icon>
                    </v-btn>
                  </v-card-actions> -->
                  </v-form>
                </v-card>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-card class="mb-12 elevation-0" height="450px">
                  <v-form ref="additionalData2">
                    <v-row>
                      <!-- <v-col cols="6">
                        <v-card-subtitle
                          class="font-weight-regular caption ml-2"
                          >Execution Of Duties and
                          Responsibilities</v-card-subtitle
                        >
                      </v-col> -->
                      <!-- <v-col cols="3">
                        <v-rating
                          v-model="additionalDetails.scoreDuties"
                          background-color="green"
                          color="green"
                          small
                        ></v-rating>
                      </v-col> -->
                      <v-col cols="12">
                        <v-select
                          label="Duties and Responsibilities"
                          v-model="additionalDetails.commentDuties"
                          dense
                          :items="commentOfItems"
                          outlined
                          class="FontSize field_height field_label_size mt-2"
                          color="green"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- <v-col cols="6">
                        <v-card-subtitle
                          class="font-weight-regular caption ml-2"
                          >Attitude and Personal Reputation</v-card-subtitle
                        >
                      </v-col> -->
                      <!-- <v-col cols="3">
                        <v-rating
                          v-model="additionalDetails.scoreAttitude"
                          background-color="green"
                          color="green"
                          small
                        ></v-rating>
                      </v-col> -->
                      <v-col cols="12" class="mt-n5">
                        <v-select
                          label="Attitude and Reputation"
                          v-model="additionalDetails.commentAttitude"
                          dense
                          :items="commentOfItems"
                          outlined
                          class="FontSize field_height field_label_size"
                          color="green"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- <v-col cols="6">
                        <v-card-subtitle
                          class="font-weight-regular caption ml-2"
                          >Performance</v-card-subtitle
                        >
                      </v-col> -->
                      <!-- <v-col cols="3">
                        <v-rating
                          v-model="additionalDetails.scorePerformance"
                          background-color="green"
                          color="green"
                          small
                        ></v-rating>
                      </v-col> -->
                      <v-col cols="12" class="mt-n5">
                        <v-select
                          label="Performance"
                          v-model="additionalDetails.commentPerformance"
                          dense
                          :items="commentOfItems"
                          outlined
                          class="FontSize field_height field_label_size"
                          color="green"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- <v-col cols="6">
                        <v-card-subtitle
                          class="font-weight-regular caption ml-2"
                          >Integrity</v-card-subtitle
                        >
                      </v-col> -->
                      <!-- <v-col cols="3">
                        <v-rating
                          v-model="additionalDetails.scoreIntegrity"
                          background-color="green"
                          color="green"
                          small
                        ></v-rating>
                      </v-col> -->
                      <v-col cols="12" class="mt-n5">
                        <v-select
                          label="Integrity"
                          v-model="additionalDetails.commentIntegrity"
                          dense
                          :items="commentOfItems"
                          outlined
                          class="FontSize field_height field_label_size"
                          color="green"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!-- <v-col cols="6">
                        <v-card-subtitle
                          class="font-weight-regular caption ml-2"
                          >Attendance</v-card-subtitle
                        >
                      </v-col> -->
                      <!-- <v-col cols="3">
                        <v-rating
                          v-model="additionalDetails.scoreAttendence"
                          background-color="green"
                          color="green"
                          small
                        ></v-rating>
                      </v-col> -->
                      <v-col cols="12" class="mt-n5">
                        <v-select
                          label="Attendance"
                          v-model="additionalDetails.commentAttendence"
                          :items="commentOfItems"
                          dense
                          outlined
                          class="FontSize field_height field_label_size"
                          color="green"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-card dense elevation="0">
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        dark
                        depressed
                        :loading="loading"
                        class="text-capitalize custom-btn"
                        @click="e1 = 1"
                      >
                        Back
                      </v-btn>
                      <v-btn
                        dark
                        color="green"
                        depressed
                        :loading="loading"
                        class="text-capitalize custom-btn"
                        @click="e1 = 3"
                      >
                        Continue<v-icon small class="ml-1"
                          >mdi-fast-forward</v-icon
                        >
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card class="mb-12 elevation-0" height="280px">
                  <v-form ref="additionalData3">
                    <h4 class="font-weight-regular caption">
                      Eligible For Re-Hire
                    </h4>
                    <v-radio-group
                      v-model="additionalDetails.eligibleforhire"
                      row
                    >
                      <v-radio color="green" label="Yes" value="Yes"></v-radio>
                      <v-radio color="green" label="No" value="No"></v-radio>
                      <v-radio
                        color="green"
                        label="May Be"
                        value="May be"
                      ></v-radio>
                    </v-radio-group>
                    <!-- <v-text-field
                      v-model="additionalDetails.natureofseperation"
                      label="Nature of Separation"
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                    ></v-text-field> -->
                    <v-select
                      :items="reasonsOfLeavingList2"
                      v-model="additionalDetails.natureofseperation"
                      label="Nature of Separation"
                      outlined
                      dense
                      color="green"
                      class="FontSize field_height field_label_size"
                    ></v-select>
                    <v-text-field
                      color="green"
                      v-model="additionalDetails.complaints"
                      label="Complaints (Example: Sexual Harassment/Misbehaviour/Fraud)"
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                    ></v-text-field>
                    <v-text-field
                      color="green"
                      v-model="additionalDetails.contractviolation"
                      label="Contract Violation"
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                    ></v-text-field>
                    <v-text-field
                      color="green"
                      v-model="additionalDetails.additionalcomments"
                      label="Additional Comments"
                      dense
                      outlined
                      class="FontSize field_height field_label_size"
                    ></v-text-field>
                  </v-form>
                </v-card>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    depressed
                    :loading="loading"
                    class="text-capitalize custom-btn"
                    @click="e1 = 2"
                  >
                    Back
                  </v-btn>
                  <!-- <v-btn
                    class="
                      internalButtons
                      white--text
                      fontStyle
                      text-capitalize
                      ml-2
                    "
                    @click="e1 = 4"
                    ><v-icon>mdi-eye-check-outline</v-icon>Preview</v-btn
                  > -->
                  <v-btn
                    color="green"
                    dark
                    depressed
                    :loading="loading"
                    class="text-capitalize custom-btn"
                    @click="e1 = 4"
                    ><v-icon small class="mr-1">mdi-eye-check-outline</v-icon
                    >Preview</v-btn
                  >
                </v-card-actions>
              </v-stepper-content>

              <!-- NEW TEMPALTE  -->
              <!-- <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12"></v-col> -->
              <v-stepper-content step="4">
                <v-row no-gutters>
                  <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
                    <v-card-text
                      >Employee ID :
                      <span class="additionalDetails">
                        {{ this.emp_id }}
                      </span></v-card-text
                    >
                  </v-col>
                  <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
                    <v-card-text
                      >Employee Name :
                      <span class="additionalDetails">
                        {{ this.issued_to }}
                      </span></v-card-text
                    >
                  </v-col>

                  <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
                    <v-card-text
                      >Worked From :
                      <span class="additionalDetails">
                        {{ this.date1 }}
                      </span></v-card-text
                    >
                  </v-col>

                  <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12">
                    <v-card-text
                      >Worked Till :
                      <span class="additionalDetails">
                        {{ this.date2 }}
                      </span></v-card-text
                    >
                  </v-col>

                  <v-col cols="12" md="12" lg="12" xl="12" sm="12" xs="12">
                    <v-card-text
                      >Designation :
                      <span class="additionalDetails">
                        {{ this.emp_desg }}
                      </span></v-card-text
                    >
                  </v-col>
                  <v-col cols="12" md="12" lg="12" xl="12" sm="12" xs="12">
                    <v-card-text
                      >Department :
                      <span class="additionalDetails">
                        {{ this.emp_dept }}
                      </span></v-card-text
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="issued_to_contact_number != ''"
                  >
                    <v-card-text
                      >Phone Number :
                      <span class="additionalDetails">
                        {{ this.issued_to_contact_number }}
                      </span></v-card-text
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="issued_to_email_id != ''"
                  >
                    <v-card-text
                      >Email Address :
                      <span class="additionalDetails">
                        {{ this.issued_to_email_id }}
                      </span></v-card-text
                    >
                  </v-col>

                  <!-- <v-col cols="12" md="12" lg="12" xl="12" sm="12" xs="12">
                    <h3 class="ml-3">Addition-Details:</h3>
                  </v-col> -->

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.salary != '' &&
                      additionalDetails.salary != null
                    "
                  >
                    <v-card-text
                      >Salary :
                      <span class="additionalDetails">
                        {{ additionalDetails.salary }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.remuneration != '' &&
                      additionalDetails.remuneration != null
                    "
                  >
                    <v-card-text
                      >Additional Remuneration :
                      <span class="additionalDetails">
                        {{ additionalDetails.remuneration }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.supervisordetails != '' &&
                      additionalDetails.supervisordetails != null
                    "
                  >
                    <v-card-text
                      >Supervisor's Name:
                      <span class="additionalDetails">
                        {{ additionalDetails.supervisordetails }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.reasonofleaving != '' &&
                      additionalDetails.reasonofleaving != null
                    "
                  >
                    <v-card-text
                      >Reason Of Leaving :
                      <span class="additionalDetails">
                        {{ additionalDetails.reasonofleaving }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.natureofemployment != '' &&
                      additionalDetails.natureofemployment != null
                    "
                  >
                    <v-card-text
                      >Nature of Employment :
                      <span class="additionalDetails">
                        {{ additionalDetails.natureofemployment }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.lastlocationofwork != '' &&
                      additionalDetails.lastlocationofwork != null
                    "
                  >
                    <v-card-text
                      >Last Location of Work :
                      <span class="additionalDetails">
                        {{ additionalDetails.lastlocationofwork }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.workfromhome != '' &&
                      additionalDetails.workfromhome != null
                    "
                  >
                    <v-card-text
                      >Location of WFH :
                      <span class="additionalDetails">
                        {{ additionalDetails.workfromhome }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.dues != '' &&
                      additionalDetails.dues != null
                    "
                  >
                    <v-card-text
                      >Exit Formalities Completed:
                      <span class="additionalDetails">
                        {{ additionalDetails.dues }}
                      </span></v-card-text
                    >
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.commentDuties != '' &&
                      additionalDetails.commentDuties != null
                    "
                  >
                    <v-card-text
                      >Comment on Duties:
                      <span class="additionalDetails">
                        {{ additionalDetails.commentDuties }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.scoreDuties != '' &&
                      additionalDetails.scoreDuties != null
                    "
                  >
                    <v-card-text
                      >Comment on Duties :
                      <span class="additionalDetails">
                        {{ additionalDetails.scoreDuties }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.commentAttitude != '' &&
                      additionalDetails.commentAttitude != null
                    "
                  >
                    <v-card-text
                      >Comment on Attitude :
                      <span class="additionalDetails">
                        {{ additionalDetails.commentAttitude }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.scoreAttitude != '' &&
                      additionalDetails.scoreAttitude != null
                    "
                  >
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.commentPerformance != '' &&
                      additionalDetails.commentPerformance != null
                    "
                  >
                    <v-card-text
                      >Comment on Performance :
                      <span class="additionalDetails">
                        {{ additionalDetails.commentPerformance }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.scorePerformance != '' &&
                      additionalDetails.scorePerformance != null
                    "
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.commentIntegrity != '' &&
                      additionalDetails.commentIntegrity != null
                    "
                  >
                    <v-card-text
                      >Comment on Integrity :
                      <span class="additionalDetails">
                        {{ additionalDetails.commentIntegrity }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.scoreIntegrity != '' &&
                      additionalDetails.scoreIntegrity != null
                    "
                  >
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.commentAttendence != '' &&
                      additionalDetails.commentAttendence != null
                    "
                  >
                    <v-card-text
                      >Comment on Attendance :
                      <span class="additionalDetails">
                        {{ additionalDetails.commentAttendence }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.natureofseperation != '' &&
                      additionalDetails.natureofseperation != null
                    "
                  >
                    <v-card-text
                      >Nature of Seperation :
                      <span class="additionalDetails">
                        {{ additionalDetails.natureofseperation }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.complaints != '' &&
                      additionalDetails.complaints != null
                    "
                  >
                    <v-card-text
                      >Complaints :
                      <span class="additionalDetails">
                        {{ additionalDetails.complaints }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.contractviolation != '' &&
                      additionalDetails.contractviolation != null
                    "
                  >
                    <v-card-text
                      >Contract Violation :
                      <span class="additionalDetails">
                        {{ additionalDetails.contractviolation }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.additionalcomments != '' &&
                      additionalDetails.additionalcomments != null
                    "
                  >
                    <v-card-text
                      >Additional Comments :
                      <span class="additionalDetails">
                        {{ additionalDetails.additionalcomments }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    lg="6"
                    xl="6"
                    sm="12"
                    xs="12"
                    v-if="
                      additionalDetails.eligibleforhire != '' &&
                      additionalDetails.eligibleforhire != null
                    "
                  >
                    <v-card-text
                      >Eligible to Re-hire :
                      <span class="additionalDetails">
                        {{ additionalDetails.eligibleforhire }}
                      </span>
                    </v-card-text>
                  </v-col>

                  <div style="text-align: end"></div>
                  <v-col cols="12" md="6" lg="6" xl="6" sm="12" xs="12"></v-col>
                  <v-card-actions>
                    <v-btn
                      dark
                      depressed
                      class="text-capitalize custom-btn"
                      @click="e1 = 3"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      dark
                      color="green"
                      depressed
                      :loading="loading"
                      class="text-capitalize custom-btn"
                      @click="issueRelievingLetter()"
                      ><v-icon class="mr-1" small>mdi-plus</v-icon> Issue
                      Letter</v-btn
                    >
                  </v-card-actions>
                </v-row>
              </v-stepper-content>

              <!-- OLD TEMPLATE -->
              <!-- <v-stepper-content step="4">

                <v-row>
                  <v-col cols="6" md="6">
                    <v-card-text
                      >Employee ID :
                      <span class="additionalDetails">
                        {{ this.emp_id }}
                      </span></v-card-text
                    >
                  </v-col>
                  <v-col cols="6" md="6">
                    <v-card-text
                      >Employee Name :
                      <span class="additionalDetails">
                        {{ this.issued_to }}
                      </span></v-card-text
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6" class="mt-n7">
                    <v-card-text
                      >Worked From :
                      <span class="additionalDetails">
                        {{ this.date1 }}
                      </span></v-card-text
                    >
                  </v-col>
                  <v-col cols="6" md="6" class="mt-n7">
                    <v-card-text
                      >Worked Till :
                      <span class="additionalDetails">
                        {{ this.date2 }}
                      </span></v-card-text
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" md="6" class="mt-n7">
                    <v-card-text
                      >Designation :
                      <span class="additionalDetails">
                        {{ this.emp_desg }}
                      </span></v-card-text
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="6"
                    md="6"
                    class="mt-n7"
                    v-if="issued_to_contact_number != ''"
                  >
                    <v-card-text
                      >Phone Number :
                      <span class="additionalDetails">
                        {{ this.issued_to_contact_number }}
                      </span></v-card-text
                    >
                  </v-col>
                  <v-col
                    cols="6"
                    md="6"
                    class="mt-n7"
                    v-if="issued_to_email_id != ''"
                  >
                    <v-card-text
                      >Email Address :
                      <span class="additionalDetails">
                        {{ this.issued_to_email_id }}
                      </span></v-card-text
                    >
                  </v-col>
                </v-row>
           
                <v-row class="">
                  <v-col
                    cols="6"
                    md="6"
                    v-if="
                      additionalDetails.salary != '' &&
                      additionalDetails.salary != null
                    "
                    class="mt-n7"
                  >
                    <v-card-text
                      >Salary :
                      <span class="additionalDetails">
                        {{ additionalDetails.salary }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.remuneration != '' &&
                      additionalDetails.remuneration != null
                    "
                    class="mt-n7"
                  >
                    <v-card-text
                      >Additional Remuneration :
                      <span class="additionalDetails">
                        {{ additionalDetails.remuneration }}
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.supervisordetails != '' &&
                      additionalDetails.supervisordetails != null
                    "
                  >
                    <v-card-text
                      >Supervisor's Name and Designation :
                      <span class="additionalDetails">
                        {{ additionalDetails.supervisordetails }}
                      </span>
                    </v-card-text>
                  </v-col>
          
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.reasonofleaving != '' &&
                      additionalDetails.reasonofleaving != null
                    "
                  >
                    <v-card-text
                      >Reason Of Leaving :
                      <span class="additionalDetails">
                        {{ additionalDetails.reasonofleaving }}
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>
              
                <v-row class="">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.natureofemployment != '' &&
                      additionalDetails.natureofemployment != null
                    "
                  >
                    <v-card-text
                      >Nature of Employment :
                      <span class="additionalDetails">
                        {{ additionalDetails.natureofemployment }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.lastlocationofwork != '' &&
                      additionalDetails.lastlocationofwork != null
                    "
                  >
                    <v-card-text
                      >Last Location of Work :
                      <span class="additionalDetails">
                        {{ additionalDetails.lastlocationofwork }}
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.workfromhome != '' &&
                      additionalDetails.workfromhome != null
                    "
                  >
                    <v-card-text
                      >Location of WFH :
                      <span class="additionalDetails">
                        {{ additionalDetails.workfromhome }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.dues != '' &&
                      additionalDetails.dues != null
                    "
                    class="mt-n7"
                  >
                    <v-card-text
                      >Exit Formalities & No-Dues Clearance Completed :
                      <span class="additionalDetails">
                        {{ additionalDetails.dues }}
                      </span></v-card-text
                    >
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.commentDuties != '' &&
                      additionalDetails.commentDuties != null
                    "
                  >
                    <v-card-text
                      >Comment on Duties :
                      <span class="additionalDetails">
                        {{ additionalDetails.commentDuties }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.scoreDuties != '' &&
                      additionalDetails.scoreDuties != null
                    "
                  >
                  
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.commentAttitude != '' &&
                      additionalDetails.commentAttitude != null
                    "
                  >
                    <v-card-text
                      >Comment on Attitude :
                      <span class="additionalDetails">
                        {{ additionalDetails.commentAttitude }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.scoreAttitude != '' &&
                      additionalDetails.scoreAttitude != null
                    "
                  >
                   
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.commentPerformance != '' &&
                      additionalDetails.commentPerformance != null
                    "
                  >
                    <v-card-text
                      >Comment on Performance :
                      <span class="additionalDetails">
                        {{ additionalDetails.commentPerformance }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.scorePerformance != '' &&
                      additionalDetails.scorePerformance != null
                    "
                  >
                
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.commentIntegrity != '' &&
                      additionalDetails.commentIntegrity != null
                    "
                  >
                    <v-card-text
                      >Comment on Integrity :
                      <span class="additionalDetails">
                        {{ additionalDetails.commentIntegrity }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.scoreIntegrity != '' &&
                      additionalDetails.scoreIntegrity != null
                    "
                  >
                 
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.commentAttendence != '' &&
                      additionalDetails.commentAttendence != null
                    "
                  >
                    <v-card-text
                      >Comment on Attendance :
                      <span class="additionalDetails">
                        {{ additionalDetails.commentAttendence }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.scoreAttendence != '' &&
                      additionalDetails.scoreAttendence != null
                    "
                  >
               
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.natureofseperation != '' &&
                      additionalDetails.natureofseperation != null
                    "
                  >
                    <v-card-text
                      >Nature of Seperation :
                      <span class="additionalDetails">
                        {{ additionalDetails.natureofseperation }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.complaints != '' &&
                      additionalDetails.complaints != null
                    "
                  >
                    <v-card-text
                      >Complaints :
                      <span class="additionalDetails">
                        {{ additionalDetails.complaints }}
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.contractviolation != '' &&
                      additionalDetails.contractviolation != null
                    "
                  >
                    <v-card-text
                      >Contract Violation :
                      <span class="additionalDetails">
                        {{ additionalDetails.contractviolation }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.additionalcomments != '' &&
                      additionalDetails.additionalcomments != null
                    "
                  >
                    <v-card-text
                      >Additional Comments :
                      <span class="additionalDetails">
                        {{ additionalDetails.additionalcomments }}
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row class="4">
                  <v-col
                    class="mt-n7"
                    cols="12"
                    md="12"
                    v-if="
                      additionalDetails.eligibleforhire != '' &&
                      additionalDetails.eligibleforhire != null
                    "
                  >
                    <v-card-text
                      >Eligible to Re-hire :
                      <span class="additionalDetails">
                        {{ additionalDetails.eligibleforhire }}
                      </span>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    dark
                    depressed
                    :loading="loading"
                    class="text-capitalize custom-btn"
                    @click="e1 = 3"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    dark
                    color="green"
                    depressed
                    :loading="loading"
                    class="text-capitalize custom-btn"
                    @click="issueRelievingLetter()"
                    ><v-icon class="mr-1" small>mdi-plus</v-icon> Issue
                    Letter</v-btn
                  >
                </v-card-actions>
              </v-stepper-content> -->
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { RL_listReasonOfLeavingDropdown } from "@/mixins/GetRL_listReasonOfLeaving.js";
import { ListIssuerNatureOfSeparationDropdown } from "@/mixins/GetAllListIssuerNatureOfSeparation.js";
import { VueEditor } from "vue2-editor";
import Snackbar from "@/components/Extras/Snackbar.vue";
import {
  IssueCredential,
  RLFileConversion,
  RLCheckNewDocument,
} from "@/graphql/mutations.js";
import { GetAllSupportedCountry } from "@/mixins/GetAllSupportedCountry.js";
import { API, graphqlOperation } from "aws-amplify";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
import { GetPresignedUrlForS3Upload } from "../../../mixins/GetsFileConvertS3PreSignedUrl.js";
var AWS = require("aws-sdk");
import axios from "axios";
export default {
  props: {
    dialogIssueCredentials: Boolean,
    actionEnum: String,
    StoreObj: Object,
  },
  mixins: [
    GetCurrentUser,
    GetAllSupportedCountry,
    GetPresignedUrlForS3Upload,
    RL_listReasonOfLeavingDropdown,
    ListIssuerNatureOfSeparationDropdown,
  ],
  components: {
    Snackbar,
    VueEditor,
  },
  data: () => ({
    issued_to_contact_number: "",
    selectedListReason: null, // New data property for v-model
    reasonsOfLeavingList: [],
    reasonsOfLeavingList2: [],
    EmailPhoneGiven: false,
    e1: 1,
    // reasonForLeaving: ["Resigned", "Absconding", "Terminated", "Other"],
    commentOfItems: [
      "Very Satisfied",
      "Satisfied",
      "Neutral",
      "Unsatisfied",
      "Very Unsatisfied",
    ],
    natureOfEmployment: [
      "Permanent Full-Time",
      "Permanent Part-Time",
      "Contractual Full-Time",
      "Contractual Part-Time",
    ],
    lastLocationOfWork: [
      "Work From Office",
      "Work From Home",
      "Work From Client Location",
    ],
    currentview: "issuedocument",
    countrycode: ["+91"],
    emp_id: "",
    menu1: "",
    menu2: "",
    date1: "",
    date2: "",
    emp_desg: "",
    emp_dept: "",
    Toggle: 0,
    files: null,
    payload: "",
    loading: false,
    IsAutoPopulateAdress: false,
    issued_to: "",
    issued_to_email_id: "",
    issued_to_country_code: "+91",
    issued_to_contact_number: "",
    comments: "",
    SnackBarComponent: {},
    pdfview: false,
    pdfsrc: "",
    windowHeight: 0,
    heightPDF: 0,
    store_data_ob_bc: true,
    document_type: "",
    IssueCredsRules: [],
    contactRules: [],
    GetAllSupportedCountryList: [],
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"],
    ],
    preview_mode: false,
    email_subject: "",
    renderComp: true,
    epochDoc: "",
    valid: true,
    mainDate1: "",
    mainDate2: "",
    additionalDetails: {
      companyAddress: "",
      companyNumber: "",
      salary: "",
      remuneration: "",
      supervisordetails: "",
      reasonofleaving: "",
      natureofemployment: "",
      lastlocationofwork: "",
      workfromhome: "",
      dues: "",
      scoreDuties: "",
      commentDuties: "",
      scoreAttitude: "",
      commentAttitude: "",
      scorePerformance: "",
      commentPerformance: "",
      scoreIntegrity: "",
      commentIntegrity: "",
      scoreAttendence: "",
      commentAttendence: "",
      eligibleforhire: "",
      natureofseperation: "",
      complaints: "",
      contractviolation: "",
      additionalcomments: "",
    },
  }),

  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    isbigScreen() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg;
    },
  },

  watch: {
    selectedListReason(newVal, oldVal) {
      // console.log("Selected List Reason changed. New Value:", newVal);
    },
    startDate(date1) {
      if (this.date2 < date1) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Till Date Cannot Be changed be Less Than from date ",
        };
      }
    },
    issued_to_contact_number(val) {
      if (val != "" && val != null) {
        this.contactRules = [
          (v) =>
            /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) || "Invalid",
          (v) => (v && v.length < 11) || "Invalid",
          (v) => (v && v.length > 9) || "Invalid",
        ];
      } else {
        this.contactRules = [];
      }
    },
    issued_to_email_id(val) {
      if (val != "" && val != null) {
        this.emailRules = [(v) => /.+@.+/.test(v) || "Email Must Be Valid"];
      } else {
        this.emailRules = [];
      }
    },
    files(val) {
      if (val != undefined && val != "" && val != null) {
        let d = new Date();
        let monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        let date =
          d.getDate() + "-" + monthNames[d.getMonth()] + "-" + d.getFullYear();
        this.email_subject = `Relieving letter is issued to ${this.issued_to} on ${date}`;
        if (val.size <= 5000000) {
          // console.log(val.type);
          this.document_type = val.type.includes("pdf")
            ? "PDF"
            : val.type.includes("image")
            ? "IMAGE"
            : val.type.includes(
                "vnd.openxmlformats-officedocument.wordprocessingml.document"
              )
            ? "DOCX"
            : val.type.includes("application/msword")
            ? "DOC"
            : "TEXTFILE";
          if (this.document_type == "PDF") {
            var fileReader = new FileReader();
            fileReader.readAsDataURL(val);
            var self = this;
            fileReader.onload = async function (val) {
              self.pdfsrc = val.target.result;
              self.pdfview = true;
            };
          } else if (
            this.document_type == "DOCX" ||
            this.document_type == "DOC"
          ) {
            this.IssueCredentialsMethodForDoc();
          } else {
            this.renderComp = false;
            this.$nextTick(() => {
              this.SnackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                Top: true,
                SnackbarText: "Select Either PDF or WORD file",
              };
              this.renderComp = true;
            });
          }
        } else {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "File should be less than 5 MB",
            };
            this.renderComp = true;
          });
        }
      } else {
        this.pdfview = false;
      }
    },
    dialogIssueCredentials(val) {
      if (val == true) {
        this.GetAllSupportedCountryMethod("ACTIVE");
        this.store_data_ob_bc = true;
        this.issued_to_country_code = "+91";
        this.additionalDetails.address = `${this.$store.getters.get_current_user_details.issuer_details.issuer_address_1}, ${this.$store.getters.get_current_user_details.issuer_details.issuer_address_2}, ${this.$store.getters.get_current_user_details.issuer_details.city}, ${this.$store.getters.get_current_user_details.issuer_details.state}, ${this.$store.getters.get_current_user_details.issuer_details.postal_code}`;
      }
    },
  },
  async mounted() {
    await this.RL_listReasonOfLeavingMethod();
    await this.ListIssuerNatureOfSeparationMethod();
    this.windowHeight = window.innerHeight - 100;
    this.heightPDF = window.innerHeight - 280;
  },
  methods: {
    validateMobileNumber(value) {
      const numRegex = /^[0-9]+$/;
      if (!numRegex.test(value)) {
        return "Numericals Only ";
      }
      return true;
    },
    back() {
      this.EmailPhoneGiven = false;
      this.currentview = "issuedocument";
      // console.log("this.currentview", this.currentview, "and");
    },
    async issueRelievingLetter() {
      this.loading = true;
      // console.log(this.additionalDetails);
      // console.log(JSON.stringify(this.additionalDetails));
      await this.checkDocument();
      this.loading = false;
    },

    //APIS -(1):--> RLFileConversion
    async fileConvert(type, filename) {
      let inputParams = {
        document_key: `${this.$store.getters.get_current_user_details.user_id}/${filename}`,
        original_document_type: type,
      };
      // console.log(inputParams);
      let result = await API.graphql(
        graphqlOperation(RLFileConversion, {
          input: inputParams,
        })
      );
      const resultObject = JSON.parse(result.data.RLFileConversion);
      // console.log(resultObject);
      this.pdfsrc = resultObject.response;
      this.pdfview = true;
    },

    //(2):--> IssueCredential
    async issueCredentialsMethod(Key) {
      let d = new Date();
      let monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let date =
        d.getDate() + "-" + monthNames[d.getMonth()] + "-" + d.getFullYear();
      // console.log(this.date1.split("-").reverse());
      // console.log(this.date2.split("-").reverse());
      this.maindate1 =
        this.date1.split("-").reverse()[0] +
        "-" +
        monthNames[this.date1.split("-").reverse()[1] - 1] +
        "-" +
        this.date1.split("-").reverse()[2];
      this.maindate2 =
        this.date2.split("-").reverse()[0] +
        "-" +
        monthNames[this.date2.split("-").reverse()[1] - 1] +
        "-" +
        this.date2.split("-").reverse()[2];
      try {
        var inputParams = {
          should_create_pdf: true,
          issued_to: this.issued_to,
          user_id: this.$store.getters.get_current_user_details.user_id,
          file_name: Key,
          key: Key,
          date: date,
          month: `${d.getFullYear()}-${d.getMonth() + 1}`,
          push_to_blockchain: false,
          document_type: "PDF",
          employee_id: this.emp_id,
          worked_from: this.maindate1,
          worked_till: this.maindate2,
          designation: this.emp_desg,
          department: this.emp_dept===""? undefined : this.emp_dept,
          email_subject: this.email_subject,
        };
        inputParams.additional_details = JSON.stringify(this.additionalDetails);
        if (
          this.email_subject != undefined &&
          this.email_subject != "" &&
          this.email_subject != null
        ) {
          inputParams.email_subject = this.email_subject;
        }
        if (
          this.issued_to_contact_number != undefined &&
          this.issued_to_contact_number != "" &&
          this.issued_to_contact_number != null
        ) {
          inputParams.phone_number =
            `${this.issued_to_country_code}${this.issued_to_contact_number}`.includes(
              "+"
            ) == true
              ? `${this.issued_to_country_code}${this.issued_to_contact_number}`.replace(
                  "+",
                  ""
                )
              : `${this.issued_to_country_code}${this.issued_to_contact_number}`;
        }
        if (
          this.issued_to_email_id != undefined &&
          this.issued_to_email_id != "" &&
          this.issued_to_email_id != null
        ) {
          inputParams.issued_to_email_id = this.issued_to_email_id;
        }
        if (
          this.comments != undefined &&
          this.comments != "" &&
          this.comments != null
        ) {
          inputParams.comments = this.comments;
        }
        this.loading = true;
        // console.log(inputParams);
        let result = await API.graphql(
          graphqlOperation(IssueCredential, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.IssueCredential);
        if (ResultObject.status == "Success") {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: ResultObject.Status_Message,
            };
            this.renderComp = true;
          });
          this.currentview = "issuedocument";
          this.e1 = 1;
          this.IssueCredentialsEmit(2, ResultObject);
        }
        this.loading = false;
      } catch (error) {
        // console.log(error);
        this.renderComp = false;
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
          this.renderComp = true;
        });
        this.loading = false;
      }
    },

    //(3):--> RLCheckNewDocument
    async checkDocument() {
      this.loading = true;
      var self = this;
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.files);
      let data;
      fileReader.onload = async function (val) {
        self.count = 40;
        self.pdfsrc = val.target.result;
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        const crypto = require("crypto");
        data = crypto.createHash("sha256").update(buf).digest("hex");
        // console.log(data);
        let inputParams = {
          credential_hash: data,
        };
        try {
          let result = await API.graphql(
            graphqlOperation(RLCheckNewDocument, {
              input: inputParams,
            })
          );
          let resultObject = JSON.parse(result.data.RLCheckNewDocument);
          if (resultObject.status == "SUCCESS") {
            if (self.document_type == "DOCX" || self.document_type == "DOC") {
              var newString;
              if (self.epochDoc.includes(".docx")) {
                newString = self.epochDoc.replaceAll(".docx", ".pdf");
              }
              // if (self.epochDoc.includes(".doc")) {
              //   newString = self.epochDoc.replaceAll(".doc", ".pdf");
              // }
              // console.log(newString);
              let key = `${self.$store.getters.get_current_user_details.user_id}/${newString}`;
              self.issueCredentialsMethod(key);
            } else {
              self.IssueCredentialsMethod();
            }
          }
        } catch (error) {
          // console.log(error);
          // console.log(error.errors[0].message);
          self.renderComp = false;
          self.$nextTick(() => {
            self.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: error.errors[0].message,
            };
            self.renderComp = true;
          });
        } finally {
          this.loading = false;
        }
      };
    },

    //NEW S3 UPLOAD USING URL

    async IssueCredentialsMethod() {
      this.loading = true;
      var self = this;
      // var s3Bucket = new AWS.S3({
      //   region: self.$store.getters.get_current_user_details.s3_credentials.region,
      //   accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
      //   secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_key,
      // });
      // var file_name = `${new Date().getTime()}_${self.files.name}`;
      var file_name = `${self.files.name}`;
      // var fileReader = new FileReader();
      // fileReader.readAsDataURL(self.files);
      // fileReader.onload = async function () {
      //   var base64FileContent = fileReader.result.split(",");
      //   var buf = Buffer.from(base64FileContent[1], "base64");
      //   var param = {
      //     Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
      //     Key: `${self.$store.getters.get_current_user_details.user_id}/RL_${self.GenerateRandomString()}_${file_name}`,
      //     Body: buf,
      //     ContentType: self.files.type,
      //     ACL: "public-read-write",
      //   };
      //   await s3Bucket.putObject(param, function (err, data) {
      //     if (err) {
      //       console.log(err);
      //     } else if (data) {
      //       self.issueCredentialsMethod(param.Key, file_name);
      //     }
      //   });
      // };
      const key = `${
        self.$store.getters.get_current_user_details.user_id
      }/RL_${self.GenerateRandomString()}_${file_name}`;
      await self.GetPresignedUrlForS3UploadMethod(key);
      const response = await this.https3UploadFunction();
      if (response == "Success") self.issueCredentialsMethod(key, file_name);
    },

    async IssueCredentialsMethodForDoc() {
      var self = this;
      // console.log(self.files.name);
      // var s3Bucket = new AWS.S3({
      //   region: self.$store.getters.get_current_user_details.s3_credentials.region,
      //   accessKeyId: self.$store.getters.get_current_user_details.s3_credentials.access_key,
      //   secretAccessKey: self.$store.getters.get_current_user_details.s3_credentials.secret_key,
      // });
      // self.epochDoc = `${new Date().getTime()}_${self.files.name}`;

      self.epochDoc = `${self.files.name}`;
      // var fileReader = new FileReader();
      // fileReader.readAsDataURL(self.files);
      // fileReader.onload = async function () {
      //   var base64FileContent = fileReader.result.split(",");
      //   var buf = Buffer.from(base64FileContent[1], "base64");
      //   var param = {
      //     Bucket: self.$store.getters.get_current_user_details.s3_credentials.bucket_name,
      //     Key: `${self.$store.getters.get_current_user_details.user_id}/${self.epochDoc}`,
      //     Body: buf,
      //     ContentType: self.files.type,
      //     ACL: "public-read-write",
      //   };
      //   console.log(param.Key);
      //   await s3Bucket.putObject(param, function (err, data) {
      //     if (err) {
      //       console.log(err);
      //     } else if (data) {
      //       self.fileConvert(self.document_type, self.epochDoc);
      //     }
      //   });
      // };
      const key = `${self.$store.getters.get_current_user_details.user_id}/${self.epochDoc}`;
      await this.GetPresignedUrlForS3UploadMethod(key);
      const response = await this.https3UploadFunction();
      if (response == "Success")
        self.fileConvert(self.document_type, self.epochDoc);
    },

    async https3UploadFunction() {
      const response = await axios.put(
        this.PresignedUrlForS3Upload,
        this.files,
        {
          headers: {
            "Content-Type": this.files.type,
          },
        }
      );
      if (response.status == 200 && response.statusText == "OK") {
        // this.SnackBarComponent = {
        //   SnackbarVmodel: true,
        //   SnackbarColor: "green",
        //   Top: true,
        //   SnackbarText: "Uploaded Successfully!!",
        // };
        // console.log("upload completed:-->", response);
        // console.log("response.status:-->", response.status);
        // console.log("response.statusText:-->", response);
        return "Success";
      } else {
        this.loading = false;
        // console.log("Error uploading file", response);
      }
    },
    validateIssueForm(p, file) {
      // console.log("Confirm btn");
      this.valid = true;
      if (
        this.issued_to_contact_number == "" && //if we didnt give email or phone , then it will open "1st dialog"
        this.issued_to_email_id == ""
      ) {
        this.EmailPhoneGiven = true; // for opening "1st dialog"--> You have not given Email Address or Phone number
      }
      if (
        this.issued_to_contact_number != "" && //if we give email or phone number then it will check for "RULES"
        this.issued_to_contact_number != null
      ) {
        //Validating the Phone Number
        const pattern = /^[6789]\d{9}$/;
        if (!pattern.test(this.issued_to_contact_number)) {
          this.valid = false;
          this.renderComp = false;
          this.$nextTick(() => {
            // console.log("Phone");
            this.$forceUpdate();
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Phone number should be valid",
            };
            this.renderComp = true;
          });
        }
      }
      //Validating the Email-Id
      if (this.issued_to_email_id != "" && this.issued_to_email_id != null) {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(this.issued_to_email_id)) {
          this.valid = false;
          this.renderComp = false;
          this.$nextTick(() => {
            // console.log("Email");
            this.$forceUpdate();
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Email Address should be valid",
            };
            this.renderComp = true;
          });
        }
      }
      //If Everthing is correct, then Move to [this.currentview = "additionalDetails"];
      if (this.valid == true) {
        if (this.$refs.formIssueCredential.validate()) {
          // this.IssueCredentialsMethod(p, file);
          this.currentview = "additionalDetails";
          // this.checkDocument();
          if (this.IsAutoPopulateAdress === true) {
            this.additionalDetails.address =
              this.$store.getters.get_current_user_details.issuer_details.issuer_address_1;
          }
          // console.log("p,file", (p, file));
        } else {
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: "Fields marked with asterisks (*) are mandatory",
            };
            this.renderComp = true;
          });
          this.loading = false;
        }
      }
    },

    //Afer dialog has been closed , refresh forms and everything
    async IssueCredentialsEmit(Toggle, ResultObject) {
      this.currentview = "issuedocument";
      this.e1 = 1;
      this.payload = "";
      this.document_type = "";
      this.store_data_ob_bc = false;
      this.IssueCredsRules = [];
      this.files = null;
      this.pdfview = false;
      this.preview_mode = false;
      this.email_subject = "";
      this.emp_id = "";
      this.issued_to = "";
      this.issued_to_email_id = "";
      this.issued_to_contact_number = "";
      this.comments = "";
      this.date1 = "";
      this.date2 = "";
      this.emp_desg = "";
      this.additionalDetails = {};
      if (this.$refs.formIssueCredential) {
        this.$refs.formIssueCredential.reset();
      }
      if (this.$refs.additionalData1) {
        this.$refs.additionalData1.reset();
      }
      if (this.$refs.additionalData2) {
        this.$refs.additionalData2.reset();
      }
      if (this.$refs.additionalData3) {
        this.$refs.additionalData3.reset();
      }
      this.$emit("clicked", Toggle, ResultObject);
      // console.log("cancel button dailog closed");
      await this.GetCurrentUser();
    },

    GenerateRandomString() {
      const length = 6;
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        result += charset[randomIndex];
      }
      // console.log("check Random result", result);
      return result;
    },
  },
};
</script>

<style scoped>
/* Assuming you have a class named .custom-btn for styling the buttons */

.custom-btn {
  width: 135px !important;
  /* Adjust the width as needed */
}
</style>
