import { ListPDFTemplates } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllPortalPDFs = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      GetPortalTemplatesList: [],
    };
  },
  methods: {
    async GetAllPortalTemplatesMethod() {
      try {
        this.overlay = true;
        this.TemplateTableExpand = false;
        let result = await API.graphql(
          graphqlOperation(ListPDFTemplates, {
            input: {
              user_id: this.$store.getters.get_current_user_details.user_id,
            },
          })
        );
        this.GetPortalTemplatesList = JSON.parse(result.data.ListPDFTemplates).data.items;
        // console.log("ListPDFTemplates", this.GetPortalTemplatesList);
        this.overlay = false;
        this.TemplateTableExpand = true;
      } catch (error) {
        this.overlay = false;
        this.TemplateTableExpand = true;
        this.GetPortalTemplatesList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
