import { ListIssuerNatureOfSeparation } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const ListIssuerNatureOfSeparationDropdown = {
  data() {
    return {
      overlay: false,
      noDataText: "",
      
    };
  },
  methods: {
    async ListIssuerNatureOfSeparationMethod() {
      try {
        this.overlay = true;
        const result = await API.graphql(
          graphqlOperation(ListIssuerNatureOfSeparation, {
            input: {
              issuer_id: this.$store.getters.get_current_user_details.issuer_id,
            },
          })
        );
        this.overlay = false;
        //  console.log("ListIssuerNatureOfSeparation result", result);
         this.reasonsOfLeavingList2= JSON.parse(
          result.data.ListIssuerNatureOfSeparation
        ).items[0].rl_nature_of_separation;
        // console.log(
        //   "ListIssuerNatureOfSeparation result",
        //   this.ListIssuerNatureOfSeparationApi
        // );
        return this.reasonsOfLeavingList2;
      
      } catch (error) {
        console.log("Error in ListIssuerNatureOfSeparation", error);
        this.noDataText = error.errors[0].message;
        console.log(
          "ListIssuerNatureOfSeparation noDataText ",
          this.noDataText
        );
        this.overlay = false;
      }
    },
  },
};
