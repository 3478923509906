<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogDeleteImage" max-width="400px" persistent>
      <v-card align="center">
        <v-toolbar dark dense class="elevation-0" color="primary">
          <div v-text="'Delete Image'"></div>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogDeleteImageEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-img max-width="200px" class="mt-5" :src="StoreObj.file_url"></v-img>
          <div class="mt-3">
            Are you sure you want to <span v-text="StoreObj.media_status == 'ACTIVE' ? 'Deactivate' : 'Activate'"></span> {{ StoreObj.Name }} ?
          </div>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <v-spacer></v-spacer>
          <v-btn small class="borderRadius fontStyle text-capitalize" color="secondary" outlined @click="dialogDeleteImageEmit((Toggle = 1))"
            ><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn
          >
          <v-btn small class="borderRadius fontStyle text-capitalize" :loading="loading" color="secondary" @click="ActivateDeactivate()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ActivateOrDeactivateMedia } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  props: {
    dialogDeleteImage: Boolean,
    StoreObj: Object,
  },
  components: {
    Snackbar,
  },
  data: () => ({
    Toggle: 0,
    loading: false,
    SnackBarComponent: {},
  }),
  methods: {
    async ActivateDeactivate() {
      try {
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          media_id: this.StoreObj.media_id,
          media_status: this.StoreObj.media_status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
        };
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(ActivateOrDeactivateMedia, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.ActivateOrDeactivateMedia);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.dialogDeleteImageEmit((this.Toggle = 2));
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    dialogDeleteImageEmit(Toggle) {
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
