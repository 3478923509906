<template>
  <div>
    <!-- <Snackbar :SnackbarComp="SnackbarComp" /> -->
    <v-dialog :value="dialogDeleteReasons" persistent max-width="500px">
      <v-card flat>
        <v-toolbar dense flat dark class="gradient-bar">
          <v-toolbar-title>Delete</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-icon color="white" @click="dialogDeleteReasonsEmit(1)" light>mdi-close</v-icon>
        </v-toolbar>

        <v-card-text class="mt-5">
          <div class="text-center font-weight-bold">
            Are you sure you want to Delete 
            <span class="red--text">{{ StoreObj }} </span>?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn dark depressed class="text-capitalize custom-btn" @click="dialogDeleteReasonsEmit(3)">
            <v-icon left>mdi-close</v-icon>Cancel
          </v-btn>
          <v-btn dark depressed class="text-capitalize custom-btn" color="green"
            @click="dialogDeleteReasonsEmit(2)"><v-icon left>mdi-check</v-icon>Confirm</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import { RL_addReasonOfLeaving } from "@/graphql/mutations.js";
// import { API, graphqlOperation } from "aws-amplify";

export default {
  props: {
    dialogDeleteReasons: Boolean,
    StoreObj: String,
  },
  components: {},
  data: () => ({
    reasonsOfLeavingList: [],
    overlay: false,
  }),
  watch: {
    dialogDeleteReasons12(value) {
      if (value) {
        if (this.StoreObj.action == "DELETE_REASONS") {
          this.rl_reasons_of_leaving = this.StoreObj.rl_reasons_of_leaving;
          // console.log("delete");
        }
      }
    },
  },
  methods: {
    // DeleteReasons1Method() {
    //   console.log("delete");
    // },

    // async DeleteReasonsMethod() {
    //   try {
    //     const mutation_name =
    //       this.StoreObj.action == "DELETE_REASONS" ? RL_addReasonOfLeaving : "";
    //     this.overlay = true;
    //     const result = await API.graphql(
    //       graphqlOperation(mutation_name, {
    //         input: {
    //           issuer_id: this.$store.getters.get_current_user_details.issuer_id,
    //           RL_reason_of_leaving: this.StoreObj.rl_reasons_of_leaving,
    //         },
    //       })
    //     );
    //     console.log("RL_addReasonOfLeaving  result", result);
    //     const ResultObject = JSON.parse(
    //       result.data.RL_addReasonOfLeaving
    //     ).status;

    //     if (ResultObject === "Success") {
    //       // this.SnackBarComponent = {
    //       //   SnackbarVmodel: true,
    //       //   SnackbarColor: "green",
    //       //   Top: true,
    //       //   SnackbarText: "Reasons for Leaving Updated Successfully",
    //       // };
    //       await this.RL_listReasonOfLeavingMethod();
    //     }
    //   } catch (error) {
    //     console.log("RL_addReasonOfLeaving error", error);
    //     this.hasChanges1 = false;
    //     // this.SnackBarComponent = {
    //     //   SnackbarVmodel: true,
    //     //   SnackbarColor: "red",
    //     //   Top: true,
    //     //   SnackbarText: error.errors[0].message,
    //     // };
    //   } finally {
    //     this.overlay = false;
    //   }
    //   // else {
    //   //   this.SnackBarComponent = {
    //   //     SnackbarVmodel: true,
    //   //     SnackbarColor: "red",
    //   //     Top: true,
    //   //     SnackbarText: "Please Enter Rea Values",
    //   //   };
    //   // }
    // },
    dialogDeleteReasonsEmit(Toggle) {
      if (Toggle) this.$emit("clicked", Toggle);
    },
  },
};
</script>
<style scoped>
.custom-btn {
  width: 100px !important;
  /* Adjust the width as needed */
}
</style>