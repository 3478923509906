<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-row no-gutters>
      <v-col col="6" md="8" xs="12" sm="12">
        <v-card class="elevation-0 mx-3">
          <v-card-text class="py-0">
            <v-card class="elevation-0 mt-3">
              <h2 class="font-weight-regular">Update Organization Details</h2>
              <h4 class="font-weight-regular mt-2">Issuer Details</h4>
              <v-form ref="form">
                <v-text-field
                  dense
                  outlined
                  label="Issuer Name"
                  v-model="issuer_name"
                  class="mt-4 field_height field_label_size FontSize"></v-text-field>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-select
                      dense
                      outlined
                      label="Identification Type"
                      v-model="identity_type"
                      :items="identification_type"
                      class="mt-n2 field_height field_label_size FontSize"></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Identification Number"
                      v-model="identification_number"
                      class="mt-n2 field_height field_label_size FontSize"></v-text-field>
                  </v-col>
                </v-row>

                <h4 class="font-weight-regular">Issuer Address</h4>
                <v-row class="mt-2">
                  <v-col cols="12" xs="12" sm="12" md="8" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      label="Address"
                      v-model="issuer_address_1"
                      class="field_height field_label_size FontSize"></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="py-0">
                    <v-select
                      dense
                      outlined
                      label="Country"
                      v-model="country"
                      :items="select_country"
                      item-text="label"
                      item-value="value"
                      class="field_height field_label_size FontSize"></v-select>
                  </v-col>
                </v-row>
                <h4 class="font-weight-regular">Contact Details</h4>
                <v-row class="mt-2">
                  <v-col cols="12" xs="12" sm="12" md="3" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      label="Adminstrator Name"
                      v-model="$store.getters.get_current_user_details.issuer_details.full_name"
                      class="field_height field_label_size FontSize"
                      readonly
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      label="Email"
                      v-model="$store.getters.get_current_user_details.issuer_details.contact_email_id"
                      readonly
                      class="field_height field_label_size FontSize"
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="2" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      label="country Code"
                      v-model="country_code"
                      class="field_height field_label_size FontSize"></v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="3" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      label="Contact Number"
                      v-model="contact_number"
                      class="field_height field_label_size FontSize"
                      disabled></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-card-text>
          <v-card-actions class="py-0">
            <v-spacer></v-spacer>
            <v-btn @click="$router.push('/BusinessInfo')" class="white--text cancelButton">Cancel</v-btn>
            <v-btn @click="UpadateOrgDetails" :loading="loading" class="internalButtons white--text">Confirm</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col col="6" md="4" xs="12" sm="12" v-show="$store.state.account_logo_url != ''">
        <v-card-text class="mt-16" align="center">
          <v-card class="elevation-0">
            <croppa
              v-model="imageVmodel"
              :height="100"
              :width="200"
              :canvas-color="`#E5E4E2`"
              :placeholder-color="'green'"
              :placeholder-font-size="0"
              :disabled="false"
              :prevent-white-space="false"
              :show-remove-button="true"
              @file-choose="checkImage"
              @image-remove="handleImageRemove"
              @move="handleCroppaMove"
              @zoom="handleCroppaZoom">
            </croppa>
          </v-card>
        </v-card-text>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import Croppa from "vue-croppa";
Vue.use(Croppa);
import { RLUpdateIssuerDetails } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import CountryList from "@/JsonFiles/CountryList.json";
import { GetCurrentUser } from "@/mixins/GetCurrentUser.js";
var AWS = require("aws-sdk");
export default {
  components: {
    Snackbar,
  },
  mixins: [GetCurrentUser],
  data: () => ({
    issuer_id: "",
    org_Logo: null,
    showLogo: "",
    country: "+91",
    issuer_name: "",
    identity_type: "",
    contact_number: "",
    country_code: "+91",
    issuer_address_1: "",
    account_logo_url: "",
    identification_number: "",

    loading: false,
    imagePresent: false,

    orgObj: {},
    imageVmodel: {},
    finalImageBlobObj: {},
    SnackBarComponent: {},
    selectedFileObject: {},

    select_country: [],
    identification_type: ["Pan ID", "Tax ID", "CIN"],
  }),
  created() {
    this.fetchCountries();
  },
  async mounted() {
    this.imagePresent = false;
    this.issuer_id = this.$store.getters.get_current_user_details.issuer_details.issuer_id;
    this.issuer_name = this.$store.getters.get_current_user_details.issuer_details.issuer_name;
    this.identity_type = this.$store.getters.get_current_user_details.issuer_details.identification_type;
    this.identification_number = this.$store.getters.get_current_user_details.issuer_details.identification_number;
    this.issuer_address_1 = this.$store.getters.get_current_user_details.issuer_details.issuer_address_1;
    this.contact_number = this.$store.getters.get_current_user_details.issuer_details.contact_number;
    this.imageVmodel = this.$store.getters.get_current_user_details.issuer_details.account_logo_url;
  },
  methods: {
    async handleCroppaZoom() {
      this.imagePresent = true;
      const blob = await this.imageVmodel.promisedBlob();
      blob.name = `${new Date().getTime()}.png`;
      this.checkImage(blob);
    },
    async handleCroppaMove() {
      this.imagePresent = true;
      const blob = await this.imageVmodel.promisedBlob();
      blob.name = `${new Date().getTime()}.png`;
      this.finalImageBlobObj = blob;
    },
    handleImageRemove() {
      this.imagePresent = false;
      this.selectedFileObject = {};
      this.finalImageBlobObj = {};
    },
    async checkImage(e) {
      this.finalImageBlobObj = e;
    },
    fetchCountries() {
      // var countryccWithIso = [];
      var countryWithCc = [];
      this.countriesData = [];
      CountryList.forEach((element) => {
        countryWithCc.push({
          label: element.name,
          value: element.dial_code,
        });
        this.countriesData.push(element.name);
      });
      var countryListt = countryWithCc;
      this.select_country = countryListt;
    },
    checkImagePresent() {
      if (this.org_Logo == null || this.org_Logo == "" || this.org_Logo == undefined) {
        this.handleUpload();
      }
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    async uploadorglogo(e) {
      return new Promise((resolve) => {
        var s3Bucket = new AWS.S3({
          region: "ap-south-1",
          accessKeyId: "AKIARIY6IPQHZI6O3QVI",
          secretAccessKey: "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",
        });
        var self = this;
        const currentDate = new Date();
        let epoch1 = currentDate.getTime();
        this.org_Logo = e;
        var fileReader = new FileReader();
        fileReader.readAsDataURL(this.org_Logo);
        fileReader.onload = async function (val) {
          self.showLogo = val.target.result;
          var base64FileContent = fileReader.result.split(",");
          var buf = Buffer.from(base64FileContent[1], "base64");
          var param = {
            Bucket: "workhistory-mumbai",
            Key: `${self.issuerId}/account_logo/${epoch1}_${self.org_Logo.name}`,
            Body: buf,
            ContentType: self.org_Logo.type,
            ACL: "public-read-write",
          };
          await s3Bucket.putObject(param, function (err, data) {
            if (err) {
              console.log(err);
              self.loading = false;
              return resolve("SUCCESS");
            } else if (data) {
              let brand_logo = `https://${param.Bucket}.s3.ap-south-1.amazonaws.com/${param.Key}`;
              self.account_logo_url = brand_logo;
              return resolve("ERROR");
            }
          });
        };
      });
    },
    async UpadateOrgDetails() {
      try {
        let country;
        if (this.country == "+91") {
          country = "India";
        }
        if (this.imagePresent == true) {
          await this.uploadorglogo(this.finalImageBlobObj);
        }
        var inputParams = {
          user_id: this.$store.getters.get_current_user_details.user_id,
          issuer_name: this.issuer_name,
          issuer_address_1: this.issuer_address_1,
          contact_number: this.contact_number,
          identification_type: this.identity_type,
          identification_number: this.identification_number,
          country: country,
          country_code: this.country_code,
        };
        if (this.imagePresent) {
          inputParams.account_logo_url = this.account_logo_url;
        }
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(RLUpdateIssuerDetails, {
            input: inputParams,
          })
        );
        let resultObject = JSON.parse(result.data.RLUpdateIssuerDetails);
        if (resultObject.status == "Success") {
          this.imagePresent = false;
          this.renderComp = false;
          this.$nextTick(() => {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "green",
              Top: true,
              SnackbarText: resultObject.Status_Message,
            };
            this.renderComp = true;
          });
          this.loading = false;
          setTimeout(() => {
            this.$router.push("/BusinessInfo");
          }, 2000);
        }
        console.log(result);
      } catch (error) {
        console.log(error);
        this.renderComp = false;
        console.log(error.errors[0].message);
        this.$nextTick(() => {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: error.errors[0].message,
          };
          this.renderComp = true;
        });
        this.loading = false;
      }
    },
  },
};
</script>

<style></style>
